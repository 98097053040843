import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AiOutlinePlus } from "react-icons/ai";
import {
  CNav,
  CModal,
  Loading,
  UserListItem,
  UserList,
} from "../../uiComponents";
import {
  dataEmpty,
  MappedElement,
  renderUserName,
} from "../../utils/helper";
import AddMentor from "./AddMentor";
import { getAllMentor } from "../../store/actions/TopMentor";
import { post } from "../../utils/methods";
import { getAllTutors } from "../../store/actions/Tutors";

function TopScoreMentor() {
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState("");

  const dispatch = useDispatch();

  const reduxState = useSelector(({ tutors }) => {
    return {
      getAllTutorsLoading: tutors?.getAllTutorsLoading,
      tutors: tutors?.tutors,
      myStudentCount: tutors?.myStudents?.length,
    };

  });

  const { getAllTutorsLoading, tutors } = reduxState;

  useEffect(()=> {
    get()
  }, []);

  const renderMentors = (obj, index) => {
    return (
      <UserListItem
        key={index}
        titleStyle="m-0"
        id={obj?._id ? obj?._id : ""}
        name={renderUserName(obj)}
      />
    );
  };

  const get = (text = "") => {
    dispatch(getAllTutors({
      ...(text && { searchQueryText: text }),
      sortByScore : true
    }))
  }

  return (
    <div className="topMentor w-100 d-flex  flex-column">
      <CNav />
      <h3 className="page-title"> Top Mentors </h3>
      <div className="first-card-view-body with-out-filter scroll-y">
        <UserList data={tutors} loading={getAllTutorsLoading}>
        {tutors?.length > 0 && <MappedElement data={tutors} renderElement={renderMentors} />}
        </UserList>
      </div>
    </div>
  );
}

export default TopScoreMentor;
