import React from "react";
import CImage from "../CImage/CImage";

const CMultipleImages = ({ className = "", data, renderCount = 2 }) => {
    if (data && data.length) {
        let userCount = data.length;
        return (
            <div className={`${className} c-multiple-images`}>
                {data.map((obj, index) =>
                    index <= renderCount ? <CImage key={index} src={obj.imageUrl ? obj.imageUrl : ''} /> : null
                )}
                {userCount > renderCount + 1 ? <div className="counter"> {userCount}+ </div> : null}
            </div>
        );
    }
    return null;
};

export default CMultipleImages;
