import React from "react";
import ReactPlayer from "react-player";
import {
    CMultipleImages,
    SelectCategoriesFilter,
    CTags,
} from "../../uiComponents";
import { Dropdown, Menu, Input, Button } from "antd";
import { AiOutlineEllipsis, AiOutlineSend } from "react-icons/ai";
import {
    getMultipleValues,
    MappedElement, renderUserDob,
    renderUserFullName, renderUserGender,
    renderUserNickName,
    renderEmail,
    renderPhone,
    renderUserName,
    renderUser,
    renderIp,
    renderOneToOnePrice,
    renderCounselingPrice
} from "../../utils/helper";
import { FiX } from "react-icons/fi";
import { categories, feedback } from "../data";

import { RiTimeLine, RiDeleteBinLine } from "react-icons/ri";
import { Image } from "react-bootstrap";
import moment from "moment";
import StarRatingComponent from "react-star-rating-component";
import { BsFillStarFill } from "react-icons/bs";
import { GoStar } from "react-icons/go";

function Price({ teacher }) {
    const menu = (
        <Menu>
            <Menu.Item key="0"> Option 1 </Menu.Item>
            <Menu.Item key="1"> Option 2 </Menu.Item>
            <Menu.Item key="2"> Option 3 </Menu.Item>
        </Menu>
    );
  
  

    return (
        <div className="details-tab-container">
            <ul className="user-info d-flex flex-column">
                <li style={{ display: 'flex', flexDirection: 'column'}}>
                    <span>ONE TO ONE</span>
                    <table  style={{border: '1px solid #00000040', width: '100%', marginTop: '10px'}}>
                        <tr style={{border: '1px solid green', width: '40%'}}>
                            <th style={{border: '1px solid #00000040', width: '50%', paddingLeft: '10px'}}>Subject</th>
                            <th style={{border: '1px solid #00000040', paddingLeft: '10px'}}>Price</th>
                        </tr>

                        {
                            teacher &&
                            teacher?.offering?.oneToOneSubjects?.map((obj)=>{

                            return(
                                <tr style={{border: '1px solid green', width: '40%'}}>
                                <td style={{border: '1px solid #00000040', width: '50%', paddingLeft: '10px'}}>{obj?.subject?.name}</td>
                                <td style={{border: '1px solid #00000040', paddingLeft: '10px'}}>$ {obj?.price}</td>
                            </tr>
                            )

                            })
                        }

                    </table>
                    {/* <span>{`$ ${renderOneToOnePrice(teacher)}`}</span> */}
                </li>

                <li style={{display: 'flex', flexDirection: 'column'}}>
                    <span>INSTANT</span>
                    <table  style={{border: '1px solid #00000040', width: '100%', marginTop: '10px'}}>
                        <tr style={{border: '1px solid green', width: '40%'}}>
                            <th style={{border: '1px solid #00000040', width: '50%', paddingLeft: '10px'}}>Subject</th>
                            <th style={{border: '1px solid #00000040', paddingLeft: '10px'}}>Price</th>
                        </tr>
                        {
                            teacher &&
                            teacher?.offering?.offering?.map((obj)=>{

                            return(
                                <tr style={{border: '1px solid green', width: '40%'}}>
                                <td style={{border: '1px solid #00000040', width: '50%', paddingLeft: '10px'}}>{obj?.subject?.name}</td>
                                <td style={{border: '1px solid #00000040', paddingLeft: '10px'}}>$ {obj?.price}</td>
                            </tr>
                            )

                            })
                        }
                       
                    </table>
                    {/* <span>{`$ ${renderCounselingPrice(teacher)}`}</span> */}
                </li>

                <li >
                    <span>COUNSELING</span>
                    {/* <span>{`$ ${teacher.offering.counselingPrice}`}</span> */}
                    <span>{`$ ${renderCounselingPrice(teacher)}`}</span>
                </li>

            </ul>
        </div>
    );
}
export default Price;
