import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Axios from "axios";
import "./Page.css";

import {
  baseUrl
} from "../../utils/intercepter";

function Page() {
  const dispatch = useDispatch();

  const [buttons, setButtons] = useState([]);

  var buttonArr = []

  useEffect(() => {
    // dispatch(getbuttons())
    // Axios.post(`${baseUrl}logging/getTopButton`)
    Axios.post(`${baseUrl}/logging/getTopPage`)
      .then((result) => {
        // console.log('see :', result.data)
        setButtons(result.data)
      })
      .catch((err) => {
        console.log("err : ", err);
      });
  }, []);


  return (
    <>
    <div style={{ width: "100%" }}>
      {/* -------- For Searched Course ------ */}

      <div
        style={{ textAlign: "center", height: "max-content", width: "100%" }}
      >
        <h1>Page</h1>
      </div>

        <div style={{display: 'flex',justifyContent: 'center', marginTop: '30px'}}>
      <table
      style={{width: '50%'}}
      >
        <tr style={{border: '1px solid gray', backgroundColor: '#6c757d90'}}>

          <th style={{border: '1px solid gray', width: '33%', paddingLeft: '2%', height: '50px',}}>Visited By</th>
          <th style={{border: '1px solid gray', width: '33%', paddingLeft: '2%', height: '50px',}}>Page Name</th>
          <th style={{border: '1px solid gray', width: '33%', height: '50px', paddingLeft: '2%', textAlign: 'center'}}>Date / Time</th>
          
        </tr>
        {buttons.length &&
          buttons.map((obj) => {
            let mySplit = obj.createdAt.split('T')
              return (
                <tr
                  style={{
                    border: '1px solid gray'
                  }}
                >
                   <td style={{border: '1px solid gray', width: '33%', paddingLeft: '2%', background: '#ced4da', height: '50px', fontWeight: '600'}}>{obj?.result[0]?.details?.firstName + " " + obj?.result[0]?.details?.lastName}</td>

                   <td style={{border: '1px solid gray',  width: '33%', paddingRight: '2%', height: '50px', textAlign: 'left'}}>{obj?.pageName}</td>

                   <td style={{border: '1px solid gray',  width: '33%', paddingRight: '2%', height: '50px', textAlign: 'right'}}>{mySplit[0] + ' / ' + mySplit[1].substring(0, 5)}</td>
                </tr>
              );
            
          })}
      </table>
      </div>

      {/* --------XXXXXXX------- */}


    </div>
  </>
  );
}

export default Page;
