import React, { useState, useEffect } from "react";
import { CModal, CNav, Loading } from "../../uiComponents";
import { Button, Form, Input, Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  approvalsTakeAction,
  getAllApprovals,
} from "../../store/actions/Approvals";
import * as Rules from "../../utils/rules";
import {
  dataEmpty,
  MappedElement,
  renderUserName,
} from "../../utils/helper";
import ApprovalModal from "./ApprovalModal";

function Approvals() {
  
  const [detailModalIsOpen, setDetailModal] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const [rejection, setRejection] = useState(false);
  const [approval, setApproval] = useState(null);
  const [pages, setPages] = useState(1);
  const [limit, setLimit] = useState(6);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(20);
  

  const dispatch = useDispatch();

  const reduxState = useSelector(({ approvals }) => {
    return {
      actionLoading: approvals.actionLoading,
      getAllApprovalsLoading: approvals.getAllApprovalsLoading,
      approvals: approvals.approvals,
      metaData: approvals?.metaData
    };
  });

  const { getAllApprovalsLoading, approvals, actionLoading } = reduxState;

  const status = "PENDING";

  useEffect(() => {
    get()
  }, [pages]);

  const get = () => {
    dispatch(getAllApprovals({
      topics: [
        "TEACHER_SIGNUP",
        "TEACHER_PROFILE_UPDATE",
        "TEACHER_RATE_CHANGE",
        "TEACHER_SIGNUP_RESUBMIT",
        "ON_BOARD_REQUEST",
      ],
      page: pages,
      limit,
      status,
    }))}

  const toggleDetailModal = (isOpen, data = null, id = null) => {
    setDetailModal(isOpen);
    setUserDetail(data);
  };

  const callback = () => {
    toggleDetailModal(false);
    setRejection(false);
  };

  const RejectionComment = () => {
    const onFinish = (values) => {
      const payload = {
        approvalId: approval._id,
        approvalStatus: "REJECTED",
        actionReview: values.comment,
      };
      dispatch(approvalsTakeAction(payload, callback))
    };

    return (
      <Form
        name="refund-form"
        className="modal-form"
        initialValues={{}}
        onFinish={onFinish}
      >
        <h4 className="modal-form-title">Rejection Comments</h4>
        <Form.Item
          name="comment"
          className="c-input"
          rules={Rules.commentsRule}
        >
          <Input placeholder="Write Your Comments" />
        </Form.Item>
        <Form.Item className="mb-0">
          <Button
            htmlType="submit"
            className="c-button button-with-loading"
            block
            loading={getAllApprovalsLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const takeAction = (action, id) => {
    const payload = {
      approvalId: id,
      approvalStatus: action,
      actionReview: action,
    };
    dispatch(approvalsTakeAction(payload, callback))
  };

  const renderData = (obj) => {
    switch (obj?.topic) {
      case "ON_BOARD_REQUEST":
        return (
          <div
            className="text flex-1"
            onClick={() =>
              obj?.teacher ? toggleDetailModal(true, obj, obj?._id) : null
            }>
            <b> {renderUserName(obj?.teacher ? obj?.teacher : "")} </b>
          </div>
        );
      case "TEACHER_SIGNUP":
        return (
          <div
            className="text flex-1"
            onClick={() =>
              obj?.teacher ? toggleDetailModal(true, obj, obj?._id) : null
            }
          >
            New teacher
            <b> {renderUserName(obj?.teacher ? obj?.teacher : "")} </b>
          </div>
        );
      case "TEACHER_PROFILE_UPDATE":
        return (
          <div
            className="text flex-1"
            onClick={() =>
              obj.teacher ? toggleDetailModal(true, obj, obj?._id) : null
            }
          >
            <b> {renderUserName(obj?.teacher ? obj?.teacher : "")} </b>
            request for changing of personal info.
          </div>
        );
      case "TEACHER_RATE_CHANGE":
        return (
          <div
            className="text flex-1"
            onClick={() =>
              obj?.teacher ? toggleDetailModal(true, obj?.teacher, obj?._id) : null
            }
          >
            <b> {renderUserName(obj?.teacher ? obj?.teacher : "")} </b>
            request for changing of hr rates from
            <b>$20/hr</b> to <b>$35/hr</b>
          </div>
        );
      default:
        return null;
    }
  };

  const renderResults = (data) => {
    if (data && data?.length) {
      return (
        <div className="c-section mb-5" >
          <ul className="list ">
            <MappedElement
              data={data}
              renderElement={(obj, index) => {
                return (
                  <li className="list-item hover" key={index}>
                    <img
                      className="icon"
                      src={require("../../assets/svgs/homework.svg")}
                      draggable="false"
                      alt=""
                    />
                    {renderData(obj)}
                    <div className="button-groups d-flex flex-row">
                      <Button
                        className="c-button "
                        disabled={actionLoading}
                        onClick={() => {
                          setApproval(obj);
                          setRejection(true);
                          toggleDetailModal(null, false);
                        }}
                      >
                        Decline
                      </Button>
                      <Button
                        className="c-button"
                        disabled={actionLoading}
                        onClick={() => takeAction("APPROVED", obj?._id)}>
                        Accept
                      </Button>
                    </div>
             
                  </li>
                );
              }} />
          </ul>
          <CModal
            show={rejection}
            className="center small"
            onHide={() => setRejection(false)}
          >
            <RejectionComment />
          </CModal>
        </div>
      );
    } else return dataEmpty();
  };

  const handleChange = (pages) => {
    setPages(pages)
    setMinIndex(pages - 1 * limit)
    setMaxIndex(pages * limit)
  }

  return (
    <div className="approvals w-100 d-flex flex-column">
      <CNav notificationVisible={false} />
      <h3 className="page-title" > Approvals </h3>

      {getAllApprovalsLoading ? (
        <div className="w-100 h-100">
          <Loading position="static" className="page-loading" />
        </div>
      ) : (
        renderResults(approvals)
      )}
      <Pagination
        pageSize={limit}
        current={pages}
        total={reduxState?.metaData?.totalDocuments}
        onChange={handleChange}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}
      />
      <CModal
        show={detailModalIsOpen}
        className="right-side"
        backdrop="static"
        keyboard={false}
        onHide={() => toggleDetailModal(false)}
      >
        {userDetail ? (
          <ApprovalModal
            onDecline={(ob) => {
              setApproval(ob);
              setRejection(true);
              toggleDetailModal(null, false);
            }}
            loading={actionLoading}
            onAccept={(id) => takeAction("APPROVED", id)}
            data={userDetail}
          />
        ) : null}
      </CModal>
    </div>
  );
}


export default Approvals;
