import io from "socket.io-client";
import { EVENT, CURRENT_ENV } from "./environments";
import { announcementsSocket } from "./socketChannels";

let socketUrl = EVENT[CURRENT_ENV];
let socket = null;

const setGlobalSocketConnection = (data) => {
  socket = data;
};

const connectionSocket = (token = "") => {
  let options = { transports: ["websocket", "polling"] };
  if (token) {
    socket = io(`${socketUrl}/?token=${token}`, options);
    announcementsSocket("ADMINS");
    announcementsSocket("ALL");
  }
};

export { connectionSocket, socket, setGlobalSocketConnection };
