import React from "react";
import {
  dataEmpty,
  getCourseName,
  getCourseStudents,
  getCourse,
  MappedElement,
} from "../../utils/helper";
import { CCard } from "../../uiComponents";

const Subjects = ({ data = {} }) => {
  return (
    <div className="c-section h-100">

      {data ? (
        <div className="subject-container mb-4 ">
          <MappedElement
            data={data?.offering?.subjects}
            renderElement={(obj, index) => (
              <>
                <CCard
                  grade={obj?.grade?.name}
                  curriculum={obj?.grade?.curriculum?.name}
                  key={index}
                  title={getCourseName(obj)?.text}
                  cardStyle="subject-card"
                  titleStyle="p-0"
                  cMultipleImagesStyle="mb-0"
                  users={getCourseStudents(obj)}
                  tags={getCourse({ ...obj, type: obj?.type }, "courseType")}
                />
              </>
            )}
          />
        </div>
      ) : (
        dataEmpty()
      )}
    </div>
  );
};

export default Subjects;
