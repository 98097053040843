import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import {
    CMultipleImages,
    SelectCategoriesFilter,
    CTags,
} from "../../uiComponents";
import { Dropdown, Menu, Input, Button } from "antd";
import { AiOutlineEllipsis, AiOutlineSend } from "react-icons/ai";
import {
    getMultipleValues,
    MappedElement, renderUserDob,
    renderUserFullName, renderUserGender,
    renderUserNickName,
    renderEmail,
    renderPhone,
    renderUserName,
    renderUser,
    renderIp,
    renderScore,
    getProfilePercentage
} from "../../utils/helper";
import { FiX } from "react-icons/fi";
import { categories, feedback } from "../data";

import { RiTimeLine, RiDeleteBinLine } from "react-icons/ri";
import { Image } from "react-bootstrap";
import moment from "moment";
import StarRatingComponent from "react-star-rating-component";
import { BsFillStarFill } from "react-icons/bs";
import { GoStar } from "react-icons/go";

function Details({ teacher }) {

    const [profilePercentage, setProfilePercentage] = useState(0)

    useEffect(()=>{
        const newData = getProfilePercentage(teacher).total
        // console.log('hhhh : ', teacher)
        // console.log('tttt : ', newData)
        setProfilePercentage(newData)
    }, [teacher])
    const menu = (
        <Menu>
            <Menu.Item key="0"> Option 1 </Menu.Item>
            <Menu.Item key="1"> Option 2 </Menu.Item>
            <Menu.Item key="2"> Option 3 </Menu.Item>
        </Menu>
    );
  
  

    const renderLessons = () => {
        const renderLessonItem = (type, index) => {
            return (
                <li className="list-item" key={index}>
                    <RiTimeLine className="icon group-lesson" />

                    <div className="flex-1 d-flex text align-items-center">
                        <b> Lesson 2 </b>
                        <span className="c-subject-tag one-to-one mb-0 ml-3">
                            GROUP LESSON
                        </span>
                    </div>
                    <div className="secondary-text flex-06">
                        <CMultipleImages
                            data={[
                                require("../../assets/images/user.jpg"),
                                require("../../assets/images/user.jpg"),
                                require("../../assets/images/user.jpg"),
                            ]}
                            renderCount={1}
                        />
                    </div>
                    <div className="secondary-text flex-06">Apr 16, 12:00AM - 1:00PM</div>
                </li>
            );
        };
        return (
            <div className="lessons">
                {
                    // renderfilters()
                }
                <ul className="list">
                    <MappedElement data={["", ""]} renderElement={renderLessonItem} />
                </ul>
            </div>
        );
    };

    return (
        <div className="details-tab-container">
            <ul className="user-info d-flex flex-column">
                <li>
                    <span>Name</span>
                    <span>{renderUserFullName(teacher)}</span>
                </li>
                <li>
                    <span>UserName</span>
                    <span>{renderUser(teacher)}</span>
                </li>
                <li>
                    <span>Email</span>
                    <span>{renderEmail(teacher)}</span>
                </li>
                <li>
                    <span>Date Of Birth</span>
                    <span>{renderUserDob(teacher)}</span>
                </li>
                <li>
                    <span>Gender</span>
                    <span>{renderUserGender(teacher)}</span>
                </li>
                <li>
                    <span>Phone</span>
                    <span>{renderPhone(teacher)}</span>
                </li>
                <li>
                    <span>IP</span>
                    <span>{renderIp(teacher)}</span>
                </li>
                <li>
                    <span>Score By Parameters</span>
                    <span>{renderScore(teacher)}</span>
                </li>
                <li>
                    <span>Country Code</span>
                    <span>{teacher.profile.countryCodeName}</span>
                </li>
                <li>
                    <span>Joined At</span>
                    <span>{teacher?.profile?.createdAt ? teacher?.profile?.createdAt.substring(0, 10) : ""}</span>
                </li>
                <li>
                    <span>Profile Completed</span>
                    <span>{profilePercentage} %</span>
                </li>
            </ul>
        </div>
    );
}
export default Details;
