import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AiOutlinePlus } from "react-icons/ai";
import {
  CNav,
  CModal,
  Loading,
  UserListItem,
  UserList,
} from "../../uiComponents";
import {
  dataEmpty,
  MappedElement,
  renderUserName,
} from "../../utils/helper";
import AddMentor from "./AddMentor";
import { getAllMentor } from "../../store/actions/TopMentor";
import { post } from "../../utils/methods";

function TopMentor() {
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState("");

  const dispatch = useDispatch();

  const reduxState = useSelector(({ topMentors }) => {
    return {
      getAllMentorsLoading: topMentors?.getAllMentorsLoading,
      mentors: topMentors.mentors,
    };
  });

  const { getAllMentorsLoading, mentors } = reduxState;
  console.log(mentors)

  useEffect(()=> {
    dispatch(getAllMentor());
  }, []);

  const renderMentors = (obj, index) => {
    return (
      <UserListItem
        key={index}
        titleStyle="m-0"
        id={obj?._id ? obj?._id : ""}
        name={renderUserName(obj)}
      />
    );
  };

  return (
    <div className="topMentor w-100 d-flex  flex-column">
      <CNav />
      <h3 className="page-title"> Top Mentors </h3>
      <div className="first-card-view-body with-out-filter scroll-y">
        <UserList data={mentors} loading={getAllMentorsLoading}>
        {mentors?.length > 0 && <MappedElement data={mentors} renderElement={renderMentors} />}
        </UserList>
        {/* <UserList data={allMentors}>
          <MappedElement data={allMentors} renderElement={renderMentors} />
        </UserList> */}
      </div>
      <button className="c-fab-button" onClick={() => setModalOpen(true)}>
        <AiOutlinePlus />
      </button>
      {modalOpen && (
        <CModal
          show={modalOpen}
          backdrop="static"
          keyboard={false}
          className="center small"
          onHide={() => setModalOpen(false)}
        >
          <AddMentor onHide={() => setModalOpen(false)} data={data} />
        </CModal>
      )}
    </div>
  );
}

export default TopMentor;
