import React, { useEffect, useState } from 'react'
import { post } from '../../utils/methods'

function Schedule({teacher, scheduleData}) {



  return (
    <div className="details-tab-container">
            <ul className="user-info d-flex flex-column">

              
                <li style={{ display: 'flex', flexDirection: 'column'}}>
                    <table  style={{border: '1px solid #00000040', width: '100%', marginTop: '10px'}}>
                        <tr style={{border: '1px solid green', width: '40%'}}>
                           
                            <th style={{border: '1px solid #00000040', paddingLeft: '10px'}}>Type</th>
                            <th style={{border: '1px solid #00000040', padding: '0 10px'}}>Price</th>
                            <th style={{border: '1px solid #00000040', paddingLeft: '10px'}}>Start Time</th>
                            <th style={{border: '1px solid #00000040', paddingLeft: '10px'}}>End Time</th>
                           <th style={{border: '1px solid #00000040', width: '40%', paddingLeft: '10px', textAlign: 'center'}}>Students</th>
                        </tr>

                        {
                          scheduleData &&
                          scheduleData.map((obj)=>{
                            return(
                              <tr style={{border: '1px solid green', width: '40%'}}>
                              <td style={{border: '1px solid #00000040', paddingLeft: '10px'}}>{obj?.type.replace('_', ' ')}</td>
                              <td style={{border: '1px solid #00000040', paddingLeft: '10px'}}>$ {obj?.price}</td>
                              <td style={{border: '1px solid #00000040', paddingLeft: '10px'}}>{new Date(obj?.startTime).toUTCString()}</td>
                              <td style={{border: '1px solid #00000040', padding: '0 5px'}}>{new Date(obj?.endTime).toUTCString()}</td>

                              {
                                obj.result &&
                                obj.result.map((item)=>{
                                  return(
                                    <td style={{border: '1px solid #00000040', textAlign: 'center', overflowY: 'scroll'}}>{item?.details.firstName} {item?.details.lastName}</td>
                                  )
                                })
                              }


                          </tr>
                          )
                          })
                        }

                        {/* {
                            teacher &&
                            teacher?.offering?.oneToOneSubjects?.map((obj)=>{

                            return(
                                <tr style={{border: '1px solid green', width: '40%'}}>
                                <td style={{border: '1px solid #00000040', width: '50%', paddingLeft: '10px'}}>{obj?.subject?.name}</td>
                                <td style={{border: '1px solid #00000040', paddingLeft: '10px'}}>$ {obj?.price}</td>
                            </tr>
                            )

                            })
                        } */}

                    </table>
                </li>

            </ul>
        </div>
  )
}

export default Schedule