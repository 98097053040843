import React from "react";
import {dataEmpty} from "../../utils/helper";
import {Loading} from "../index";

function UserList(props) {
    const {className = '', loading, data, children} = props;
    return (
        loading ? (
            <div className="w-100 h-100">
                <Loading position="static" className="page-loading" />
            </div>
        ) : (data && data.length) ? (
            <ul className={`${className ? className : 'm-0 p-0 c-type-list'}`}>
                {children}
            </ul> ) : dataEmpty()
    )
}
export default UserList;
