import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { post } from "../../utils/methods";
import * as Rules from "../../utils/rules";

import { Form, Input, Button } from "antd";
import { activateAccount } from "../../store/actions/MyAccount";

function ActivateAcc({ onHide }) {
    const dispatch = useDispatch();
    const [form]= Form.useForm();

    const reduxState= useSelector(({myAccount})=> {
        return {
            activateAccountLoading: myAccount?.activateAccountLoading
        }
    })

    const callback = () => {
        onHide();
      };

    const onFinish= (values)=> {
        console.log(values);
        let payload= {
            accountId: values.accountId
        }
        dispatch(activateAccount(payload, callback));
    }

    const renderInputs = (values) => {
            return (
                <Form.Item
                name="accountId"
                className="c-input"
                rules={Rules?.emailRule}
              >
                <Input
                  placeholder='Enter the Email Id'
                />
              </Form.Item>
            );
      };

  return (
    <Form
      name="activate-acc"
      className="modal-form"
      onFinish={onFinish}
      form={form}
    >
      <h4 className="modal-form-title">Activate the Account</h4>
      {renderInputs()}
      <Form.Item className="mb-0">
        <Button
          htmlType="submit"
          className="c-button button-with-loading"
          block
          loading={reduxState?.activateAccountLoading}
        >
          Activate
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ActivateAcc;
