import React from "react";
import { CNav, SelectCategoriesFilter } from "../../uiComponents";
import { categories } from "../data";
import { MappedElement } from "../../utils/helper";
import { Dropdown, Menu } from "antd";
import { FiX } from "react-icons/fi";
import { AiOutlineEllipsis } from "react-icons/ai";

function Quizzes(props) {
  const menu = () => (
    <Menu className="categories-dropdown">
      <Menu.Item key="0"> Share with all students </Menu.Item>
      <Menu.Item key="1"> Share with whole class </Menu.Item>
      <Menu.Item key="2"> Share with... </Menu.Item>
    </Menu>
  );

  const renderQuizItem = (value, index) => {
    return (
      <li className="list-item" key={index}>
        <img
          className="icon"
          src={require("../../assets/svgs/homework.svg")}
          draggable="false"
          alt=""
        />
        <div className="text flex-1">
          {" "}
          <b> Spanish </b> - (Lesson 3){" "}
        </div>
        <div className="single-user text flex-1">
          <img src={require("../../assets/images/user-3.jpg")} alt="" />
          <b>Spanish</b>
        </div>
        <div className="single-user text flex-1">
          <img src={require("../../assets/images/user-3.jpg")} alt="" />
          <b>Spanish</b>
        </div>
        <div className="secondary-text flex-1">
          <b className="text m-0">10 / </b>9
        </div>
        <div className="secondary-text flex-1">Today 12:40</div>
        <Dropdown
          overlay={menu()}
          trigger={["click"]}
          overlayClassName="c-dropdown"
        >
          <a
            href={false}
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            <AiOutlineEllipsis />
          </a>
        </Dropdown>
      </li>
    );
  };

  return (
    <div className="quizzes d-flex flex-column w-100">
      <CNav />

      <h3 className="page-title"> Quizzes </h3>

      <div className="d-flex align-items-center mb-3">
        <div className="d-flex flex-1">
          <div className="c-tag rounded mb-0">
            {" "}
            Curriculum{" "}
            <button>
              {" "}
              <FiX />{" "}
            </button>{" "}
          </div>
          <div className="c-tag rounded mb-0">
            {" "}
            Subject{" "}
            <button>
              {" "}
              <FiX />{" "}
            </button>{" "}
          </div>
          <div className="c-tag rounded mb-0">
            {" "}
            Grade{" "}
            <button>
              {" "}
              <FiX />{" "}
            </button>{" "}
          </div>
          <div className="c-tag rounded mb-0">
            {" "}
            Student{" "}
            <button>
              {" "}
              <FiX />{" "}
            </button>{" "}
          </div>
          <div className="c-tag rounded mb-0">
            {" "}
            Tutor{" "}
            <button>
              {" "}
              <FiX />{" "}
            </button>{" "}
          </div>
          <div className="clear-all-button">
            {" "}
            <span>Clear All</span>{" "}
          </div>
        </div>
        <SelectCategoriesFilter categories={categories} />
      </div>
      <div className="c-section mt-3 mb-5">
        <div className="c-section-multiple-titles d-flex mb-2">
          <span className="c-section-title flex-1">Subject</span>
          <span className="c-section-title flex-08">Teacher</span>
          <span className="c-section-title flex-08">Student</span>
          <span className="c-section-title flex-08">Grade</span>
          <span className="c-section-title flex-1">Date</span>
        </div>

        <ul className="list">
          <MappedElement data={["", ""]} renderElement={renderQuizItem} />
        </ul>
      </div>
    </div>
  );
}
export default Quizzes;
