import React from "react";
import { CNav, SelectCategoriesFilter, CTags } from "../../uiComponents";
import { Button } from "antd";
import { categories, messages } from "../data";
import { FiX } from "react-icons/fi";
import { MappedElement } from "../../utils/helper";
import { Image } from "react-bootstrap";

function Messages() {
  const renderFilter = () => {
    return (
      <div className="d-flex align-items-center mb-3">
        <div className="d-flex flex-1">
          <div className="c-tag rounded mb-0">
            Curriculum
            <button>
              <FiX />
            </button>
          </div>
          <div className="c-tag rounded mb-0">
            Subject
            <button>
              <FiX />
            </button>
          </div>
          <div className="c-tag rounded mb-0">
            Grade
            <button>
              <FiX />
            </button>
          </div>
          <div className="c-tag rounded mb-0">
            Student
            <button>
              <FiX />
            </button>
          </div>
          <div className="c-tag rounded mb-0">
            Tutor
            <button>
              <FiX />
            </button>
          </div>
          <div className="clear-all-button">
            <span>Clear All</span>
          </div>
        </div>
        <SelectCategoriesFilter categories={categories} />
      </div>
    );
  };

  const renderMessages = (obj, index) => {
    return (
      <div className="c-card c-card-messages d-flex flex-column justify-content-between">
        <div className="d-flex justify-content-between">
          <div className="c-card-messages-left flex-05">
            <Image src={obj?.userImage} className="mr-2" />
          </div>
          <div>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <p className="text">{obj?.name}</p>
                <p className="secondary-text">send a message to</p>
                <div className="text">
                  <b>{obj?.reciever}</b>
                </div>
              </div>
            </div>
            <div className="mb-1 mt-1 display-flex flex-column">
              <a href={false} className="secondary-text">
                {obj?.message}...<span className="highlight-text">See More</span>
              </a>
              <CTags
                className="mt-2"
                data={["Curriculum", "Grade", "Subject"]}
              />
            </div>
          </div>
          <div className="secondary-text m-0">{obj?.recieve}</div>
        </div>
        <div>
          <Button className="c-button w-100 mt-2">
            Tag a Message Inappropiate
          </Button>
        </div>
      </div>
    );
  };
  return (
    <div className="messages d-flex flex-column w-100">
      <CNav />
      <h3 className="page-title"> Messages </h3>
      {renderFilter()}

      <div className="flex-wrap d-flex">
        <MappedElement data={messages} renderElement={renderMessages} />
      </div>
    </div>
  );
}
export default Messages;
