import { useDispatch, useSelector } from "react-redux";
import React, {useEffect, useState} from "react";
import { Button, Form, Input} from "antd";
import * as Rules from "../../utils/rules";
import { signUp } from "../../store/actions/Users";
import {AuthDesignWrapper} from "../../uiComponents";
import {checkTokenInParam} from "../../utils/helper";
import {useHistory} from "react-router";

const CForm = ({params}) => {

    const dispatch = useDispatch();

    let loading = useSelector(({users}) => users?.loginLoading);

    const onFinish = (values) => {
        let payload = {
            details: {
                firstName: values?.firstName,
                middleName: values?.middleName,
                lastName: values?.lastName
            },
            phone: values?.phone,
            password: values?.password,
            invitationToken: (params && params?.invitationToken) ? params?.invitationToken : ''
        };
        dispatch(signUp(payload));
    };

    return (
        <Form name="normal_login" initialValues={{}} onFinish={onFinish}>

            <Form.Item name="firstName" rules={Rules?.firstName} className="c-input">
                <Input placeholder="First Name" />
            </Form.Item>

            <Form.Item name="middleName" className="c-input">
                <Input placeholder="Middle Name" />
            </Form.Item>

            <Form.Item name="lastName" rules={Rules?.lastNameRule} className="c-input">
                <Input placeholder="Last Name" />
            </Form.Item>


            <Form.Item name="phone" rules={Rules?.phoneRule} className="c-input">
                <Input placeholder="Phone" />
            </Form.Item>
            <Form.Item name="password" rules={Rules?.passwordRule} className="c-password-input">
                <Input.Password placeholder="Password"/>
            </Form.Item>

            <Form.Item>
                <Button
                    htmlType="submit"
                    className="c-button c-button-primary button-with-loading"
                    loading={loading}
                    block
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

function SignUp() {
    const history = useHistory();

    let [params, updateParams] = useState(null);

    useEffect(() => {
        let  data  = checkTokenInParam();
        if(data && data?.invitationToken){
            updateParams(data)
        } else {
            updateParams(null);
            history.replace('/login')
        }
    }, []);

    return (
        <AuthDesignWrapper
            title="Sign Up"
            imagePath={require("../../assets/images/2.jpg")}
        >
            <CForm
                params={params}
            />
        </AuthDesignWrapper>
    );
}
export default SignUp;
