import React from "react";
import { Dropdown, Menu, Button } from "antd";
import { AiOutlineEllipsis } from "react-icons/ai";
import {CTags, CCountdown, CImage, CMultipleImages} from "../index";
import {renderUserName} from "../../utils/helper";

const menu = (
    <Menu>
      <Menu.Item key="0">
        <a href="_blank">Send message to students</a>
      </Menu.Item>
    </Menu>
);

function CCard({
                 title,
                 date,
                 type,
                 typeClass,
                 users,
                 primaryText,
                 primaryFunc,
                 secondaryText,
                 secondaryFunc,
                 onClick,
                 timer,
                 tags,
                 more = false,
                 teacherInfo,
                 parentInfo,
                 cardStyle,
                 titleStyle = '',
                 cMultipleImagesStyle = '',
                 grade,
                 curriculum,
               }) {

  const renderMultiUsers = (user, role) => {
    return (
        <ul className="c-card-multi-list">
          <li className="c-card-multi-list-item p-0">
            <CImage className="c-card-multi-img" src={user.imageUrl} />
            <h4 className="text ellipsis">{renderUserName(user)}</h4>
            <span className="text">•</span>
            <p className="secondary-text">{role}</p>
          </li>
        </ul>
    );
  };

  return (
      <div className={`c-card ${cardStyle}`} onClick={onClick}>
        <div className="c-card-header" style={{flexDirection:"column", display:"flex",}}>
          {/* <h1 className={`c-card-title ${titleStyle}`}>Subject: {title}  </h1>
          <h1 className={`c-card-title ${titleStyle}`}>Grade: {grade}  </h1>
          <h1 className={`c-card-title ${titleStyle}`}>Curriculum: {curriculum}  </h1> */}
          <div ><span style={{color:"#111c55" , fontWeight:"bold" }}>Curriculum</span>: {curriculum && curriculum ? curriculum: "-" }</div>
          <div><span style={{color:"#111c55" , fontWeight:"bold"}}>Grade</span>: {grade && grade ? grade :"-"}</div>
          <div><span style={{color:"#111c55" , fontWeight:"bold"}}>Subject</span>: {title && title ? title:"-"}</div>

          {more ? (
              <Dropdown overlay={menu} trigger={["click"]} overlayClassName="c-dropdown">
                <a
                    href="_blank"
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                >
                  <AiOutlineEllipsis />
                </a>
              </Dropdown>
          ) : null}
        </div>
        <div className="c-card-body">
          {date ? <p className="c-card-date"> {date} </p> : null}
          {tags ? <CTags className="mb-2" data={tags} /> : null}
          {type ? <span className={`c-card-tag ${typeClass}`} style={{
            backgroundColor: type.backgroundColor,
            color: type.color
          }}> {type.text} </span> : null}
          {users ? <CMultipleImages className={cMultipleImagesStyle ? cMultipleImagesStyle : 'mb-2'} data={users} /> : null}
          {teacherInfo ? renderMultiUsers(teacherInfo, 'Teacher') : null}
          {parentInfo ? renderMultiUsers(parentInfo) : null}
        </div>
        <div className="c-card-footer">
          {timer ? <CCountdown className="mb-2" title="Meeting starting soon" deadline={timer} /> : null}
          {primaryText ? (
              <Button onClick={primaryFunc}  className={`c-button w-100 ${secondaryText ? "mb-2" : ""}`}>
                {primaryText}
              </Button>
          ) : null}
          {secondaryText ? (
              <Button className="c-button w-100 c-button-primary-light" onClick={secondaryFunc} block>
                {secondaryText}
              </Button>
          ) : null}
        </div>
      </div>
  );
}
export default CCard;
