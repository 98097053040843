import { createStore, applyMiddleware, combineReducers } from "redux";
import reducers from "./reducers/index";
import thunk from "redux-thunk";
// import { composeWithDevTools } from 'redux-devtools-extension';

const middleware =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(thunk)
    : applyMiddleware(thunk);

export const store = createStore(combineReducers(reducers), {}, middleware)
