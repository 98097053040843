import React from "react";
import UserList from "./UserList";
import { CNav, CImage } from "../../uiComponents";
import { usersList, messages } from "../data";
import { MappedElement } from "../../utils/helper";

import moment from "moment";
import { Image } from "react-bootstrap";
import { Dropdown, Menu, Input } from "antd";

import { FiSearch } from "react-icons/fi";
import { AiOutlineEllipsis, AiOutlineSend } from "react-icons/ai";
import { MdAttachFile } from "react-icons/md";

const menu = (
  <Menu>
    <Menu.Item key="0"> Mark as resolved </Menu.Item>
    <Menu.Item key="1"> Raise up to next level </Menu.Item>
    <Menu.Item key="2"> Assign to another admin </Menu.Item>
  </Menu>
);

function Disputes() {
  const renderElement = (message, index) => {
    return (
      <li className="chat-content" key={index}>
        <CImage imageUrl={message?.userImage} alt={message?.name} />
        <div className="flex-1">
          <span>
            {message?.name}, {moment(message?.date).format("LT")}
          </span>
          <p>{message?.message}</p>
        </div>
      </li>
    );
  };

  return (
    <div className="Disputes w-100 d-flex flex-column ">
      <CNav />
      <div className="two-card-view d-flex">
        <div className="first-card-view">
          <div className="first-card-view-header dispute-counter d-flex justify-content-between">
            <div className="d-flex flex-column">
              <h1 className="flex-1 mb-2">Disputes (3)</h1>
              <div className="d-flex align-items-center">
                <div className="border-right pr-2">
                  <p className="secondary-text">Resolved (2)</p>
                </div>
                <div className="ml-3">
                  <p className="secondary-text">Not resolved (1)</p>
                </div>
              </div>
            </div>
            <button className="mr-2">
              <FiSearch />
            </button>
          </div>

          <div className="first-card-view-body with-out-filter scroll-y">
            <UserList data={usersList} />
          </div>
        </div>
        <div className="second-card-view d-flex flex-column">
          <div className="second-card-view-header without-tabs d-flex align-items-center">
            <Image src={require("../../assets/images/user-2.jpg")} />
            <div className="d-flex flex-column flex-1">
              <div className="d-flex flex-row align-items-center">
                <MappedElement
                  data={["Daniel D", "Rita R", "Candie C"]}
                  renderElement={(users, index) => {
                    return (
                      <div>
                        <h1>{users},</h1>
                      </div>
                    );
                  }}
                />
                <div className="c-tag rounded m-0 ml-2">LEVEL 1</div>
              </div>
              <div className="secondary-text">4 Participants</div>
            </div>
            <FiSearch className="ant-dropdown-link secondary-text" />
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              overlayClassName="c-dropdown"
            >
              <a
                href={false}
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <AiOutlineEllipsis />
              </a>
            </Dropdown>
          </div>
          <div className="chat-tab-container">
            <div className="chat-container scroll-y">
              <div className="d-flex flex-column align-items-center">
                <div className="d-flex flex-row align-items-center mb-3">
                  <h1 className="secondary-text mr-1">{"Emily Ken"}</h1>
                  <span className="secondary-text">started a dispute</span>
                </div>
                <div className="d-flex  flex-row align-items-center mb-4">
                  <h1 className="secondary-text mr-1"> {"Emily K"}</h1>
                  <span className="secondary-text mr-1"> added</span>
                  <span className="highlight-text mr-1">{"Bernarr H"}</span>
                  <span className="secondary-text"> to this conversation</span>
                </div>
              </div>

              <ul className="chat">
                <MappedElement data={messages} renderElement={renderElement} />
              </ul>
            </div>
            <div className="chat-input-container c-input">
              <button className="chat-input-button right">
                <MdAttachFile />
              </button>
              <Input placeholder="Type a message…" />
              <button className="chat-input-button">
                <AiOutlineSend />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Disputes;
