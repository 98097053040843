import React, { Fragment, useState, useEffect } from "react";
import { BiDollar } from 'react-icons/bi'
import {
  CModal,
  CNav,
  Loading,
  CImage,
} from "../../uiComponents";
import {
  MappedElement,
  renderUserName,
  dataEmpty,
  formatNumberWithCommas,
  moneyFormat,
} from "../../utils/helper";
import { CSearchBar } from "../../uiComponents/";
import {
  Button,
  Form,
  Input,
  Menu,
  Checkbox,
  Radio,
} from "antd";
import { FiSearch } from "react-icons/fi";
import { Image } from "react-bootstrap";
import {
  getAllTeacherFinances,
  sendMoneyTeacher,
  getFinancesForAdmin,

} from "../../store/actions/Finances";
import { useDispatch, useSelector } from "react-redux";
import MyCODListingsComponent from "../myFinances/CashOnDeman";
const menu = (
  <Menu>
    <Menu.Item key="0"> 1st menu item </Menu.Item>
    <Menu.Item key="1"> 2nd menu item </Menu.Item>
    <Menu.Item key="3"> 3rd menu item </Menu.Item>
  </Menu>
);

const StudentsHours = () => {
  const onFinish = (values) => {
  };

  return (
    <Form
      name="students-hours-form"
      className="modal-form"
      initialValues={{}}
      onFinish={onFinish}
    >
      <h4 className="modal-form-title">Check Students Hours</h4>
      <div className="c-card mb-4">
        <div className="check-hours-section">
          <h4>6hrs</h4>
          <p>Hours leftover</p>
        </div>
      </div>
      <Form.Item className="mb-0 ml-2 mr-2">
        <Button
          htmlType="submit"
          className="c-button button-with-loading "
          block
        >
          Send Invoice
        </Button>
      </Form.Item>
    </Form>
  );
};

const SendMoney = (props) => {
  const dispatch = useDispatch();

  const { selectedTeacherFinance, setSendMoneyModal } = props;
  const sendMoneyCallback = () => {
    setSendMoneyModal(false);
  };
  const onFinish = (values) => {
    let payload = {
      teacher: selectedTeacherFinance._id,
    };
    dispatch(sendMoneyTeacher(payload, "teacherList", sendMoneyCallback()));
  };

  return (
    <Form
      name="send-money-form"
      className="modal-form"
      initialValues={{}}
      onFinish={onFinish}
    >
      <h4 className="modal-form-title">Send Money</h4>
      <Form.Item name="select" className="c-select-group">
        <div className="form-item-title">Select Payment Metthod</div>
        <Radio.Group className="mb-2">
          <Radio.Button className="p-5" value="parents" buttonStyle="solid">
            <Image
              className="w-100"
              src={require("../../assets/images/visa-master-card.png")}
            />
          </Radio.Button>
        </Radio.Group>
        <Radio.Group className="mb-2">
          <Radio.Button className="p-5" value="parents" buttonStyle="solid">
            <Image
              className="w-100"
              src={require("../../assets/images/visa-master-card.png")}
            />
          </Radio.Button>
        </Radio.Group>
        <Radio.Group className="mb-2">
          <Radio.Button className="p-5" value="parents" buttonStyle="solid">
            <div className="text">Cash on Demand</div>
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item className="mb-0">
        <Button
          htmlType="submit"
          className="c-button button-with-loading"
          block
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const Refund = () => {
  const onFinish = (values) => {
  };

  return (
    <Form
      name="refund-form"
      className="modal-form"
      initialValues={{}}
      onFinish={onFinish}
    >
      <h4 className="modal-form-title">Refund</h4>
      <div className="form-item-title">Students</div>
      <Form.Item name="search" className="c-input c-input-with-icon">
        <FiSearch />
        <Input placeholder="Search" />
      </Form.Item>

      <Checkbox.Group className="user-check-list">
        <MappedElement
          data={["", "", ""]}
          renderElement={(value, valex) => {
            return (
              <Checkbox value={valex} className="user-check-list-item">
                <CImage
                  className="user-check-list-item-image"
                  imageUrl={require("../../assets/images/user.jpg")}
                  alt=""
                />
                <h1 className="user-check-list-item-title">
                  Harold H.{valex + 1}
                </h1>
              </Checkbox>
            );
          }}
        />
      </Checkbox.Group>

      <div className="refund-content mb-4">
        <p>Fund Money As Per Dispute</p>
        <h4>$4268</h4>
      </div>

      <Form.Item className="mb-0">
        <Button
          htmlType="submit"
          className="c-button button-with-loading"
          block
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

function MyFinancesComponent(props) {
  const [sendMoneyModal, setSendMoneyModal] = useState(false);
  const [selectedTeacherFinance, setSelectedTeacherFinance] = useState();
  const { finances, loading, adminFinances, getAllTeacherFinances } = props;

  const handleUpdate = (isOpen, data = null) => {
    setSendMoneyModal(isOpen);
    setSelectedTeacherFinance(data);
  };

  const teacherPayment = (value, valex) => {
    const pending = value?.amountEarned - value?.amountReceived;
    return (
      <li className="list-item mb-1" key={valex}>
        <CImage
          className="image"
          imageUrl={
            value && value?.profile?.imageUrl ? value?.profile?.imageUrl : null
          }
          alt=""
        />
        <div className="flex-1 d-flex align-items-center">
          <h6 className="text">{value ? renderUserName(value) : ""}</h6>
          {/* <div className="c-tag rounded"> Disputes</div> */}
        </div>
        <div className="text flex-1">
          {value && pending
            ? `$ ${formatNumberWithCommas(pending)}`
            : "$ 0"}
        </div>
        <div className="text flex-1">
          {value && value?.amountEarned
            ? `$ ${formatNumberWithCommas(value?.amountEarned)}`
            : "$ 0"}
        </div>
        <div className="text flex-1">
          {value && value?.amountReceived
            ? `$ ${formatNumberWithCommas(value?.amountReceived)}`
            : "$ 0"}
        </div>
        <div className="button-groups d-flex flex-row">
          <Button
            className="c-button"
            onClick={() => handleUpdate(true, value)}
            disabled={
              value && value?.amountEarned && value?.amountEarned > 0
                ? null
                : true
            }
          >
            Send Money
          </Button>
        </div>

      </li>
    );
  };

  const renderValue = (data, key) => {
    return "$ " + data ? `${data}` : "0";
  };
  const searchPayToTeacher = (text) => {
    if (text !== "") {
      getAllTeacherFinances({ searchQueryText: text });
    } else {
      getAllTeacherFinances(null);
    }
  };

  return (
    <Fragment>
      <section className="finances d-flex flex-column">
        <h3 className="page-title m-0"> My Finances </h3>
        <div className="finances-sections mt-2">
          <div className="c-card finances-section">
            <div className="finances-content">
              <div className="finances-content-justify" >
                <h4>USD</h4>
                <div> <BiDollar size={23} /> </div>
              </div>

              <h4 className="finances-content-justify-tag"> {moneyFormat(renderValue(adminFinances?.total, "total")) ? moneyFormat(renderValue(adminFinances?.total, "total")) : 0}</h4>
              <p>Total fund ever received</p>
            </div>
          </div>
          <div className="c-card finances-section">
            <div className="finances-content">
              <h4 className="finances-content-justify-tag">
                <div className="finances-content-justify">
                  <h4>USD</h4>
                  <div><BiDollar size={23} /></div>
                </div>
                {moneyFormat(renderValue(adminFinances?.totalPaidToTeachers, "accrued")) ? moneyFormat(renderValue(adminFinances?.totalPaidToTeachers, "accrued")) : 0}</h4>
              <p>Total Paid To Tutors</p>
            </div>
          </div>
          <div className="c-card finances-section">
            <div className="finances-content">
              <div className="finances-content-justify">
                <h4>USD</h4>
                <div><BiDollar size={23} /> </div>
              </div>
              <h4 className="finances-content-justify-tag">

                {moneyFormat(renderValue(adminFinances?.totalInWallet, "totalCommissionPaid")) ? moneyFormat(renderValue(adminFinances?.totalInWallet, "totalCommissionPaid")) : 0}
              </h4>
              <p>Total Commission Paid</p>
            </div>
          </div>
        </div>
      </section>
      <div className="c-section flex-1">
        <div className="d-flex c-section-header">
          <h3 className="page-title m-0 flex-1"> Pay to Teacher </h3>
          <CSearchBar
            isShow={true}
            onTextChange={(e) => searchPayToTeacher(e)}
            isFixed={true}
          />
        </div>
        <div className="d-flex align-items-center mt-3 mb-4">
        </div>
        {loading ? (
          <Loading position="static" className="page-loading" />
        ) : (
          <>
            {finances && finances?.length ? (
              <Fragment>
                <div className="c-section-multiple-titles d-flex">
                  <h6 className="c-section-title flex-07">Teacher</h6>
                  <h6 className="c-section-title flex-06">Pending</h6>
                  <h6 className="c-section-title flex-06">Earned</h6>
                  <h6 className="c-section-title flex-1">Paid</h6>
                </div>
                <ul className="list mb-5">
                  <MappedElement
                    data={finances?.length ? finances : []}
                    renderElement={teacherPayment}
                  />
                </ul>
              </Fragment>
            ) : (
              dataEmpty()
            )}
          </>
        )}
      </div>
      <CModal
        show={sendMoneyModal}
        className="center small"
        onHide={() => setSendMoneyModal(false)}
      >
        <SendMoney
          setSendMoneyModal={setSendMoneyModal}
          selectedTeacherFinance={selectedTeacherFinance}
        />
      </CModal>
    </Fragment>
  );
}

function MyFinances() {

  const dispatch = useDispatch()

  const reduxState = useSelector(({ finances }) => {
    return {
      finances: finances?.finances,
      adminFinances: finances?.adminFinances,
      loading: finances?.getFinancesLoading,
      adminFinancesLoading: finances?.getAdminFinancesLoading,
      cashOndemand: finances?.cashOnDemands,
      cashOndemandLoading: finances?.getashOnDemandsLoading,
      updateCashOnDemandLoading: finances?.updateCashOnDemandLoading,
    };
  });

  const { finances, adminFinances, cashOndemand, updateCashoOnDemand, loading, adminFinancesLoading, cashOndemandLoading } = reduxState;

  const [studentsHours, setStudentsHours] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [codListings, setCodListings] = useState(false);

  const get = () => {
    dispatch(getAllTeacherFinances())
    dispatch(getFinancesForAdmin())
  }

  useEffect(() => {
    get();
  }, []);

  return (
    <div className="my-finances w-100 d-flex flex-column" >
      <CNav notificationVisible={false}>
        <div className="button-groups">
          {!codListings ? (
            <Button
              className="c-button m-0"
              onClick={() => setCodListings(true)}
            >
              Cash on Demand Receivings
            </Button>
          ) : null}
        </div>
      </CNav>
      {adminFinancesLoading || cashOndemandLoading ? (
        <div className="w-100 h-100">
          <Loading position="static" className="page-loading" />
        </div>
      ) : (
        <>
          {codListings ? (
            <MyCODListingsComponent
              data={cashOndemand}
              updateFunction={updateCashoOnDemand}
              backFunction={setCodListings}
            />
          ) : (
            <MyFinancesComponent
              finances={finances}
              adminFinances={adminFinances}
              getAllTeacherFinances={getAllTeacherFinances}
              loading={loading}
            />
          )}
        </>
      )}

      <CModal
        show={studentsHours}
        className="center small"
        onHide={() => setStudentsHours(false)}
      >
        <StudentsHours />
      </CModal>
      <CModal
        show={refundModal}
        className="center small"
        onHide={() => setRefundModal(false)}
      >
        <Refund />
      </CModal>
    </div>
  );
}
const mapStateToProps = ({ finances }) => {
  return {
    finances: finances?.finances,
    adminFinances: finances?.adminFinances,
    loading: finances?.getFinancesLoading,
    adminFinancesLoading: finances?.getAdminFinancesLoading,
    cashOndemand: finances?.cashOnDemands,
    cashOndemandLoading: finances?.getashOnDemandsLoading,
    updateCashOnDemandLoading: finances?.updateCashOnDemandLoading,
  };
};

export default MyFinances
