import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTopMentor } from "../../store/actions/TopMentor";
import { post } from "../../utils/methods";

import { Form, Input, Button } from "antd";
import { getAllTutors } from "../../store/actions/Tutors";

function AddMentor({ onHide, data }) {
  const dispatch = useDispatch();
  const [teacherId, setTeacherId] = useState(null);
  const [form] = Form.useForm();

  const reduxState = useSelector(({ topMentors, tutors }) => {
    return {
      addMentorLoading: topMentors?.addMentor,
      allTeachers: tutors?.tutors
    };
  });

  const {addMentorLoading, allTeachers}= reduxState;

  const callback = () => {
    onHide();
  };

  const onFinish = (values) => {
    let payload = {
      teacherId: values.teacherId,
      topMentor: true,
    };
    console.log(payload);
    dispatch(addTopMentor(payload, callback));
    onHide();
  };

  useEffect(()=> {
    dispatch(getAllTutors());
  }, [])

  const handleChange = (e) => {
    setTeacherId(e.target.value);
  };

  const renderInputs = (data = "") => {
    return (
      <>
        <Form.Item name="teacherId" className="c-input">
          <select
            style={{ width: "100%", padding: "10px" }}
            value={teacherId}
          >
            <option>Add Top Mentor</option>
            {allTeachers &&
              allTeachers.map((obj) => {
                {
                  return (
                    <option
                      style={{ textTransform: "uppercase" }}
                      value={obj._id}
                      onChange={handleChange}
                    >
                      {obj.profile.firstName + " " + obj.profile.lastName}
                    </option>
                  );
                }
              })}
          </select>
        </Form.Item>
      </>
    );
  };

  return (
    <Form
      name="add-mentor"
      className="modal-form"
      initialValues={{
        mentorName: data && data?.name ? data?.name : "",
      }}
      onFinish={onFinish}
      form={form}
    >
      <h4 className="modal-form-title">Select Your Top Mentor</h4>
      {renderInputs()}
      <Form.Item className="mb-0">
        <Button
          htmlType="submit"
          className="c-button button-with-loading"
          loading={addMentorLoading}
          block
        >
          Add
        </Button>
      </Form.Item>
    </Form>
  );
}

export default AddMentor;
