import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AiOutlinePlus } from "react-icons/ai";
import {
  CNav,
  CModal,
  Loading,
  UserListItem,
  UserList,
} from "../../uiComponents";
import {
  dataEmpty,
  MappedElement,
  renderUserName,
} from "../../utils/helper";
import AddSubject from "./AddSubject";
import { post } from "../../utils/methods";
import { getTopSubjects } from "../../store/actions/TopSubject";

function TopSubjects() {
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState("");
  //const [allSubjects, setAllSubject]= useState([]);

  const dispatch = useDispatch();

  const reduxState = useSelector(({ topSubjects }) => {
    return {
      getAllSubjectsLoading: topSubjects?.getAllSubjectsLoading,
      subjects: topSubjects?.subjects,
    };
  });

  const { getAllSubjectsLoading, subjects } = reduxState;

  // useEffect(() => {
  //   //get();
  //   post(`subject/getTopSubject`).then((res)=> {
  //     setAllSubject(res.data.data.entries);
  //   })
  // }, []);

  useEffect(()=> {
    dispatch(getTopSubjects())
  }, [])

  const renderSubjects = (obj, index) => {
    return (
      <UserListItem
        key={index}
        titleStyle="m-0"
        id={obj?._id ? obj?._id : ""}
        type="subject"
        name={obj.name} 
      />
    );
  };

  return (
    <div className="topMentor w-100 d-flex  flex-column">
      <CNav />
      <h3 className="page-title"> Top Subjects </h3>
      <div className="first-card-view-body with-out-filter scroll-y">
      <UserList data={subjects} loading={getAllSubjectsLoading}>
          <MappedElement data={subjects} renderElement={renderSubjects} />
      </UserList>
      </div>
      <button className="c-fab-button" onClick={() => setModalOpen(true)}>
        <AiOutlinePlus />
      </button>
      {modalOpen && (
        <CModal
          show={modalOpen}
          backdrop="static"
          keyboard={false}
          className="center small"
          onHide={() => setModalOpen(false)}
        >
          <AddSubject onHide={() => setModalOpen(false)} data={data} />
        </CModal>
      )}
    </div>
  );
}

export default TopSubjects;
