import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { getTopCourses } from "../../store/actions/TopCourses";
import "./Courses.css";
import { D } from "caniuse-lite/data/agents";
import {
  baseUrl
} from "../../utils/intercepter";

function Courses() {
  const dispatch = useDispatch();

  const [searchedTeacherData, setSearchedTeacherData] = useState([])

  const [clickedTeacherData, setClickedTeacherData] = useState([])

  useEffect(() => {
    // dispatch(getTopCourses())

    var mySearchArr = []
    var myClickArr = []


    Axios.post(`${baseUrl}/logging/getTopCourse`)
      .then((result) => {
        
        let searchDataArr = [result.data]
        // console.log(searchDataArr[0][0])
      let searchedRecord = searchDataArr[0].filter( i => i.subType.includes("SEARCHED_COURES") );
      for (let i = 0; i < searchedRecord.length; i++){
        let searchedArr = searchedRecord[i].searchedCourse

        mySearchArr = [...mySearchArr, searchedArr]
      }
      const map = mySearchArr.reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());
      // console.log([...map])
setSearchedTeacherData([...map])
// --------- XXXXXXX ----------

        // For Clicked Teacher ------
        let clickedArrData = [result.data]
        // console.log(clickedArrData[0].subType)
        let clickedRecord = clickedArrData[0].filter( i => i.subType.includes("CLICKED_COURES") );
        for (let i = 0; i < clickedRecord.length; i++){
          let clickedArr = clickedRecord[i].searchedCourse

          myClickArr = [...myClickArr, clickedArr]
        }
        const clickedMap = myClickArr.reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());
setClickedTeacherData([...clickedMap])
console.log([...clickedMap.keys()])

  // --------- XXXXXXX ----------
    })
    .catch((err) => {
      console.log("err : ", err);
    });
  }, []);

  

  let reduxState =  useSelector(({ course }) =>
   course)


  return (
    <>
    <div style={{ width: "100%" }}>
      {/* -------- For Searched Course ------ */}

      <div
        style={{ textAlign: "center", height: "max-content", width: "100%" }}
      >
        <h1>Searched Courses</h1>
      </div>

        <div style={{display: 'flex',justifyContent: 'center', marginTop: '30px'}}>
      <table
      style={{width: '50%'}}

      >
        <tr style={{border: '1px solid gray', backgroundColor: '#6c757d90'}}>

          <th style={{border: '1px solid gray', width: '50%', paddingLeft: '2%', height: '50px',}}>Searched Courses</th>
          <th style={{border: '1px solid gray', width: '50%', height: '50px', paddingLeft: '2%'}}>Count</th>
          
        </tr>
        {searchedTeacherData.length &&
          searchedTeacherData.map((obj) => {
              return (
                <tr
                  style={{
                    border: '1px solid gray'
                  }}
                >
                   <td style={{border: '1px solid gray', width: '50%', paddingLeft: '2%', background: '#ced4da', height: '50px', fontWeight: '600'}}>{obj[0].toUpperCase()}</td>

                   <td style={{border: '1px solid gray',  width: '50%', paddingRight: '2%', height: '50px', textAlign: 'right'}}>{obj[1]}</td>
                </tr>
              );
            
          })}
      </table>
      </div>

      {/* --------XXXXXXX------- */}



      {/* ------ For Clicked Course ------ */}

      <div
         style={{ textAlign: "center", height: "max-content", width: "100%", marginTop: '60px' }}
      >
        <h1>Clicked Courses</h1>
      </div>

      <div style={{display: 'flex',justifyContent: 'center', marginTop: '30px'}}>
      <table
      style={{width: '50%'}}

      >
        <tr style={{border: '1px solid gray', backgroundColor: '#6c757d90'}}>

          <th style={{border: '1px solid gray', width: '50%', paddingLeft: '2%', height: '50px',}}>Clicked Courses</th>
          <th style={{border: '1px solid gray', width: '50%', height: '50px', paddingLeft: '2%'}}>Count</th>
          
        </tr>
        {clickedTeacherData.length &&
          clickedTeacherData.map((obj) => {
              return (
                <tr
                  style={{
                    border: '1px solid gray'
                  }}
                >
                   <td style={{border: '1px solid gray', width: '50%', paddingLeft: '2%', background: '#ced4da', height: '50px', fontWeight: '600'}}>{obj[0].toUpperCase()}</td>

                   <td style={{border: '1px solid gray',  width: '50%', paddingRight: '2%', height: '50px', textAlign: 'right'}}>{obj[1]}</td>
                </tr>
              );
            
          })}
      </table>
      </div>
   

      {/* --------XXXXXXX------- */}
    </div>
  </>
  );
}

export default Courses;
