import TopSubject from '../constants/TopSubject';
import {post} from '../../utils/methods';
import {toast} from '../../utils/helper';

export const addTopSubject = (payload, CB) => (dispatch) => {
    dispatch({ type: TopSubject.ADD_SUBJECT_API, loading: true });
    post(`subject/updateTopSubject`, payload)
      .then(({ data }) => {
        dispatch({ type: TopSubject.ADD_SUBJECT_API, loading: false });
        CB && CB();
        dispatch(getTopSubjects());
        toast("success", `Successfully added in Top Subjects`);
      })
      .catch((error) => {
        dispatch({ type: TopSubject.ADD_SUBJECT_API, loading: false });
      });
};

export const getTopSubjects = (payload) => (dispatch) => {
  dispatch({
    type: TopSubject.GET_ALL_SUBJECTS_API,
    loading: true,
    subjects: [],
  });
  post('subject/getTopSubject', payload)
  .then(({ data }) => {
    //const mentors = data?.data?.entries ? data.data.entries : []
   
    dispatch({
        type: TopSubject.GET_ALL_SUBJECTS_API,
        loading: false,
        // mentors: (data.data && data.data.entries && data.data.entries.length) ? data.data.entries : []
        subjects: data?.data?.entries ? data.data.entries : []
      });
})
.catch((error) => {
  console.log(error)
    dispatch({ type: TopSubject.GET_ALL_SUBJECTS_API, loading: false, subjects: [] });
});
};