import React from "react";
import logos from '../../assets/svgs/hikma-app-icon-blue.svg';
import background from "../../assets/svgs/hikma-logo.svg";

function AuthDesignWrapper({ children, title, paragraph, secondParagraph, imagePath = require('../../../src/assets/images/2.jpg'), dot }) {
    return (
        <div className="auth-design-wrapper d-flex flex-row h-100">
            <div className="auth-design-wrapper-left d-flex">
                <div className="auth-design-wrapper-left-container d-flex w-100">
                    <div className="col-5 p-0">
                        <div className="text-center">
                            <img className="logo logo-style" src={logos} alt="logohikma" />
                            <div className="login-style" >Login</div>
                        </div>
                        {paragraph ? <p>{paragraph}</p> : null}
                        {secondParagraph ? <p>{secondParagraph}</p> : null}
                        <div className="inner-container">
                            {children}
                        </div>
                        <p className="warning">* Non-Authoried person are strictly advised not to try logging in! </p>
                    </div>
                </div>
            </div>
            <div className="auth-design-wrapper-right logo-background" >
                <img className="logo logo-tag" src={background} alt="Himka" draggable="false" />
            </div>
        </div>
    );
}

export default AuthDesignWrapper;
