export const firstName = [
  {
    required: true,
    message: "Please enter your first name!",
  },
];
export const lastNameRule = [
  {
    required: true,
    message: "Please enter your last name!",
  },
];
export const nickNameRule = [
  {
    required: true,
    message: "Please enter your nick name!",
  },
];
export const middleNameRule = [
  {
    required: true,
    message: "Please enter your last name!",
  },
];
export const hourlyRatesRule = [
  {
    required: true,
    message: "Please enter your hourly rates!",
  },
];
export const monthlyRatesRule = [
  {
    required: true,
    message: "Please enter your monthly rates!",
  },
];
export const passwordRule = [
  {
    required: true,
    message: "Please enter password!",
  },
  { min: 8, message: "please enter at least 8 digit password!" },
];
export const commentsRule = [
  {
    required: true,
    message: "Please enter rejection comments",
  },
];
export const newPasswordRule = [
  {
    required: true,
    message: "Please enter new password!",
  },
  { min: 8, message: "please enter at least 8 digit password!" },
];
export const confirmPasswordRule = (validatePassword) => [
  {
    required: true,
    message: "Please enter confirm password!",
  },
  { min: 8, message: "please enter at least 8 digit password!" },
  { validator: validatePassword },
];
export const phoneRule = [
  {
    required: true,
    message: "Please enter phone number!",
  },
];
export const emailRule = [
  {
    type: "email",
    message: "Please enter your valid email!",
  },
  {
    required: true,
    message: "Please enter your email!",
  },
];
export const countryRule = [
  {
    required: true,
    message: "Please enter your country!",
  },
];
export const addressRule = [
  {
    required: true,
    message: "Please enter your address!",
  },
];
export const sateRule = [
  {
    required: true,
    message: "Please enter your state!",
  },
];
export const cityRule = [
  {
    required: true,
    message: "Please enter your city!",
  },
];
export const zipCodeRule = [
  {
    required: true,
    message: "Please enter your zip code!",
  },
];
export const gpaRule = [
  {
    min: 0.0,
    max: 4.0,
    message: "please enter gpa least less than 4.0!",
  },
];
export const subjectName = [
  {
    required: true,
    message: "please enter subject name",
  },
];
export const subject = [
  {
    required: true,
    message: "please select subject",
  },
];
export const gradeName = [
  {
    required: true,
    message: "please enter grade name",
  },
];
export const grade = [
  {
    required: true,
    message: "please select grade",
  },
];
export const curriculumName = [
  {
    required: true,
    message: "please enter curriculum name",
  },
];
export const curriculum = [
  {
    required: true,
    message: "please select curriculum",
  },
];

export const type = [
  {
    required: true,
    message: "please select type",
  },
];
export const group = [
  {
    required: true,
    message: "please select group",
  },
];
export const announcementTitle = [
  {
    required: true,
    message: "please enter announcement title",
  },
];
export const announcementMessage = [
  {
    required: true,
    message: "please enter announcement Message",
  },
];
export const sendMoneyAmount = [
  {
    required: true,
    message: "please enter amount",
  },
];

export const mentorName = [
  {
    required: true,
    message: "please enter mentor name",
  },
];
