import React from 'react';
import { Spin } from 'antd';

const Loading = ({className = '', position = 'fixed'}) => {
    return(
        <div className={`loading ${className}`} style={{position: position}}>
            <Spin size='large'/>
        </div>
    )
};

export default Loading
