import React from "react";
import {
  CCardWrapper,
  CNav,
  CTags,
  SelectCategoriesFilter,
} from "../../uiComponents";
import { Tabs } from "antd";
import { categories } from "../data";
import { FiX } from "react-icons/fi";
import { MappedElement } from "../../utils/helper";
const { TabPane } = Tabs;

function Questions() {
  const renderFilter = () => {
    return (
      <div className="d-flex align-items-center mt-3 mb-4">
        <div className="d-flex flex-1">
          <div className="c-tag rounded mb-0">
            Curriculum
            <button>
              <FiX />
            </button>
          </div>
          <div className="c-tag rounded mb-0">
            Subject
            <button>
    
              <FiX />
            </button>
          </div>
          <div className="c-tag rounded mb-0">
            Grade
            <button>
       
              <FiX />
            </button>
          </div>
          <div className="clear-all-button">
            <span>Clear All</span>
          </div>
        </div>
        <SelectCategoriesFilter categories={categories} />
      </div>
    );
  };

  const answerRenderItem = () => {
    return (
      <div className="question-card main">
        <div className="question-card-header">
          <div className="replied"> Replied back in 1h </div>
          <div className="user-question">
            <img
              className="user-image"
              src={require("../../assets/images/user-3.jpg")}
              alt=""
            />
            <div className="user-question-info">
              <p>
                {" "}
                Dianne R. <span> Apr 23, 6:23PM</span>
              </p>
              <h3>How to write an article?</h3>
              <CTags data={["Curriculum", "Grade", "Subject"]} />
            </div>
          </div>
        </div>
        <div className="question-card-body">
          <div className="message-list">
            <div className="message-list-item">
              <img src={require("../../assets/images/user-3.jpg")} alt="" />
              <p>
                <b>Mary (You):</b>
                Use subheadings to break up the text and make clear paragraphs.
                Write in a semi- informal, conversational style...
                <a href={false}>Show More</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const unanswerRenderItem = () => {
    return (
      <div className="question-card main">
        <div className="question-card-header">
          <div className="user-question m-0">
            <img
              className="user-image"
              src={require("../../assets/images/user-3.jpg")}
              alt=""
            />
            <div className="user-question-info">
              <p>
                {" "}
                Dianne R. <span> Apr 23, 6:23PM</span>
              </p>
              <h3>How to write an article?</h3>
              <CTags data={["Curriculum", "Grade", "Subject"]} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="questions d-flex flex-column w-100">
      <CNav />
      <h3 className="page-title"> Questions </h3>
      <CCardWrapper className="c-card-wrapper w-100 p-4 flex-1">
        <div className="questions-tab-container">
          <Tabs defaultActiveKey="answered" type="card" className="c-card-tab">
            <TabPane tab="Answered (76)" key="answered">
              {renderFilter()}
              <div className="question-cards">
                <MappedElement
                  data={["", "", ""]}
                  renderElement={answerRenderItem}
                />
              </div>
            </TabPane>
            <TabPane tab="Unanswered (2)" key="unanswered">
              {renderFilter()}
              <div className="question-cards">
                <MappedElement
                  data={["", "", ""]}
                  renderElement={unanswerRenderItem}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </CCardWrapper>
    </div>
  );
}
export default Questions;
