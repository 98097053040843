import React, {Fragment, useEffect, useState} from "react";
import { MappedElement, renderTotalMeetings, dataEmpty, renderUserImage , renderUserName } from "../../utils/helper";
import { teachersList } from "../data";
import { Menu, Dropdown } from "antd";
import { AiOutlineEllipsis } from "react-icons/ai";
import { CModal, CImage } from "../../uiComponents";
import { getAllIntorductoryMeetings } from "../../store/actions/Parents";
import { connect } from "react-redux";
import { get, reject } from "lodash";
import { Loading } from "../../uiComponents";
import moment from 'moment';

// const menu = (
//   <Menu>
//     <Menu.Item key="0"> Option 1 </Menu.Item>
//     <Menu.Item key="1"> Option 2 </Menu.Item>
//     <Menu.Item key="2"> Option 3 </Menu.Item>
//   </Menu>
// );

function IntroductoryMeeting(props) {
  const [detailModalIsOpen, setDetailModal] = useState(false);
  const { parentId, getAllIntorductoryMeetings, loading, meetings, totalHired, totalRejected } = props;
  const toggleDetailModal = (isOpen, data = {}) => {
    setDetailModal(isOpen);
  };

  useEffect(() => {
    let payload = {
      parent: parentId
    }
    getAllIntorductoryMeetings(payload, getAllMeetingsCallBack)
  }, [parentId]);

  const getAllMeetingsCallBack = () => {
  };

  const renderUsersItem = (value, index) => {
    let length = "";
    if (value.startTime && value.endTime) {
      let startDate = value.startTime;
      let endDate = value.endTime;
      let start = moment(startDate).format("hh:mm:ss");
      let end = moment(endDate).format("hh:mm:ss");
      length = moment.utc(moment(end, "hh:mm:ss").diff(moment(start, "hh:mm:ss"))).format("hh:mm:ss")
    }

    return (
      <div>
        <li
          className="list-item p-0 mb-1"
          onClick={() => toggleDetailModal(false)}
          key={index}
        >
          <div className="header d-flex">
            <CImage className="image" imageUrl={renderUserImage(value.teacher.imageUrl)} alt="" />
            <div className="flex-1 d-flex align-items-center">
              <h6 className="text m-0">
                <b className="m-0">{renderUserName(value.teacher)}</b>
              </h6>
            </div>
            <div className="flex-1">
              <div className="c-tag rounded mb-0">{value.isHired ? "Yes" : "No"}</div>
            </div>

            <div className="secondary-text flex-1"> {length ? length : 0}</div>
            <div className="secondary-text flex-1">{value.createdAt ? moment(value.createdAt).fromNow() : '-'}</div>

            {/* <Dropdown
              overlay={menu}
              trigger={["click"]}
              overlayClassName="c-dropdown"
            >
              <a
                href={false}
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <AiOutlineEllipsis />
              </a>
            </Dropdown> */}
          </div>
          {/* <div className="footer text">
            is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy text ever since the
          </div> */}
        </li>
      </div>
    );
  };

  return (
    <div className="introductory-meeting invoicing-status-tab-container h-100 d-flex flex-column">
      {loading ? <div className="w-100 h-100">
            <Loading position="static" className="page-loading" />
          </div> :  <Fragment>
            <div className="invoicing-hours-section d-flex">
              <div className="invoicing-hours-inner-section left">
                <h2>{meetings && meetings.length ? meetings.length : 0}</h2>
                <span>Total Meetings</span>
              </div>
              <div className="invoicing-hours-inner-section left">
                <h2>{totalHired ? totalHired : 0}</h2>
                <span>Hired</span>
              </div>
              <div className="invoicing-hours-inner-section right">
                <h2>{totalRejected ? totalRejected : 0}</h2>
                <span>Rejected</span>
              </div>
            </div>
            {(meetings && meetings.length) ?
                <div className="c-section mb-5">
                  <div className="c-section-multiple-titles d-flex">
                    <h6 className="c-section-title flex-1">Teacher</h6>
                    <h6 className="c-section-title flex-08">Hired</h6>
                    <h6 className="c-section-title flex-08">Length</h6>
                    <h6 className="c-section-title flex-1">Date</h6>
                  </div>
                  <ul className="list p-0">
                    <MappedElement data={meetings} renderElement={renderUsersItem} />
                  </ul> </div> : dataEmpty()}
          </Fragment>}
      <CModal
        show={detailModalIsOpen}
        className="right-side"
        onHide={() => toggleDetailModal(false)}
      >
        <div className="user-detail-view d-flex flex-column scroll-y">
          <div className="user-image-with-name">
            <CImage imageUrl={require("../../assets/images/user-2.jpg")} alt="" />
            <h4>Dianne R.</h4>
          </div>
          <ul>
            <li>
              <span>Name</span> <span>Dianne Rid</span>
            </li>
            <li>
              <span>Nickname</span> <span>Dianne</span>
            </li>
            <li>
              <span>Email</span> <span>dianne.rid@yahoo.com</span>
            </li>
            <li>
              <span>Gender</span> <span>Female</span>
            </li>
            <li>
              <span>Phone</span> <span>(631) 681-3147</span>
            </li>
            <li>
              <span>Curriculum</span> <span>Lorem ipsum</span>
            </li>
            <li>
              <span>Grade</span> <span>9</span>
            </li>
            <li>
              <span>Subjects</span> <span>Spanish, French</span>
            </li>
          </ul>
        </div>
      </CModal>
    </div>
  );
}
const mapStateToProps = ({ parents }) => {
  return {
    loading: parents.getAllMeetingLoading,
    meetings: parents.meetings,
    totalHired: parents.totalHired,
    totalRejected: parents.totalRejected,

  };
};
const mapDispatchToProps = {
  getAllIntorductoryMeetings
};
export default connect(mapStateToProps, mapDispatchToProps)(IntroductoryMeeting);
