import TopMentor from "../constants/TopMentor";
import { post } from "../../utils/methods";
import { toast } from "../../utils/helper";

export const addTopMentor = (payload, CB) => (dispatch) => {
  dispatch({ type: TopMentor.ADD_MENTOR_API, loading: true });
  post(`teacher/updateTopMentor`, payload)
    .then(({ data }) => {
      dispatch({ type: TopMentor.ADD_MENTOR_API, loading: false });
      CB && CB();
      dispatch(getAllMentor());
      toast("success", `Successfully added in Top mentor`);
    })
    .catch((error) => {
      dispatch({ type: TopMentor.ADD_MENTOR_API, loading: false });
    });
};

export const getAllMentor = (payload) => (dispatch) => {
  dispatch({
    type: TopMentor.GET_ALL_MENTORS_API,
    loading: true,
    mentors: [],
  });
  post('teacher/getTopMentor', payload)
  .then(({ data }) => {
    //const mentors = data?.data?.entries ? data.data.entries : []
   
    dispatch({
        type: TopMentor.GET_ALL_MENTORS_API,
        loading: false,
        // mentors: (data.data && data.data.entries && data.data.entries.length) ? data.data.entries : []
        mentors: data?.data?.entries ? data.data.entries : []
      });
})
.catch((error) => {
  console.log(error)
    dispatch({ type: TopMentor.GET_ALL_MENTORS_API, loading: false, mentors: [] });
});
};
