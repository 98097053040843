// Countries api
import Categories from "../constants/Categories";
import { post } from "../../utils/methods";
import { toast } from "../../utils/helper";
import { getAll } from "./Common";

export const createCategory = (type, payload, CB) => (dispatch) => {
  dispatch({ type: Categories.CREATE_CATEGORY_API, loading: true });
  console.log(payload, 'payload');
  post(`${type}/create`, payload)
    .then(({ data }) => {
      dispatch({ type: Categories.CREATE_CATEGORY_API, loading: false });
      CB && CB();
      dispatch(getAll(type));
      dispatch(getAllCurriculum({ isFillSubjectDetails: true, showAllCurriculum: true }));
      toast("success", `Successfully created ${type}`);
    })
    .catch((error) => {
      dispatch({ type: Categories.CREATE_CATEGORY_API, loading: false });
    });
};

export const getAllCurriculum = (payload) => (dispatch) => {
  dispatch({
    type: Categories.GET_ALL_CURRICULUM_API,
    loading: true,
    curriculum: [],
  });
  post("curriculum/getAll", {...payload, showAllCurriculum: true})
    .then(({ data }) => {
      dispatch({
        type: Categories.GET_ALL_CURRICULUM_API,
        loading: false,
        curriculum: data.data && data.data.entries ? data.data.entries : [],
      });
    })
    .catch((error) => {
      dispatch({
        type: Categories.GET_ALL_CURRICULUM_API,
        loading: false,
        curriculum: [],
      });
    });
};

export const deleteSubject = (payload, CB) => (dispatch) => {
  dispatch({ type: Categories.DELETE_SUBJECT, loading: true });
  post("/subject/delete", payload)
    .then((data) => {
      dispatch({ type: Categories.DELETE_SUBJECT, loading: false });
      CB && CB();
      toast("success", `Successfully Deleted Subject `);

    })
    .catch((error) => {
      dispatch({ type: Categories.DELETE_SUBJECT, loading: false });
    });
};

export const deleteCirriculum = (payload, CB) => (dispatch) => {
  dispatch({ type: Categories.DELETE_CURRICULUM, loading: true });
  post("/curriculum/delete", payload).then((data) => {
    dispatch({ type: Categories.DELETE_CURRICULUM, loading: false });
    CB && CB();
    toast("success", `Successfully Deleted Curriculum `);
  }).catch((error) => {
    dispatch({ type: Categories.DELETE_CURRICULUM, loading: false })
  })
};

export const deleteGrade = (payload, CB) => (dispatch) => {
  dispatch({ type: Categories.DELETE_GRADE, loading: true });
  post("/grade/delete", payload).then((data) => {
    CB && CB();
    toast("success", `Successfully Deleted Grade `);
  }).then((error) => {
    dispatch({ type: Categories.DELETE_GRADE, loading: false })
  })
};


export const updateData = (type, payload, CB) => (dispatch) => {
  dispatch({ type: Categories.UPDATE_CURRICULUM, loading: true });
  post(`${type}/update`, payload).then((data) => {
    dispatch({ type: Categories.UPDATE_CURRICULUM, loading: false });
    toast("success", `Successfully Updated`);
    dispatch(getAllCurriculum({ isFillSubjectDetails: true, showAllCurriculum: true }));
    CB && CB();
  }).then((error) => { dispatch({ type: Categories.UPDATE_CURRICULUM, loading: false }); })



};