import React from "react";

import { MappedElement } from "../../utils/helper";
import { teachersList } from "../data";
import { Dropdown, Menu } from "antd";
import { AiOutlineEllipsis } from "react-icons/ai";
import { CImage } from "../../uiComponents";

function ParentTeacherMeeting() {
  const menu = (
    <Menu>
      <Menu.Item key="0"> Option 1 </Menu.Item>
      <Menu.Item key="1"> Option 2 </Menu.Item>
      <Menu.Item key="2"> Option 3 </Menu.Item>
    </Menu>
  );

  const parentList = (value, index) => {
    return (
      <li className="list-item mb-1" key={index}>
        <CImage className="image" imageUrl={value?.imageUrl} alt="" />
        <div className="flex-1 d-flex align-items-center">
          <h6 className="text">
            <b>{value.name}</b>
          </h6>
        </div>
        <div className="secondary-text flex-1">{value?.length}</div>
        <div className="secondary-text flex-1">{value?.date}</div>

        <Dropdown
          overlay={menu}
          trigger={["click"]}
          overlayClassName="c-dropdown"
        >
          <a
            href={false}
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            <AiOutlineEllipsis />
          </a>
        </Dropdown>
      </li>
    );
  };

  return (
    <div className="parent-teacher-meeting c-section">
      <div className="c-section-multiple-titles d-flex">
        <h6 className="c-section-title flex-1">Parent</h6>
        <h6 className="c-section-title flex-09">Length</h6>
        <h6 className="c-section-title flex-09">Date</h6>
      </div>
      <ul className="list">
        <MappedElement data={teachersList} renderElement={parentList} />
      </ul>
    </div>
  );
}
export default ParentTeacherMeeting;
