import Common from "../constants/Common";
import { get, post } from "../../utils/methods";

export const getCountries = () => (dispatch) => {
  dispatch({ type: Common.GET_ALL_COUNTRIES_API, loading: true });
  get("https://restcountries.eu/rest/v2/all")
    .then(({ data }) => {
      dispatch({
        type: Common.GET_ALL_COUNTRIES_API,
        loading: false,
        countries: data,
      });
    })
    .catch((error) => {
      dispatch({ type: Common.GET_ALL_COUNTRIES_API, loading: false });
    });
};

export const getAllSubjects = () => (dispatch) => {
  dispatch({ type: Common.GET_ALL_SUBJECT_API, loading: true, subjects: [] });
  post("subject/getAll")
    .then(({ data }) => {
      dispatch({
        type: Common.GET_ALL_SUBJECT_API,
        loading: false,
        subjects: data.data && data.data.entries ? data.data.entries : [],
      });
    })
    .catch((error) => {
      dispatch({
        type: Common.GET_ALL_SUBJECT_API,
        loading: false,
        subjects: [],
      });
    });
};

export const getAllCurriculum = () => (dispatch) => {
  dispatch({
    type: Common.GET_ALL_CURRICULUM_API,
    loading: true,
    curriculum: [],
  });
  post("curriculum/getAll")
    .then(({ data }) => {
      dispatch({
        type: Common.GET_ALL_CURRICULUM_API,
        loading: false,
        curriculum: data.data && data.data.entries ? data.data.entries : [],
      });
    })
    .catch((error) => {
      dispatch({
        type: Common.GET_ALL_CURRICULUM_API,
        loading: false,
        curriculum: [],
      });
    });
};

export const getAllGrades = () => (dispatch) => {
  dispatch({ type: Common.GET_ALL_GRADES_API, loading: true, grades: [] });
  post("grade/getAll")
    .then(({ data }) => {
      dispatch({
        type: Common.GET_ALL_GRADES_API,
        loading: false,
        grades: data.data && data.data.entries ? data.data.entries : [],
      });
    })
    .catch((error) => {
      dispatch({ type: Common.GET_ALL_GRADES_API, loading: false, grades: [] });
    });
};

export const getAll = (type) => (dispatch) => {
  if (type === "subject") {
    dispatch(getAllSubjects());
  } else if (type === "grade") {
    dispatch(getAllGrades());
  } else if (type === "curriculum") {
    dispatch(getAllCurriculum());
  } else {
    // dispatch(getCountries());
    dispatch(getAllSubjects());
    dispatch(getAllCurriculum());
    dispatch(getAllGrades());
  }
};

