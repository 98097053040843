import TopMentor from "../constants/TopMentor";

const initialState = {
  addMentor: false,
  getAllMentorsLoading: false,
  mentors: [],
};

export default (state = initialState, action = {}) => {

  switch (action.type) {
    case TopMentor.ADD_MENTOR_API:
      return {
        ...state,
        addMentor: action.loading,
      };
    case TopMentor.GET_ALL_MENTORS_API:
      return {
        ...state,
        getAllMentorsLoading: action.loading,
        mentors: action.mentors,
      };
    default:
      return state;
  }
};
