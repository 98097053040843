import Parents from "../constants/Parents";
import {post} from "../../utils/methods";

export const getAllParents = (payload , CB) => (dispatch) => {
    dispatch({type: Parents.GET_ALL_PARENTS_API, loading: true, data: []});
    post('parent/getAll',payload)
        .then(({data}) => {
            let parents = (data.data.entries && data.data.entries.length) ? data.data.entries : [];
            dispatch({
                type: Parents.GET_ALL_PARENTS_API,
                loading: false,
                data: (data.data && data.data.entries) ? data.data.entries : []
            });
            CB && CB(parents)
        })
        .catch((error) => {
            dispatch({type: Parents.GET_ALL_PARENTS_API, loading: false, data: []});
        });
};

export const getAllIntorductoryMeetings = (payload , CB) => (dispatch) => {
    dispatch({type: Parents.GET_ALL_INTRODUCTORY_MEETING_API, loading: true, data: []});
    post('parent/getIntroductoryMeetings' , payload)
        .then(({data}) => {
            let meetings = (data.data.entries && data.data.entries.length) ? data.data.entries : [];
            dispatch({
                type: Parents.GET_ALL_INTRODUCTORY_MEETING_API,
                loading: false,
                data: (data.data && data.data.entries) ? data.data.entries : [],
                totalHired: data.data && data.data.totalHired? data.data.totalHired: 0,
                totalRejected: data.data && data.data.totalRejected? data.data.totalRejected: 0

            });
            CB && CB(meetings)
        })
        .catch((error) => {
            dispatch({type: Parents.GET_ALL_INTRODUCTORY_MEETING_API, loading: false, data: []});
        });
};
