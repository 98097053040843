import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CNav,
  SelectCategoriesFilter,
  Loading,
  CTags,
  UserList,
  UserListItem,
  CImage,
} from "../../uiComponents";
import { feedback, usersList, categories } from "../data";
import { Tabs, Dropdown, Menu, Pagination } from "antd";
import { FiSearch, FiX } from "react-icons/fi";
import { AiOutlineEllipsis } from "react-icons/ai";
import {
  dataEmpty,
  getTags,
  MappedElement,
  renderUserName,
  renderUserImage,
  renderScore,
  renderScoreByAdmin,
  getProfilePercentage
} from "../../utils/helper";
import { getAllTutors } from "../../store/actions/Tutors";
import Promos from "./Promos";
import Details from "./Details";
import PaymentDetails from "./PaymentDetails";
import Credentials from "./Credentials";
import ParentTeacherMeeting from "./ParentTeacherMeeting";
import FeedBack from "./FeedBack";
import TeacherProfileEdits from "./TeacherProfileEdits";
import IntroductoryMeeting from "./IntroductoryMeeting";
import IntroductoryVideo from "./IntroductoryVideo";
import Students from "./Students";
import Subjects from "./Subjects";
import CSearchBar from "../../uiComponents/cSearchBar/CSearchBar";
import Price from "./Price";
import { post } from "../../utils/methods";
import { baseUrl } from "../../utils/intercepter";
import Schedule from "./Schedule";
import Recordings from "./Recordings";

const { TabPane } = Tabs;



function Tutors({ location, history }) {
  const dispatch = useDispatch();
  const reduxState = useSelector(({ tutors }) => {
    return {
      getAllTutorsLoading: tutors?.getAllTutorsLoading,
      tutors: tutors?.tutors,
      metaData: tutors.metaData, 

      myStudentCount: tutors?.myStudents?.length,
    };

  });

  const { getAllTutorsLoading, tutors } = reduxState;

  const [selectedTutor, updateSelectedTutor] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [showSearch, setShowSearch] = useState(null);
  const [data, setData] = useState("");
  const [limit, setLimit] = useState(10)
  const [pages, setPages] = useState(1);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(20);
  const { state } = location;

  const [teacherscoreByAdmin, setTeacherscoreByAdmin] = useState(0)

  const selectTutor = (data = null) => {
    updateSelectedTutor(data);
  };

  const getAllTutorsCallBack = (payload) => {
    if (state && state?.id) {
      let findTutor = payload.find((obj) => obj?._id === state?.id);
      updateSelectedTutor(findTutor);
    } else if (payload && payload?.length) {
      updateSelectedTutor(payload[0]);
    }
  };

  const get = (text = '') => {
    dispatch(
      getAllTutors(
        {
          ...(text && { searchQueryText: text }),
          sortByScore: true,
          limit: limit,
          page: pages,
          sortByAdd:true
        },
        getAllTutorsCallBack
      )
    );
  };


  const [scheduleData, setScheduleData] = useState([])
  const [recordingData, setRecordingData] = useState([])

  useEffect(()=>{
    post('schedule/getOneTeacherSchedule', {_id: selectedTutor?._id})
    .then((res)=>{
      setScheduleData(res.data)
    })
    // console.log('sel',selectedTutor)
    post('resource/getOneTeacherRecording', {_id: selectedTutor?._id})
    .then((res)=>{
      setRecordingData(res.data)
    })
  },[selectedTutor])


  useEffect(() => {
    get();
  }, [pages]);

  const handleChange = (pages) => {
    setPages(pages)
    setMinIndex(pages - 1 * limit)
    setMaxIndex(pages * limit)
  }

  const renderTutor = (obj, index) => {
    // console.log(obj)
    let newTotal = getProfilePercentage(obj).total
    // console.log('new tot :', newTotal)
    return (
      <UserListItem
        key={index}
        titleStyle="m-0"
        id={obj?._id ? obj?._id : ""}
        selectedId={selectedTutor ? selectedTutor?._id : null}
        imageUrl={renderUserImage(obj?.profile?.imageUrl)}
        name={renderUserName(obj)}
        scoreByAdmin={
          obj?.profile?.scoreByAdmin ? obj?.profile?.scoreByAdmin : ""
        }
        courses={
          obj?.profile?.courses ? obj?.profile?.courses : []
        }
        kyc = {obj.kyc}
        newTotal = {newTotal}
        onClick={() => selectTutor(obj)}
      />
    );
  };

  const navigateTo = (pathname, id) => {
    history.push({ pathname, state: { id } });
  };
  const searchTutor = (text) => {
    setData(text);
    console.log(text);
    get(text);
  };

  const toggleSearch = () => { showSearch && data?.length && searchTutor(); setShowSearch(!showSearch) };

  const updateScoreByAdmin = ()=>{
    const payload = {teacherId: selectedTutor._id, scoreByAdmin: parseInt(teacherscoreByAdmin)}
    post(`${baseUrl}teacher/updateScoreByAdmin`, payload)
  }

  return (
    <div className=" w-100 d-flex flex-column "  >
      <CNav />
      <div className="two-card-view d-flex stop mb-4">
        <div className="first-card-view">
          <div className="first-card-view-header d-flex">
            <h1 className="flex-1 tutors-heading">{showSearch ? "" : "Tutors"}</h1>
            <button onClick={() => toggleSearch()}>
              {showSearch ? <FiX /> : <FiSearch />}
            </button>

            <CSearchBar
              resetSearch={showSearch}
              isShow={showSearch}
              onTextChange={(e) => e && searchTutor(e)}
            />
          </div>

          <div className="first-card-view-body with-out-filter scroll-y">
            <UserList data={tutors} loading={getAllTutorsLoading}>
              <MappedElement data={tutors} renderElement={renderTutor} role="tutor"/>
            </UserList>
            <Pagination
              pageSize={limit}
              current={pages}
              total={reduxState?.metaData?.totalDocuments}
              onChange={handleChange}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}
            />
          </div>
        </div>

        <div className="second-card-view d-flex flex-column">
          {selectedTutor && Object.keys(selectedTutor)?.length ? (
            <Fragment>
              <div className="second-card-view-header d-flex align-items-center">
                <CImage
                  imageUrl={
                    selectedTutor ? selectedTutor?.profile?.imageUrl : null
                  }
                  alt=""
                />

                <h1 className="flex-1">{renderUserName(selectedTutor)}</h1>
                <label style={{marginRight: '10px'}}>Score By Admin :  </label>
                <input type='number'
                style={{paddingLeft: '1%'}}
                placeholder={renderScoreByAdmin(selectedTutor)}
                onChange={(e)=> {setTeacherscoreByAdmin(e.target.value)} }/>
                <button
                style={{background:'#21047c', color: '#fff', outline: 'none', border: 'none', fontSize: '12px', padding: '6px 10px'}}
                onClick={updateScoreByAdmin}
                >Update</button>


              </div>
              <Tabs
                className="h-100 d-flex flex-column c-tabs"
                defaultActiveKey="details"
                onTabClick={() => setPlaying(false)}
              >
                <TabPane tab="Details" key="details">
                  <div className="content-container scroll-y">
                    <Details teacher={selectedTutor} />
                  </div>
                </TabPane>
                <TabPane tab="Subjects" key="subjects">
                  <div className="content-container scroll-y">
                    <Subjects
                      history={history}
                      onSubjectClick={(route, id) =>
                        navigateTo("/my-subjects", id)
                      }
                      data={
                        selectedTutor ? selectedTutor : []
                      }
                    />
                  </div>
                </TabPane>
                <TabPane tab="Credentials" key="credentials">
                  <div className="content-container scroll-y">
                    <Credentials teacher={selectedTutor && selectedTutor} />
                  </div>
                </TabPane>
            
                <TabPane tab="Introductory Video" key="introductoryVideo">
                  <div className="content-container scroll-y">
                    <IntroductoryVideo
                      history={history}
                      data={selectedTutor}
                      playing={playing}
                      onRender={() => {
                        setPlaying(true);
                      }}
                    />
                  </div>
                </TabPane>

                <TabPane tab="Price" key="price">
                  <div className="content-container scroll-y">
                    <Price teacher={selectedTutor} />
                  </div>
                </TabPane>

                <TabPane tab="Schedule" key="schedule">
                  <div className="content-container scroll-y">
                    <Schedule teacher={selectedTutor} scheduleData={scheduleData}/>
                  </div>
                </TabPane>

                <TabPane tab="Recordings" key="recordings">
                  <div className="content-container scroll-y">
                    <Recordings teacher={selectedTutor}  recordingData={recordingData} />
                  </div>
                </TabPane>

              </Tabs>
            </Fragment>
          ) : (
            dataEmpty()
          )}
        </div>
      </div>
    </div>
  );
}


export default Tutors;
