import axios from "axios";
import {
    API_URL,
    AUTH_API_URL,
    CURRENT_ENV,
    REFERRAL_API_URL,
} from "./environments";
import {
    logout
} from "../store/actions/Users";
import {
    toast
} from "./helper";
import {
    store
} from "../store"


let baseUrl = API_URL[CURRENT_ENV];
let authBaseUrl = AUTH_API_URL[CURRENT_ENV];
let referralUrl = REFERRAL_API_URL[CURRENT_ENV];
// let baseUrl = "http://10.40.5.19:4040/api";
// let authBaseUrl = "http://10.40.5.19:4041/api";
let socketUrl = API_URL[CURRENT_ENV];
// const authBaseUrl = 'http://3.109.154.62:4041/api/';
// const baseUrl = 'http://3.109.154.62/api/';
// export const socketUrl = 'http://3.109.154.62:4042/';

// Vaagish IP
// const baseUrl = 'http://localhost:4040/api/';
// const authBaseUrl = 'http://localhost:4041/api/';


const interceptor = () => {
    axios.defaults.baseURL = baseUrl;

    // getTokenAndSetIntoHeaders();

    axios.interceptors.request.use(
        function (config) {
            const accessToken = localStorage.getItem(ACCESS_TOKEN);
            if (!accessToken) {
                store.dispatch(logout())
            }
            config.headers.authorization = accessToken
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (error?.response && error?.response && error?.response?.status === 401) {
                logout('success', 'Session expired')
            } else if (error?.response && error?.response && error?.response?.status !== 200) {
                let {
                    data
                } = error.response;
                let errorMessage = data && data?.message ? data?.message : '';
                toast('error', errorMessage);
            }
            return Promise.reject(error?.response);
        }
    )

};

//token key name into localStorage
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const PREV_ROUTE = 'PREV_ROUTE';

// get token into local storage and set into headers function
export const getTokenAndSetIntoHeaders = async (token) => {
    if (token) {
        axios.defaults.headers.common['authorization'] = `${token}`;
    } else {
        let accessToken = await localStorage.getItem(ACCESS_TOKEN);
        if (accessToken) {
            axios.defaults.headers.common['authorization'] = `${accessToken}`;
        }
    }
};

//General function to remove token from local storage
export const removeTokenFromLocalStorage = async (key) => {
    try {
        await localStorage.removeItem(key)
    } catch (error) {
        console.log(error)
    }

}

//set token into local storage
export const setTokenInToLocalStorage = async (value) => {
    try {
        await localStorage.setItem(ACCESS_TOKEN, value);
    } catch (error) {
        console.log(error)
    }
};
//get value into local storage
export const getValueInToLocalStorage = async (key) => {
    try {
        let value = await localStorage.getItem(key);
        return value
    } catch (error) {
      console.log(error)
    }
};
//set value into local storage
export const setValueInToLocalStorage = async (key, val) => {
    try {
        await localStorage.setItem(key, val);
    } catch (error) {
        console.log(error)
    }
};

//remove token from local storage
export const removeTokenInToLocalStorageAndDeleteAuthorization = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    delete axios.defaults.headers.common['authorization'];
};

export { baseUrl, authBaseUrl, referralUrl, interceptor,  }