import Dashboard from '../constants/Dashboard';

const initialState = {
    loading: false,
    announcements: [],
    announcementsMeta: {},
    notifications: [],
    notificationsMeta: {},
    usersInSystem: {},
    classData: {},
    finances: {},
    teacherStatus: {},
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case Dashboard.GET_DASHBOARD_DATA_API:
            return {
                ...state,
                loading: action.loading,
                announcements: action.announcements || [],
                announcementsMeta: action.announcementsMeta || {},
                notifications: action.notifications || [],
                notificationsMeta: action.notificationsMeta || {},
                classData: action.classData || {},
                finances: action.finances || {},
                teacherStatus: action.teacherStatus || {},
                usersInSystem: action.usersInSystem || {},
            };
        default:
            return state;
    }
};
