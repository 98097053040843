import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { post } from "../../utils/methods";
import { Form, Input, Button } from "antd";
import { addTopSubject } from "../../store/actions/TopSubject";
import { getAllSubjects } from "../../store/actions/Common";

function AddSubject({ onHide, data }) {
  const dispatch = useDispatch();
  const [subjectId, setSubjectId] = useState(null);
  const [form] = Form.useForm();

  const reduxState = useSelector(({ topSubjects, common  }) => {
    return {
      addSubjectLoading: topSubjects?.addSubject,
      allSubject: common?.subjects
    };
  });

  const {addSubjectLoading, allSubject}= reduxState;

  const callback = () => {
    onHide();
  };

  const onFinish = (values) => {
    let payload = {
      subjectId: values.subjectId,
      topSubject: true,
    };
    console.log(payload);
    dispatch(addTopSubject(payload, callback));
    onHide();
  };

  //const [myData, setMyData] = useState([]);

  // useEffect(() => {
  //   post(`subject/getAll`).then((res) => {
  //     console.log("response : ", res.data.data.entries);
  //     setMyData(res.data.data.entries);
  //   });
  // }, []);

  useEffect(()=> {
    dispatch(getAllSubjects())
  }, []);

  const handleChange = (e) => {
    setSubjectId(e.target.value);
  };

  const renderInputs = (data = "") => {
    return (
      <>
        <Form.Item name="subjectId" className="c-input">
          <select
            style={{ width: "100%", padding: "10px" }}
            value={subjectId}
          >
            <option>Add Top Subject</option>
            {allSubject &&
              allSubject.map((obj) => {
                {
                  return (
                    <option
                      style={{ textTransform: "uppercase" }}
                      value={obj._id}
                      onChange={handleChange}
                    >
                      {obj.name}
                    </option>
                  );
                }
              })}
          </select>
        </Form.Item>
      </>
    );
  };

  return (
    <Form
      name="add-subject"
      className="modal-form"
      initialValues={{
        subjectName: data && data?.name ? data?.name : "",
      }}
      onFinish={onFinish}
      form={form}
    >
      <h4 className="modal-form-title">Add Top Subject</h4>
      {renderInputs()}
      <Form.Item className="mb-0">
        <Button
          htmlType="submit"
          className="c-button button-with-loading"
          loading={addSubjectLoading}
          block
        >
          Add
        </Button>
      </Form.Item>
    </Form>
  );
}

export default AddSubject;
