import React from "react";
import {getCourse, MappedElement, renderUserName} from "../../utils/helper";
import {CImage, CTags, UserList} from "../../uiComponents";

function Children({ child, loading, onClick }) {

    const renderTags = (array) => {
        if (array && array.length) {
            return array.map((obj, key) => <CTags key={key} data={getCourse(obj)} />)
        }
        return null
    };

    const renderItem = (obj, index) => {
        return (
            <li key={index} onClick={() => onClick(obj._id)}>
                <CImage alt="" imageUrl={obj.imageUrl} />
                <div className="past-students-experience-info">
                    <h6 className={obj.courses && obj.courses.length ? '' : 'm-0'}>{renderUserName(obj)}</h6>
                    {obj.courses && obj.courses.length ? renderTags(obj.courses) : null}
                </div>
            </li>
        )
    };

    return (
        <div className="details-tab-container d-flex flex-column h-100">
            <UserList
                className="past-students-experience"
                data={child}
                loading={loading}>
                <MappedElement data={child} renderElement={renderItem}/>
            </UserList>
        </div>
    );
}
export default Children;
