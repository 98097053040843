import React, { Fragment, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Router, useHistory } from "react-router";

import Courses from '../pages/Courses/Courses';
import Teacher from '../pages/mentors/Teacher';
import Buttons from '../pages/buttons/Buttons';
import Page from '../pages/page/Page';
import {
  Dashboard,
  Categories,
  Tutors,
  Students,
  Parents,
  Refferal,
  MyFinances,
  Questions,
  Quizzes,
  CreateQuiz,
  Homeworks,
  Messages,
  MyMessages,
  Disputes,
  AdminLevelUs,
  Approvals,
  Login,
  TopMentor,
  TopSubjects,
  Transactions,
  RequestedTeachers,
} from "../pages";
import { SideMenu } from "../uiComponents";
import { useDispatch, useSelector } from "react-redux";
import { checkTokenIsValid } from "../store/actions/Users";
import { getValueInToLocalStorage, PREV_ROUTE } from "../utils/intercepter";

//google analytics
import ReactGA from "react-ga";
import { withRouter } from "react-router";
import TopScoreMentor from "../pages/topMentor/TopScoreMentor";
import PromotionCard from "../pages/propmotionCard/PromotionCard";
import TransactionTabs from "../pages/transactions/TransactionTabs";
const TRACKING_ID = "UA-256479831-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


function Auth() {
  const prev_route = localStorage.getItem("PREV_ROUTE");
  const access_token = localStorage.getItem("ACCESS_TOKEN");
  const history = useHistory();

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // });

  return (
    <Fragment>
      <Switch>
        <Route path="/login" component={Login} />
        {!access_token ? (
          <Redirect to={`/login`} />
        ) : prev_route && access_token ? (
          <Redirect to={`${history.location.pathname}`} />
        ) : (
          <Redirect to={`/dashboard`} />
        )}
        <Route
          path="*"
          render={() => (
            <div>
              <h1>Not Found</h1>
            </div>
          )}
        />
      </Switch>
    </Fragment>
  );
}
const routes = [
  {
    path: '/dashboard',
    exact: true,
    component: Dashboard,
  },
  {
    path: '/categories',
    exact: true,
    component: Categories,
  },
  {
    path: '/tutors',
    exact: true,
    component: Tutors,
  },
  {
    path: '/students',
    exact: true,
    component: Students,
  },
  {
    path: '/parents',
    exact: true,
    component: Parents,
  },
  {
    path: '/referral',
    component: Refferal,
  },
  {
    path: '/my-finances',
    component: MyFinances,
  },
  {
    path: '/transactions',
    component: TransactionTabs,
  },
  {
    path: '/questions',
    component: Questions,
  },
  {
    path: '/quizzes/create-quiz',
    component: CreateQuiz,
  },
  {
    path: '/quizzes',
    component: Quizzes,
  },
  {
    path: '/homeworks',
    component: Homeworks,
  },
  {
    path: '/messages',
    component: Messages,
  },
  {
    path: '/my-messages',
    component: MyMessages,
  },
  {
    path: '/disputes',
    component: Disputes,
  },
  {
    path: '/admin-level-us',
    component: AdminLevelUs,
  },
  {
    path: '/approvals',
    component: Approvals,
  },
  {
    path: '/top-mentors',
    component: TopMentor,
  },
  {
    path: '/top-mentors-score',
    component: TopScoreMentor,
  },
  {
    path: '/top-subjects',
    component: TopSubjects,
  },
  {
    path: '/promotion-card',
    component: PromotionCard,
  },
  {
    path: '/Courses',
    component: Courses,
  },
  {
    path: '/Teacher',
    component: Teacher,
  },
  {
    path: '/Buttons',
    component: Buttons,
  },
  {
    path: '/Page',
    component: Page,
  },
  {
    path: '/requested-teachers',
    component: RequestedTeachers,
  },
];

function Root(props) {
  const history = useHistory();
  const user = useSelector(({ users }) => users?.user);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      let prevRoute = await getValueInToLocalStorage(PREV_ROUTE);
      if (prevRoute) {
        history.push(prevRoute);
      }
    })();
    if (user && user.additionalData) {
      dispatch(checkTokenIsValid(user?.additionalData));
    }
  }, [dispatch, history, user]);

  return (
    <Fragment>
      <div className="body-with-side-menu d-flex flex-row h-100">
        <div className="side-menu-container">
          <SideMenu
            currentPathName={
              props && props?.location ? props?.location?.pathname : ""
            }
          />
        </div>
        <div className="body-container  scroll-y">
          <Router history={history}>
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  exact={route?.exact}
                  path={route?.path}
                  component={route?.component}
                />
              ))}
              <Redirect from="/" to={`/dashboard`} />
              <Route
                path="*"
                render={() => (
                  <div>
                    <h1>Not Found</h1>
                  </div>
                )}
              />
            </Switch>
          </Router>
        </div>
      </div>
    </Fragment>
  );
}

export { Auth, Root };
