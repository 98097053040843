import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Axios from "axios";
import "./Teacher.css";

import {
  baseUrl
} from "../../utils/intercepter";

function Teacher() {
  const dispatch = useDispatch();


  const [searchedTeacherData, setSearchedTeacherData] = useState([])

  const [clickedTeacherData, setClickedTeacherData] = useState([])

  useEffect(() => {
    // dispatch(getTeacherData())
    var mySearchArr = []
    var myClickArr = []


    // Axios.post("http://localhost:4040/api/logging/getTopTeacher")
    Axios.post(`${baseUrl}/logging/getTopTeacher`)
      .then((result) => {
        console.log('teacher data : ',result.data)
        
        // For Searched Teacher ------
          let searchDataArr = [result.data]
        let searchedRecord = searchDataArr[0].filter( i => i.subType.includes("SEARCHED_TEACHER") );
        for (let i = 0; i < searchedRecord.length; i++){
          let searchedArr = searchedRecord[i].searchedTeacher

          mySearchArr = [...mySearchArr, searchedArr]
        }
        const map = mySearchArr.reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());
setSearchedTeacherData([...map])
// --------- XXXXXXX ----------

          // For Clicked Teacher ------
          let clickedArrData = [result.data]
          console.log("check : ",clickedArrData[0])
          let clickedRecord = clickedArrData[0].filter( i => i.subType.includes("CLICKED_TEACHER") );
          console.log('check record :', clickedRecord)
          for (let i = 0; i < clickedRecord.length; i++){
            let clickedArr = clickedRecord[i].result[0]?.details?.firstName
            console.log('inside : ', clickedArr)
            myClickArr = [...myClickArr, clickedArr]
          }
          const clickedMap = myClickArr.reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());
  setClickedTeacherData([...clickedMap])
  console.log([...clickedMap.keys()])
  
    // --------- XXXXXXX ----------
      })
      .catch((err) => {
        console.log("err : ", err);
      });
  }, []);




  return (
    <>
      <div style={{ width: "100%" }}>
        {/* -------- For Searched Course ------ */}

        <div
          style={{ textAlign: "center", height: "max-content", width: "100%" }}
        >
          <h1>Searched Teacher</h1>
        </div>

          <div style={{display: 'flex',justifyContent: 'center', marginTop: '30px'}}>
        <table
        style={{width: '50%'}}

        >
          <tr style={{border: '1px solid gray', backgroundColor: '#6c757d90'}}>

            <th style={{border: '1px solid gray', width: '50%', paddingLeft: '2%', height: '50px',}}>Searched Teacher</th>
            <th style={{border: '1px solid gray', width: '50%', height: '50px', paddingLeft: '2%'}}>Count</th>
            
          </tr>
          {searchedTeacherData.length &&
            searchedTeacherData.map((obj) => {
                return (
                  <tr
                    style={{
                      border: '1px solid gray'
                    }}
                  >
                     <td style={{border: '1px solid gray', width: '50%', paddingLeft: '2%', background: '#ced4da', height: '50px', fontWeight: '600'}}>{obj[0]}</td>

                     <td style={{border: '1px solid gray',  width: '50%', paddingRight: '2%', height: '50px', textAlign: 'right'}}>{obj[1]}</td>
                  </tr>
                );
              
            })}
        </table>
        </div>

        {/* --------XXXXXXX------- */}



        {/* ------ For Clicked Course ------ */}

        <div
           style={{ textAlign: "center", height: "max-content", width: "100%", marginTop: '60px' }}
        >
          <h1>Clicked Teacher</h1>
        </div>

        <div style={{display: 'flex',justifyContent: 'center', marginTop: '30px'}}>
        <table
        style={{width: '50%'}}

        >
          <tr style={{border: '1px solid gray', backgroundColor: '#6c757d90'}}>

            <th style={{border: '1px solid gray', width: '50%', paddingLeft: '2%', height: '50px',}}>Clicked Teacher</th>
            <th style={{border: '1px solid gray', width: '50%', height: '50px', paddingLeft: '2%'}}>Count</th>
            
          </tr>
          {clickedTeacherData.length &&
            clickedTeacherData.map((obj) => {
              console.log('clicked obj', obj)
                return (
                  <tr
                    style={{
                      border: '1px solid gray'
                    }}
                  >
                     <td style={{border: '1px solid gray', width: '50%', paddingLeft: '2%', background: '#ced4da', height: '50px', fontWeight: '600'}}>{obj[0]}</td>

                     <td style={{border: '1px solid gray',  width: '50%', paddingRight: '2%', height: '50px', textAlign: 'right'}}>{obj[1]}</td>
                  </tr>
                );
              
            })}
        </table>
        </div>
     

        {/* --------XXXXXXX------- */}
      </div>
    </>
  );
}

export default Teacher;
