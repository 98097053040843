import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Auth, Root } from "./routing";
import { useDispatch, useSelector } from "react-redux";
import { checkAuth } from "./store/actions/Users";
import { Loading } from "./uiComponents";

import ReactGA from "react-ga";
const TRACKING_ID = "UA-256479831-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

//Props will give you the history, location and match params but only work if your component is wrapped in withRouter Component
function App(props) {
  const dispatch = useDispatch();

  // Getting User Data from Redux
  const reduxState = useSelector(({ users }) => {
    return {
      loading: users.loading,
      userLoggedIn: users.userLoggedIn,
      user: users.user,
    };
  });

  const { loading, userLoggedIn } = reduxState;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  // Second UseEffect After The Application Runs That checks the Authentication
  useEffect(() => {
    //dispatching To check the Authentication initially
    dispatch(checkAuth());
  }, [dispatch]);

  const renderRoutingWithRole = (value) => {
    switch (value) {
      case true:
        return <Root {...props} />;
      default:
        return <Auth />;
    }
  };

  return (
    <div className="app-root">
      {loading ? <Loading /> : renderRoutingWithRole(userLoggedIn)}
    </div>
  );
}

// Allowing to get history and location props using withRouter
export default withRouter(App);
