import React, { useEffect } from 'react';
import {
    renderUserFullName,
    renderUserDob,
    renderUserGender
} from "../../utils/helper";

function Details({ student }) {

    // useEffect(()=>{
    //     console.log('ch :', student)
    // }, [])
    return (
        <div className="details-tab-container">
            <ul className="user-info d-flex flex-column">
                <li>
                    <span>Name</span>
                    <span>{renderUserFullName(student)}</span>
                </li>
                <li>
                    <span>Date of birth</span>
                    <span>{renderUserDob(student)}</span>
                </li>
                <li>
                    <span>Email</span>
                    <span>{student?.profile?.email ? student?.profile?.email : '-'}</span>
                </li>
                <li>
                    <span>Gender</span>
                    <span>{renderUserGender(student)}</span>
                </li>
                <li>
                    <span>Phone</span>
                    <span>{student?.profile?.phone ? student?.profile?.countryCodeNum + " " + student?.profile?.phone : '-'}</span>
                </li>
                <li>
                    <span>Curriculum</span>
                    <span>{student?.profile?.curriculum?.name ? student?.profile?.curriculum?.name : '-'}</span>
                </li>
                <li>
                    <span>Grade</span>
                    <span>{student?.profile?.grade?.name ? student?.profile?.grade?.name : '-'}</span>
                </li>
                <li>
                    <span>IP</span>
                    <span>{student?.profile?.ip}</span>
                </li>
            </ul>
        </div>
    );
}
export default Details;
