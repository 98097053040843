import React from "react";

const CTags = ({data, className = ''}) => {
    if(data && data.length){
        return <div className={`c-tags d-flex flex-wrap ${className}`}>
            {data.map((obj, index) => {
                if(obj.text) {
                    return(
                        <span key={index} className="c-tag" style={{
                            backgroundColor: obj.backgroundColor,
                            color: obj.color
                        }}>
                        {obj.text}
                    </span>
                    )
                }
                return null;
            })}
        </div>
    }
    return null;
};
export default CTags;
