import AdminLevelUsers from "../constants/AdminLevelUsers";
import {post} from "../../utils/methods";
import {toast} from "../../utils/helper";

export const getAllAdminLevelUsers = () => (dispatch) => {
    dispatch({type: AdminLevelUsers.GET_ALL_ADMIN_LEVEL_USERS_API, loading: true, data: []});
    post('admin/getAll')
        .then(({data}) => {
            dispatch({
                type: AdminLevelUsers.GET_ALL_ADMIN_LEVEL_USERS_API,
                loading: false,
                data: (data.data && data.data.entries) ? data.data.entries : []
            });
        })
        .catch((error) => {
            dispatch({type: AdminLevelUsers.GET_ALL_ADMIN_LEVEL_USERS_API, loading: false, data: []});
        });
};

export const createAdmin = (payload, CB) => (dispatch) => {
    dispatch({type: AdminLevelUsers.CREATE_ADMIN_API, loading: true});
    post('admin/sendAccountCreationInvitation', payload)
        .then(({data}) => {
            dispatch({type: AdminLevelUsers.CREATE_ADMIN_API, loading: false});
            CB && CB();
            toast("success", "Successfully send your email");
        })
        .catch((error) => {
            dispatch({type: AdminLevelUsers.CREATE_ADMIN_API, loading: false});
        });
};

export const switchPrivilege = (payload) => (dispatch) => {
    dispatch({ type: AdminLevelUsers.SWITCH_PRIVILEGE, loading: true });
    post('admin/switchPrivilege', payload)
        .then(({ data }) => {
            dispatch({ type: AdminLevelUsers.SWITCH_PRIVILEGE, loading: false });
            toast("success", "Admin privilege successfully changed");
            dispatch(getAllAdminLevelUsers())
        })
        .catch((error) => {
            dispatch({ type: AdminLevelUsers.SWITCH_PRIVILEGE, loading: false });
        });
};
