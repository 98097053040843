import Dashboard from '../constants/Dashboard';
import { post } from "../../utils/methods";

const getDataWithKey = (data, key) => {
    return {
        data: data && data[key] && data[key].entries ? data[key].entries : [],
        metaData: {}
    }
};

export const getDashboardData = (CB) => (dispatch) => {
    dispatch({type: Dashboard.GET_DASHBOARD_DATA_API, loading: true});
    post('admin/getDashboard', {page: 1, limit: 5})
        .then(({data}) => {
            console.log(data,"data")
            let announcements = getDataWithKey(data.data, 'announcements');
            let notifications = getDataWithKey(data.data, 'notifications');
            dispatch({
                type: Dashboard.GET_DASHBOARD_DATA_API,
                loading: false,
                announcements: announcements?.data,
                announcementsMeta: announcements?.metaData,
                notifications: notifications?.data,
                notificationsMeta: notifications?.metaData,
                classData: data?.data?.classData ? data?.data?.classData : null,
                finances: data?.data?.finances ? data?.data?.finances : null,
                teacherStatus: data?.data?.teacherStatus ? data?.data?.teacherStatus : null,
                usersInSystem: data?.data?.usersInSystem ? data?.data?.usersInSystem : null,
            });
            CB && CB()
        })
        .catch((error) => {
            dispatch({type: Dashboard.GET_DASHBOARD_DATA_API, loading: false});
        });
};
