import Tutors from "../constants/Tutors";
import { post } from "../../utils/methods";
import {toast} from "../../utils/helper";

export const getAllTutors = (payload , CB) => (dispatch) => {
    dispatch({ type: Tutors.GET_ALL_TUTORS_API, loading: true, data: [] });
    post('teacher/getAll',payload)
        .then(({ data }) => {
            let teachers = (data.data && data.data.entries && data.data.entries.length) ? data.data.entries : []
            console.log('mera test :', teachers)
            dispatch({
                type: Tutors.GET_ALL_TUTORS_API,
                loading: false,
                data: (data.data && data.data.entries && data.data.entries.length) ? data.data.entries : [],
                metaData: data?.data?.metaData
            });
            CB && CB(teachers)
        })
        .catch((error) => {
            dispatch({ type: Tutors.GET_ALL_TUTORS_API, loading: false, data: [] });
        });
};

export const getMyStudents = (payload) => (dispatch) => {
    dispatch({ type: Tutors.GET_MY_STUDENTS_API, loading: true, data: [] });
    post('subscription/getTeacherStudents', payload)
        .then(({ data }) => {
            dispatch({
                type: Tutors.GET_MY_STUDENTS_API,
                loading: false,
                data: (data.data && data.data.entries && data.data.entries.length) ? data.data.entries : [],
            });
        })
        .catch((error) => {
            dispatch({ type: Tutors.GET_MY_STUDENTS_API, loading: false, data: [] });
        });
};

export const getTutorPromos = (payload) => (dispatch) => {
    dispatch({ type: Tutors.GET_TUTOR_PROMOS_API, loading: true, data: [] });
    post('approval/getAll', payload)
        .then(({ data }) => {
            dispatch({
                type: Tutors.GET_TUTOR_PROMOS_API,
                loading: false,
                data: (data.data && data.data.entries && data.data.entries.length) ? data.data.entries : [],
            });
        })
        .catch((error) => {
            dispatch({ type: Tutors.GET_TUTOR_PROMOS_API, loading: false, data: [] });
        });
}

export const promoTakeAction = (payload, CB) => (dispatch) => {
    dispatch({ type: Tutors.PROMO_TAKE_ACTION_API, loading: true });
    post('approval/takeAction', payload)
        .then(({ data }) => {
            toast('success', data.successCode)
            dispatch({ type: Tutors.PROMO_TAKE_ACTION_API, loading: false });
            CB && CB();
        })
        .catch((error) => {
            dispatch({ type: Tutors.PROMO_TAKE_ACTION_API, loading: false });
        });
};
