import React, { useEffect } from "react";
import { CTags } from "../index";
import { getTags } from "../../utils/helper";
import CImage from "../CImage/CImage";

function UserListItem(props) {
  const {
    id,
    selectedId,
    name,
    titleStyle = "",
    courses = [],
    imageUrl = null,
    type,
    onClick,
    scoreByAdmin,
    kyc,
    newTotal
  } = props;
  // const renderTags = (array) => {
  //     if (array && array.length) {
  //         return array.map((obj, key) => {
  //             if (obj) {
  //                 return <CTags
  //                     key={key}
  //                     data={getTags(obj, 'subject', 'grade', 'curriculum')} />
  //             }
  //             else return null
  //         })
  //     }
  //     return null
  // }


  const kycColor = (color) => {
    return (
      <h5 style={{ color: color, fontSize: '60px', height: '40px', display: 'flex', alignItems: 'center' }}>&#8226;</h5>
    );
  };
  const renderKyc = () => {
    switch (kyc) {
      case 'INITIAL_UNVERIFIED':
        return kycColor('red');

      case 'INITIAL_VERIFICATION_APPROVED':
        return kycColor('orange');

      case 'ON_BOARD_REQUEST_APPROVED':
        return kycColor('green');

      case 'ON_BOARD_REQUEST':
        return kycColor('yellow');
      default:
        return kycColor('white');
    }
  };
  return (
    <li
      className={`d-flex flex-row align-items-center c-type-list-item 
                        ${selectedId && (id === selectedId ? 'active' : '')}`}
      onClick={onClick}
      // style={{border: '2px solid'}}
    >
      {renderKyc()}

      {!type && <CImage imageUrl={imageUrl} />}
      <div className='flex-1 c-type-list-item-info' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h6 className={titleStyle} style={{ display: 'flex', alignItems: 'center' }}>
          {' '}
          {name ? name : ''}{' '}
        </h6>
        {/* INITIAL_VERIFICATION_APPROVED */}

        <span style={{ display: 'flex', alignItems: 'center' }}>{scoreByAdmin}</span>
        {/* {courses && courses.length ? renderTags(courses) : null} */}
      </div>
    </li>
  );
}

export default UserListItem;
