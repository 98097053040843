import React, { Fragment, useEffect, useState } from "react";
import { useDispatch,useSelector } from 'react-redux'
import {
  CNav,
  UserList,
  UserListItem,
  CImage,
  CModal
} from "../../uiComponents";
import { Button, Pagination, Tabs } from "antd";

import { FiX, FiSearch } from "react-icons/fi";
import Details from "./Details";

import ParentDetails from "./ParentDetails";
import { getAllStudents, getStudentSubjects } from "../../store/actions/Students";
import {
  dataEmpty,
  MappedElement,
  renderUserImage,
  renderUserName,
} from "../../utils/helper";
import Subjects from "./Subjects";
import CSearchBar from "../../uiComponents/cSearchBar/CSearchBar";
import ActivateAcc from "./ActivateAcc";
import Schedule from "./Schedule";
import { post } from "../../utils/methods";


const { TabPane } = Tabs;



function Students({location,history}) {

  const reduxState = useSelector(({ students,getStudentSubjects  }) => {
  return {
    getAllStudentsLoading: students?.getAllStudentsLoading,
    students: students?.students,
    myTutorsCount: students?.studentTutors?.length,
    getStudentSubjects: getStudentSubjects,
    metaData: students.metaData,

  }});

const {getAllStudentsLoading,getStudentSubject,students,myTutorsCount} = reduxState;

  const [selectedStudent, updateSelectedStudent] = useState(null);
  const [showSearch, setShowSearch] = useState(null);
  const [data, setData] = useState("");
  const [subjects,setSubjects]= useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [limit, setLimit] = useState(10)
  const [pages, setPages] = useState(1);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(20);

  const { state } = location;

  const dispatch = useDispatch();

  const selectStudent = (data = null) => {
    updateSelectedStudent(data);
  };
  useEffect(() => {
    get();
  }, [pages]);


  const [scheduleData, setScheduleData] = useState([])

  useEffect(()=>{
    post('schedule/getOneStudentSchedule', {_id: selectedStudent?._id})
    .then((res)=>{
      setScheduleData(res.data)
        console.log('my',res.data)
    })
    // console.log('sel',selectedTutor)
  },[selectedStudent])


  const getAllStudentsCallBack = (payload) => {
    if (state && state.id) {
      let findParent = payload.find((obj) => obj._id === state.id);
      updateSelectedStudent(findParent);
    } else if (payload && payload.length) {
      updateSelectedStudent(payload[0]);
    }
  };


  const getAllSubjectCallBack = (payload) =>{
    setSubjects(payload);
  }


  const navigateTo = (pathname, id) => {
    history.push({ pathname, state: { id } });
  };

  const renderStudent = (obj, index) => {
    // console.log('obj ', obj)
    return (
      <UserListItem
        key={index}
        titleStyle="m-0"
        id={obj._id ? obj._id : ""}
        selectedId={selectedStudent ? selectedStudent?._id : null}
        imageUrl={renderUserImage(obj?.profile?.imageUrl)}
        name={renderUserName(obj)}
        courses={obj?.courses ? obj?.courses : []}
        onClick={() => selectStudent(obj)}
      />
    );
  };

  const get = (text = "") => {
    dispatch( getAllStudents({
      ...(text && { searchQueryText: text,  }), limit: limit, page: pages,sortByAdd:true
    }, getAllStudentsCallBack))
  }

  const searchStudent = (text) => {
    setData(text);
    get(text);
  };

  const handleChange = (pages) => {
    setPages(pages)
    setMinIndex(pages - 1 * limit)
    setMaxIndex(pages * limit)
  }

  const getSubjects = ()=>{
    dispatch(getStudentSubjects({ student: selectedStudent?._id && selectedStudent?._id },getAllSubjectCallBack))
  }

  useEffect(() => {
    getSubjects()
  }, [selectedStudent?._id])

  const toggleSearch = () => { showSearch && data?.length && searchStudent(); setShowSearch(!showSearch) };

  return (
    <div className="my-students w-100 d-flex flex-column">
      <CNav />
      <div className="two-card-view d-flex stop mb-4">
        <div className="first-card-view">
          <div className="first-card-view-header d-flex">
            <h1 className="flex-1" style={{ fontFamily: "Poppins", color: "#21047c" }}>{showSearch ? "" : "Students"}</h1>
            <button onClick={() => toggleSearch()}>
              {showSearch ? <FiX /> : <FiSearch />}
            </button>

            <CSearchBar
              resetSearch={showSearch}
              isShow={showSearch}
              onTextChange={(e) => e && searchStudent(e)}
            />
          </div>
          <div className="first-card-view-body with-out-filter scroll-y">
            <UserList data={students} loading={getAllStudentsLoading}>
              <MappedElement data={students} renderElement={renderStudent} role={'student'}/>
            </UserList>
            
            <Pagination
              pageSize={limit}
              current={pages}
              total={reduxState?.metaData?.totalDocuments}
              onChange={handleChange}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}
            />
          </div>
        </div>
        <div className="second-card-view d-flex flex-column">
          {selectedStudent && Object.keys(selectedStudent).length ? (
            <Fragment>
              <div className="second-card-view-header d-flex align-items-center">
                <CImage
                  imageUrl={
                    selectedStudent ? selectedStudent?.profile?.imageUrl : null
                  }
                  alt=""
                />
                <h1 className="flex-1">{renderUserName(selectedStudent)}</h1>
              </div>
              <Tabs
                className="h-100 d-flex flex-column c-tabs"
                defaultActiveKey="details"
              >
                <TabPane tab="Details" key="details">
                  <div className="content-container scroll-y">
                    <Details student={selectedStudent} />
                  </div>
                </TabPane>

                <TabPane tab="Subjects" key="subjects">
                  <div className="content-container scroll-y">
                    <Subjects
                      history={history}
                      onSubjectClick={(route, id) =>
                        navigateTo("/my-subjects", id)
                      }
                      data={
                        subjects ? subjects : []
                      }
                    />
                  </div>
                </TabPane>



                <TabPane tab="Parent Details" key="parentDetails">
                  <div className="content-container scroll-y">
                    <ParentDetails
                      father={selectedStudent ? selectedStudent : null}
                    />
                  </div>
                </TabPane>

                <TabPane tab="Schedule" key="schedule">
                  <div className="content-container scroll-y">
                    <Schedule scheduleData={scheduleData}/>
                  </div>
                </TabPane>

              </Tabs>
            </Fragment>
          ) : (
            dataEmpty()
          )}
        </div>
        <button className="c-fab-button-new" onClick={() => setModalOpen(true)}>
            Activate a student account
        </button>
      {modalOpen && (
        <CModal
          show={modalOpen}
          backdrop="static"
          keyboard={false}
          className="center small"
          onHide={() => setModalOpen(false)}
        >
          <ActivateAcc onHide={() => setModalOpen(false)} />
        </CModal>
      )}
      </div>
    </div>
  );
}
const mapStateToProps = ({ students,getStudentSubjects }) => {

  return {
    getAllStudentsLoading: students?.getAllStudentsLoading,
    students: students?.students,
    myTutorsCount: students?.studentTutors?.length,
    getStudentSubjects: getStudentSubjects
  
  };
};

export default Students;
