import MyAccount from '../constants/MyAccount';
import { post } from "../../utils/methods";
import { toast } from "../../utils/helper";

export const activateAccount = (payload, CB) => (dispatch) => {
    dispatch({ type: MyAccount.ACTIVATE_ACCOUNT, loading: true });
    post(`student/activateAccount`, payload)
    .then(({ data }) => {
      dispatch({ type: MyAccount.ACTIVATE_ACCOUNT, loading: false });
      CB && CB();
      toast("success", `Successfully Activated the account`);
    })
    .catch((error) => {
      dispatch({ type: MyAccount.ACTIVATE_ACCOUNT, loading: false });
    });
  };