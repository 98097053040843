import React, { useEffect, useState } from "react";
import { CNav, CModal, Loading, CImage } from "../../uiComponents";
import { Button, Form, Input, Radio } from "antd";
import { dataEmpty, MappedElement, renderUserName } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { createAdmin, getAllAdminLevelUsers, switchPrivilege } from "../../store/actions/AdminLevelUsers";
import moment from 'moment';

const AdminModal = ({ onHide }) => {

    const dispatch = useDispatch();

    const loading = useSelector(({ adminLevelUsers }) => adminLevelUsers?.createAdminLoading);

    const callback = () => {
        onHide()
    }

    const onFinish = (values) => {
        let payload = {
            ...values,
            isSuperAdmin: (values?.isSuperAdmin === 'true')
        };
        dispatch(createAdmin(payload, callback))
    };

    return (
        <Form
            name="refund-form"
            className="modal-form"
            initialValues={{}}
            onFinish={onFinish}
        >
            <h4 className="modal-form-title">Add Admin</h4>

            <Form.Item name="email" className="c-input">
                <Input placeholder="Email" />
            </Form.Item>

            <div className="form-item-title">Select Type Of Admin</div>
            <Form.Item name="isSuperAdmin" className="c-select-group">
                <Radio.Group>
                    <Radio.Button value="false" buttonStyle="solid">
                        Admin
                    </Radio.Button>
                    <Radio.Button value="true" buttonStyle="solid">
                        Super Admin
                    </Radio.Button>
                </Radio.Group>
            </Form.Item>

            <Form.Item className="mb-0">
                <Button
                    htmlType="submit"
                    className="c-button button-with-loading"
                    loading={loading}
                    block
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

function AdminLevelUs() {
    const dispatch = useDispatch();
    const reduxState = useSelector(({ adminLevelUsers, users }) => {
        return {
            loading: adminLevelUsers?.getAllAdminLevelUsersLoading,
            adminLevelUsers: adminLevelUsers?.adminLevelUsers,
            switchPrivilegeLoading: adminLevelUsers?.switchPrivilegeLoading,
            isSuperAdmin: (users?.user && users?.user?.isSuperAdmin) ? users?.user?.isSuperAdmin : false
        };
    });

    const { loading, adminLevelUsers, switchPrivilegeLoading, isSuperAdmin } = reduxState;
    const [adminModal, setAdminModal] = useState(false);


    const get = () => {
        dispatch(getAllAdminLevelUsers())
    }

    const switctAdminPrivilege = (obj) => {
        dispatch(switchPrivilege({ adminId: obj?._id }))
    }
    
    useEffect(() => {
        get();
    }, []);

    const renderUsers = (obj, index) => {
        return (
            <div className="list-item" key={index} >
                <CImage className="image" imageUrl={obj?.profile?.imageUrl} alt="" />
                <div className="flex-05 d-flex align-items-center">
                    <h6 className="text">{renderUserName(obj)}</h6>
                </div>
                <div className="flex-06">
                    <div className="c-tag rounded m-0">{obj?.isSuperAdmin ? 'Super Admin' : 'Admin'}</div>
                </div>
                <div className="d-flex flex-1">
                    <div className="secondary-text">Creation date :</div>
                    <div className="secondary-text">
                        {obj?.createdAt ? moment(obj?.createdAt).format("Do MMM YYYY") : '-'}
                    </div>
                </div>
                {isSuperAdmin ? <div className="button-groups d-flex flex-row mr-0">
                    <Button className="c-button m-0"
                        onClick={() => switctAdminPrivilege(obj)}
                    >
                        Make  {(obj?.isSuperAdmin) ? '' : 'super'} admin
                    </Button>
                </div> : null}
            </div>
        );
    };

    return (
        <div className="admin-level-us w-100 d-flex flex-column">
            <CNav notificationVisible={true}>
                {isSuperAdmin ? <Button className="c-button"
                 onClick={() => setAdminModal(true)}
                >
                    + Add Admin
                </Button> : null}
            </CNav>
            <h3 className="page-title m-0 mb-4" > Admin Level Users </h3>
            {loading ? (
                <div className="w-100 h-100">
                    <Loading position="static" className="page-loading" />
                </div>
            ) : (adminLevelUsers && adminLevelUsers.length) ? <ul className="list mb-4">
                <MappedElement data={adminLevelUsers} renderElement={renderUsers} />
            </ul> : dataEmpty()}
            
            <CModal
                show={adminModal}
                className="center small"
                onHide={() => setAdminModal(false)}
                backdrop="static"
                keyboard={false}>
                <AdminModal onHide={() => setAdminModal(false)} />
            </CModal>
        </div>
    );
}

export default AdminLevelUs;
