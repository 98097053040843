export const CURRENT_ENV = 'PRODUCTION';

export const API_URL = {
  // DEV: 'https://dev.gethikma.com/main',
  DEV: 'http://192.168.1.2:4040/api',
  // PRODUCTION: 'http://localhost:4040/api',
  TEST: 'http://3.109.154.62/api/',
  // UAT: "https://dev.gethikma.com/main",
  PRODUCTION: "https://api.gethikma.com/main",
};

export const EVENT = {
  // DEV: "https://dev.gethikma.com",
  TEST: 'http://3.109.154.62:4042',
  DEV: 'http://3.109.154.62:4042',
  // UAT: "https://dev.gethikma.com",
  PRODUCTION: "https://api.gethikma.com",
  // DEV: "http://localhost:3000",
};

export const AUTH_API_URL = {
  // DEV: 'https://dev.gethikma.com/web',
  DEV: 'http://192.168.29.114:4041/api',
  TEST: 'http://3.109.154.62:4041/api',
  // UAT: "https://dev.gethikma.com/web",
  PRODUCTION: "https://api.gethikma.com/web",
};

export const REFERRAL_API_URL = {
  // DEV: 'https://dev.gethikma.com/web',
  DEV: 'https://referral.gethikma.com/referral',
  TEST: 'https://referral.gethikma.com/referral',
  // UAT: "https://dev.gethikma.com/web",
  PRODUCTION: "https://referral.gethikma.com/referral",
};

// export const authBaseUrl = 'http://3.109.154.62:4041/api/';
// export const baseUrl = 'http://3.109.154.62/api/';
// export const socketUrl = 'http://3.109.154.62:4042/';
