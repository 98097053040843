import React, { useState } from "react";
import UserList from "../disputes/UserList";
import { CNav, CModal } from "../../uiComponents";
import { messageList, messages } from "../data";
import { MappedElement } from "../../utils/helper";

import moment from "moment";
import { Image } from "react-bootstrap";
import { Dropdown, Menu, Input, Checkbox, Form, Button, Radio } from "antd";

import { FiSearch } from "react-icons/fi";
import { AiOutlineEllipsis, AiOutlineSend } from "react-icons/ai";
import { RiEdit2Line } from "react-icons/ri";
import { MdAttachFile } from "react-icons/md";

const menu = (
  <Menu>
    <Menu.Item key="0"> Option 1 </Menu.Item>
    <Menu.Item key="1"> Option 2 </Menu.Item>
    <Menu.Item key="2"> Option 3 </Menu.Item>
  </Menu>
);

function MyMessages() {
  const [createMessage, setCreateMessage] = useState(false);
  const renderElement = (message, index) => {
    return (
      <li className="chat-content" key={index}>
        <img src={message?.userImage} alt={message?.name} />
        <div className="flex-1">
          <span>
            {message?.name}, {moment(message?.date).format("LT")}
          </span>
          <p>{message?.message}</p>
        </div>
      </li>
    );
  };

  const CreateMessage = () => {
    const onFinish = (values) => {
      console.log("values", values);
    };

    const renderUsers = (users) => {
      return (
        <Checkbox.Group style={{ width: "100%" }} className="user-check-list">
          <MappedElement
            data={users}
            renderElement={(value, index) => {
              return (
                <Checkbox value={index} className="user-check-list-item">
                  <img
                    className="user-check-list-item-image"
                    src={require("../../assets/images/user.jpg")}
                    draggable="false"
                    alt=""
                  />
                  <h1 className="user-check-list-item-title">
                    Harold H.{index + 1}
                  </h1>
                </Checkbox>
              );
            }}
          />
        </Checkbox.Group>
      );
    };

    return (
      <Form
        name="create"
        className="modal-form"
        initialValues={{}}
        onFinish={onFinish}
      >
        <h4 className="modal-form-title">Start a message</h4>
        <Form.Item name="select" className="c-select-group">
          <div className="form-item-title">Would You Like To Add A Tutor?</div>
          <Radio.Group>
            <Radio.Button value="student" buttonStyle="solid">
              No
            </Radio.Button>
            <Radio.Button value="teacher" buttonStyle="solid">
              Yes
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <div className="form-item-title">My Tutors</div>
        <Form.Item name="search" className="c-input c-input-with-icon">
          <FiSearch />
          <Input placeholder="Search" />
        </Form.Item>

        {renderUsers(["", "", "", "", ""])}

        <Form.Item className="mt-3 mb-0">
          <Button
            htmlType="submit"
            className="c-button c-button-primary button-with-loading"
            block
          >
            Start
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div className="my-messages w-100 d-flex flex-column ">
      <CNav />
        <div className="two-card-view d-flex">
          <div className="first-card-view">
            <div className="first-card-view-header d-flex">
              <h1 className="flex-1">My Messages</h1>
              <button className="mr-2">
                <FiSearch />
              </button>
              <button
                className="c-button-icon"
                onClick={() => setCreateMessage(true)}
              >
                <RiEdit2Line />
              </button>
            </div>
  
            <div className="first-card-view-body with-out-filter scroll-y">
              <UserList data={messageList} />
            </div>
          </div>
          <div className="second-card-view d-flex flex-column">
            <div className="second-card-view-header without-tabs d-flex align-items-center">
              <Image src={require("../../assets/images/user-2.jpg")} />
              <div className="d-flex flex-column flex-1">
                <div className="d-flex align-items-center">
                  <b className="text">{"Bernar D"}</b>•
                  <span className="secondary-text ml-2">{"Admin"}</span>
                </div>
                <div className="secondary-text">2h</div>
              </div>
              <FiSearch className="ant-dropdown-link secondary-text" />
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                overlayClassName="c-dropdown"
              >
                <a
                  href={false}
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <AiOutlineEllipsis />
                </a>
              </Dropdown>
            </div>
            <div className="chat-tab-container">
              <div className="chat-container scroll-y">
                <ul className="chat">
                  <MappedElement data={messages} renderElement={renderElement} />
                </ul>
              </div>
              <div className="chat-input-container c-input">
                <button className="chat-input-button right">
                  <MdAttachFile />
                </button>
                <Input placeholder="Type a message…" />
                <button className="chat-input-button">
                  <AiOutlineSend />
                </button>
              </div>
            </div>
          </div>
        </div>
      <CModal
        show={createMessage}
        className="center small"
        onHide={() => setCreateMessage(false)}
      >
        <CreateMessage />
      </CModal>
    </div>
  );
}
export default MyMessages;
