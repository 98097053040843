import React from "react";

import { Menu, Dropdown } from "antd";
import { MappedElement } from "../../utils/helper";
import { teachersList } from "../data";
import { AiOutlineEllipsis } from "react-icons/ai";
import { CImage } from "../../uiComponents";



const renderUsersItem = (value, index) => {
  return (
      <li className="list-item p-0 mb-1" key={index}>
        <div className="header d-flex border-bottom-0">
          <CImage className="image" imageUrl={value.imageUrl} alt="" />
          <div className="flex-1 d-flex align-items-center">
            <h6 className="text m-0 mr-2">
              <b className="m-0">{value.name}</b>
            </h6>
            •<div className="secondary-text ml-2">{value.status}</div>
          </div>
          <div className="flex-1">
            <div className="c-tag rounded mb-0">{value.hired}</div>
          </div>

          <div className="secondary-text flex-1">{value.length}</div>
          <div className="secondary-text flex-1">{value.date}</div>
        </div>
      </li>
  );
};

function IntroductoryMeeting() {
  return (
    <div className="introductory-meeting invoicing-status-tab-container">
      <div className="invoicing-hours-section d-flex">
        <div className="invoicing-hours-inner-section left">
          <h2>12hr</h2>
          <span>Bought in bulk by hourly</span>
        </div>
        <div className="invoicing-hours-inner-section right">
          <h2>3hr</h2>
          <span>Left over right now</span>
        </div>
      </div>
      <div className="c-section">
        <div className="c-section-multiple-titles d-flex">
          <h6 className="c-section-title flex-1">Student/Parent</h6>
          <h6 className="c-section-title flex-08">Hired</h6>
          <h6 className="c-section-title flex-08">Length</h6>
          <h6 className="c-section-title flex-1">Date</h6>
        </div>
        <ul className="list p-0">
          <MappedElement data={teachersList} renderElement={renderUsersItem} />
        </ul>
      </div>
    </div>
  );
}

export default IntroductoryMeeting;
