import Announcements from '../constants/Announcements';

const initialState = {
    createAnnouncementLoading: false,
    getAnnouncementsLoading: true,
    students: [],
    studentsMeta: {},
    teachers: [],
    teachersMeta: {},
    parents: [],
    parentsMeta: {},
    all: [],
    allMeta: {},
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Announcements.CREATE_ANNOUNCEMENT_API:
            return { ...state, createAnnouncementLoading: action.loading };
       case Announcements.GET_ALL_ANNOUNCEMENTS_API:
            return {
                ...state,
                getAnnouncementsLoading: action.loading,
                students: action.students || [],
                studentsMeta: {},
                teachers: action.teachers || [],
                teachersMeta: {},
                parents: action.parents || [],
                parentsMeta: {},
                all: action.all || [],
                allMeta: {},
            };
        default:
            return state;
    }
};
