import common from './Common';
import users from './Users';
import dashboard from './Dashboard';
import notifications from './Notifications';
import categories from './Categories';
import approvals from './Approvals';
import announcements from './Announcements';
import tutors from './Tutors';
import students from './Students';
import parents from './Parents';
import adminLevelUsers from './AdminLevelUsers';
import finances from "./Finances";
import topMentors from './TopMentor'
import topSubjects from './TopSubject';
import myAccount from './MyAccount'
import referral from './Referral';

export default {
    common,
    users,
    dashboard,
    notifications,
    categories,
    approvals,
    announcements,
    tutors,
    students,
    parents,
    adminLevelUsers,
    finances,
    topMentors,
    topSubjects,
    myAccount,
    referral,
}
