
import React from "react";
import { useEffect, useState } from "react";
import { CImage, Loading } from "../../uiComponents";
import { useDispatch, useSelector } from 'react-redux'
import ReactPlayer from "react-player";
import moment from "moment";
import {
    dataEmpty,
    MappedElement,
    renderUserName,
    renderUserFullName,
    renderFullName,
    renderUserNickName,
    renderUserDob,
    renderPhone,
    renderUserGender,
    renderEmail
} from "../../utils/helper";
import {
    getAllTeachers
} from "../../store/actions/Approvals";

function ApprovalModal({ data, onAccept, onDecline, loading }) {

    console.log("datashata", data)
    const [teacherdata, setTeacherData] = useState("");

    // let userDetail = data.teacher ? data.teacher : {};
    // let credetials = userDetail.pendingChanges && userDetail.pendingChanges.credentials ? userDetail.pendingChanges.credentials : [];
    // let school = credetials.school ? credetials.school : [];
    // let university = credetials.university ? credetials.university : [];
    // let workExperience = credetials.workExperience ? credetials.workExperience : [];
    const dispatch = useDispatch();
    const reduxState = useSelector(({ approvals }) => {
        return {
            actionLoading: approvals.actionLoading,
        };
    });

    const { actionLoading } = reduxState;
    console.log("teacherdata", teacherdata)

    useEffect(() => {
        dispatch(getAllTeachers({ _id: data && data?.teacher && data?.teacher?.profile && data?.teacher?.profile?._id }, dataCallback))
    }, [])

    const dataCallback = (payload) => {
        setTeacherData(payload);
    }

    const renderView = (array, type) => {
        if (array && array?.length) {
            return <MappedElement data={array} renderElement={(obj, index) => dynamicView(type, obj, index)} />
        }
        return dataEmpty();
    };
    const dynamicView = (type, obj, i) => {
        switch (type) {
            case 'qualification':
                return (
                    <ul key={i} className="m-0 p-0 mb-3">
                        <li>
                            <span>Name</span>
                            <span>{obj?.name ? obj?.name : "-"}</span>
                        </li>
                        <li>
                            <span>Title</span>
                            <span>{obj?.title ? obj?.title : "-"}</span>
                        </li>
                        <li>
                            <span>To</span>
                            <span>{obj?.to ? moment(obj?.to && obj?.to).format("DD-MMMM-YYYY") : 'Present'}</span>
                        </li>
                        <li>
                            <span>From</span>
                            <span>{obj?.from ? moment(obj?.from && obj?.from).format("DD-MMMM-YYYY") : '-'}</span>
                        </li>
                    </ul>
                );
            case 'workExperience':
                return (
                    <ul key={i} className="m-0 p-0 mb-3">
                        <li>
                            <span>Institute</span>
                            <span>{obj.name ? obj.name : '-'}</span>
                        </li>
                        <li>
                            <span>From</span>
                            <span>{obj?.from ? moment(obj?.from && obj?.from).format("DD-MMMM-YYYY") : '-'}</span>
                        </li>
                        <li>
                            <span>To</span>
                            <span>{obj?.to ? moment(obj?.to && obj?.to).format("DD-MMMM-YYYY") : 'Present'}</span>
                        </li>

                    </ul>
                );
            default:
                return null
        }
    };
    return (
        <div className="user-detail-view scroll-y">
            <div className="credential">
                <h4>User Profile</h4>
            </div>
            {actionLoading ? (
                <div className="w-100 h-100">
                    <Loading position="static" className="page-loading" />
                </div>
            ) : <div>
                <div className="user-image-with-name">
                    <CImage imageUrl={teacherdata?.profile?.imageUrl ? teacherdata?.profile?.imageUrl : ""} alt="" />
                    <h4>{teacherdata?.profile?.userName}</h4>
                </div>
                <ul>
                    <li>
                        <span>Name</span> <span>{renderFullName(teacherdata && teacherdata) ? renderUserFullName(teacherdata && teacherdata) : '-'}</span>
                    </li>
                    <li>
                        <span>Email</span> <span>{renderEmail(teacherdata && teacherdata)}</span>
                    </li>
                    <li>
                        <span>Gender</span> <span>{renderUserGender(teacherdata && teacherdata)}</span>
                    </li>

                    <li>
                        <span>Phone</span> <span>{renderPhone(teacherdata && teacherdata)}</span>
                    </li>
                    <div className="credential">
                        <h4>Qualification</h4>
                        {renderView(teacherdata?.qualification, 'qualification')}
                    </div>
                    <div className="credential">
                        <h4>Work experience</h4>
                        {renderView(teacherdata?.credentials?.workExperience && teacherdata?.credentials?.workExperience, 'workExperience')}
                    </div>
                </ul>
                <div className="credential">
                    <h4>Personal Video</h4>
                    {teacherdata?.introductory?.personalVideoUrl ? <ReactPlayer
                        className="video-player sm"
                        controls={true}
                        url={teacherdata?.introductory?.personalVideoUrl}
                    /> : dataEmpty()}
                </div>
                {data && data.topic && data?.topic === "TEACHER_PROFILE_UPDATE" ? <div>
                    <div className="credential"><h4>Request For Update</h4></div>
                    {data?.teacher?.pendingChanges?.personalStatement ? <div className="credential" style={{ fontWeight: 500 }}><span style={{ fontWeight: 500 }}>Personal Statement: &nbsp;</span>{data?.teacher?.pendingChanges?.personalStatement ? data?.teacher?.pendingChanges?.personalStatement : ""} <hr /> </div> : ""

                    }

                    {data?.teacher?.pendingChanges?.personalVideoUrl ? <div className="credential" style={{ fontWeight: 500 }}> <span style={{ fontWeight: 500 }}>Personal Video:</span> {data?.teacher?.pendingChanges?.personalVideoUrl ? <ReactPlayer
                        className="video-player sm"
                        controls={true}
                        url={data?.teacher?.pendingChanges?.personalVideoUrl} /> : ""} <hr /></div> : ""}
                    {data?.teacher?.pendingChanges?.details?.firstName ? <div className="credential" style={{ fontWeight: 500 }}><span style={{ fontWeight: 500, color: '#b0b1b6' }}>First Name: &nbsp;</span>{data?.teacher?.pendingChanges?.details ? data?.teacher?.pendingChanges?.details?.firstName : ""} <hr /></div>
                        : ""}
                    {data?.teacher?.pendingChanges?.details?.lastName ? <div className="credential" style={{ fontWeight: 500 }}><span style={{ fontWeight: 500, color: '#b0b1b6' }}>Last Name: &nbsp;</span>{data?.teacher?.pendingChanges?.details ? data?.teacher?.pendingChanges?.details?.lastName : ""} <hr /> </div>
                        : ""}
                    {data?.teacher?.pendingChanges?.details?.dateOfBirth ? <div className="credential" style={{ fontWeight: 500 }}><span style={{ fontWeight: 500, color: '#b0b1b6' }}>DOB: &nbsp;</span>{data?.teacher?.pendingChanges?.details ? moment(data?.teacher?.pendingChanges?.details?.dateOfBirth && data?.teacher?.pendingChanges?.details?.dateOfBirth).format("DD-MMMM-YYYY") : ""} <hr /></div>
                        : ""}
                    {data?.teacher?.pendingChanges?.details?.gender ? <div className="credential" style={{ fontWeight: 500 }}><span style={{ fontWeight: 500, color: '#b0b1b6' }}>Gender: &nbsp;</span>{data?.teacher?.pendingChanges?.details ? data?.teacher?.pendingChanges?.details?.gender : ""} <hr /></div>
                        : ""}
                    {data?.teacher?.pendingChanges?.credentials?.qualification ?
                        <div className="credential"><span style={{ fontWeight: 500 }} >Qualification </span>
                            {renderView(data?.teacher?.pendingChanges?.credentials?.qualification, 'qualification')}
                        </div> : ""}

                    {data?.teacher?.pendingChanges?.credentials?.workExperience ?
                        <div className="credential"><span style={{ fontWeight: 500 }} >Work Experience </span>
                            {renderView(data?.teacher?.pendingChanges?.credentials?.workExperience, 'workExperience')}
                        </div> : ""}







                </div> : ""}
            </div>}
        </div>
    )
}
export default ApprovalModal;
