import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCategory, updateData } from "../../store/actions/Categories";
import { Button, Form, Input, Radio, Select, Switch, Upload, message, UploadFile, Checkbox } from "antd";
import * as Rules from "../../utils/rules";
import { renderOptions } from "../../utils/helper";
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';

const CreateCategory = ({ onHide, data, isUpdate, typeData, id }) => {

  const gradeData = data && data?.grades && data?.grades?.find((i) => i?._id == id)

  const dispatch = useDispatch();
  const props = useSelector(({ categories, common }) => {
    return { categories, common };
  });
  const { createCategoryLoading } = props.categories;
  const { curriculumLoading, gradesLoading } = props.common;
  const [type, setType] = useState(typeData);
  const [imageUrl, setImageUrl] = useState(data?.curriculumIcon || '')
  const [image, setimage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [initialValues, setinitialValues] = useState({
    category: data && data?.type ? data?.type : type,
    curriculumName: data && data?.name ? data?.name : "",
    isCurriculum: data && data?.isVocational ?  !data?.isVocational : true,
  })
  const callback = () => onHide();
  

  const onFinish = (values) => {
    let payload = new FormData();;
    if (values?.category === "curriculum") {
      payload.append('name', values?.curriculumName);
      payload.append('curriculum', data?._id);
      payload.append('isVocational', !values.isCurriculum);
      if (!image && imageUrl) {
        payload.append('imageUrl', imageUrl);
      }
      payload.append('image', image)
      // payload = {
      //   name: values?.curriculumName,
      //   curriculum: data?._id,
      //   isVocational: !values.isCurriculum
      // };
    } else if (values?.category === "subject") {
      if (data?.grades?.length && !values?.gradeId) {
        return message.error('Please select atleast 1 grade');
      }
      payload = {
        name: values?.subjectName,
        grade: values?.gradeId,
        curriculum: values?.curriculumId,
      };
    } else if (values?.category === "grade") {
      payload = {
        name: values?.gradeName,
        curriculum: values?.curriculumId,
      };
    }
    if (isUpdate) {
      dispatch(updateData(typeData, payload, callback))
    } else {
      dispatch(createCategory(values?.category, payload, callback));
    }
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    if(isJpgOrPng && isLt2M) {
      console.log(file);
      setimage(file)
    }
    return false;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  // const handleChange = (info) => {
  //   console.log(info.file, 'info.fileinfo.file');
  //   if (info.file.status === 'uploading') {
  //     setLoading(true);
  //     return;
  //   }
  //   if (info.file.status === 'done') {
  //     // Get this url from response in real world.
  //     getBase64(info.file.originFileObj, (url) => {
  //       setLoading(false);
  //       setImageUrl(url);
  //     });
  //   }
  //   if (info.file.status === 'error') {
  //     // Get this url from response in real world.
  //     setLoading(false);
  //     setImageUrl('')
  //     message.error('Something went wrong');
  //   }
  // };

  const renderInputs = (value, data = "") => {
    switch (value) {
      case 'curriculum':
        return (
          <Fragment>
            <Form.Item
              name='curriculumName'
              className='c-input'
              rules={Rules?.curriculumName}
              initialValue={(isUpdate && data) || data?.name}
            >
              <Input placeholder='curriculum' value={data?.name} />
            </Form.Item>
             <Form.Item >
              <Upload
                name='avatar'
                listType='picture-card'
                className='avatar-uploader'
                showUploadList={false}
                // action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                beforeUpload={beforeUpload}
                // onChange={handleChange}
              >
                {imageUrl ? <img src={imageUrl} alt='avatar' style={{ width: '100%' }} /> : uploadButton}
              </Upload>
            </Form.Item>
            <div style={{marginBottom:'20px'}}>
              {image && image?.name}
            </div>
            <Form.Item name='isCurriculum' className='c-input switchWithtag' valuePropName='checked'>
              {/* <h6 style={{marginRight:"10px"}}> Curriculum</h6> */}
              {/* {console.log()} */}
              {/* <input type="checkbox" name="isCurriculum" id="" /> */}
               <Switch
                style={{ marginRight: '10px', width: '150px' }}
                checkedChildren='Curriculum'
                unCheckedChildren='Non Curriculum '
              />
              {/* <h6>Vocational</h6> */}
            </Form.Item>
          </Fragment>
        );
      case 'subject':
        return (
          <Fragment>
            <Form.Item name='subjectName' className='c-input' rules={Rules?.subjectName}>
              <Input placeholder='Subject name' />
            </Form.Item>
            <Form.Item name='gradeId' rules={data?.isVocational ? '': Rules?.grade} initialValue={gradeData && gradeData?._id}>
              <Select defaultValue='' className='c-select' loading={gradesLoading}>
                <Select.Option value=''>Grade</Select.Option>
                {renderOptions(gradeData && [gradeData], 'name', '_id')}
              </Select>
            </Form.Item>
            <Form.Item name='curriculumId' rules={Rules?.curriculum} initialValue={data?._id ? data?._id : ''}>
              <Select className='c-select' loading={curriculumLoading}>
                {renderOptions([data], 'name', '_id')}
              </Select>
            </Form.Item>
          </Fragment>
        );
      case 'grade':
        return (
          <Fragment>
            <Form.Item name='gradeName' className='c-input' rules={Rules?.gradeName}>
              <Input placeholder='Grade name' />
            </Form.Item>
            <Form.Item name='curriculumId' rules={Rules?.curriculum} initialValue={data?._id ? data?._id : ''}>
              <Select className='c-select' loading={curriculumLoading}>
                {renderOptions([data], 'name', '_id')}
              </Select>
            </Form.Item>
          </Fragment>
        );
      default:
        return null;
    }
  };

  return (
    <Form
      name="create-category"
      className="modal-form"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <h4 className="modal-form-title">
        {isUpdate === true ? `Update ${type}` : `Add ${type}`}
      </h4>

      <div className="form-item-title"> {`You can now add your ${type} below :`} </div>
      <Form.Item name="category" className="c-select-group top" rules={Rules?.type}>
        <Radio.Group onChange={(e) => setType(e.target.value)}>
        </Radio.Group>
      </Form.Item>
      {renderInputs(type, data)}
      <Form.Item className="mb-0">
        <Button
          htmlType="submit"
          className="c-button button-with-loading"
          loading={createCategoryLoading}
          block
        >
          {isUpdate === false ? "ADD" : "Update "}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateCategory;
