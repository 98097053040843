import Approvals from '../constants/Approvals';

const initialState = {
    actionLoading: false,
    getAllApprovalsLoading: true,
    approvals: [],
    metaData:[],
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Approvals.GET_ALL_APPROVALS_API:
            return { ...state, getAllApprovalsLoading: action.loading, approvals: action.approvals, metaData: action?.metaData };
        case Approvals.APPROVAL_TAKE_ACTION_API:
            return { ...state, actionLoading: action.loading };
        case Approvals.GET_TEACHERS_APPROVAL:
            return {...state, actionLoading: action.loading };
        default:
            return state;
    }
};
