import { Button, Form, Input, InputNumber } from 'antd';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWalletConfig, updateWalletConfig } from '../../store/actions/Referral';
import { useForm } from 'antd/lib/form/util';
import { Loading } from '../../uiComponents';

const Refferal = () => {
  const dispatch = useDispatch()
  const [form] = useForm();
  const reduxState = useSelector(({ referral }) => {
    return {
      walletConfig: referral.walletConfig,
      getWalletDataLoading: referral.getWalletDataLoading,
      updateWalletLoading: referral.updateWalletLoading,
    };
  });
  const { walletConfig, getWalletDataLoading, updateWalletLoading } = reduxState;

  const onFinish = (values) => {
    console.log('Success:', values);
    dispatch(updateWalletConfig(values))
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useLayoutEffect(() => {
    dispatch(getWalletConfig())
    return () => {
    };
  }, [])
  useEffect(() => {
    form.setFieldsValue({
      coinsToBeAssigned: walletConfig?.coinsToBeAssigned,
      valuePerCoin: walletConfig?.valuePerCoin,
      maxPercentageCanBeSpent: walletConfig?.maxPercentageCanBeSpent || 0
    })
    return () => {
    }
  }, [walletConfig])
  

  return (
    <div style={{ width: '100%', position:'relative' }}>
      <section style={{ width: '100%', display: 'flex', flexDirection:'column', justifyContent: 'center', }}>
        <div className=''>
            <h2>Referral Config</h2>
        </div>
        {getWalletDataLoading || updateWalletLoading &&  <section style={{height:'100%', width:'100%', position:'absolute', zIndex:10}}>
           <Loading position="static" className="page-loading" />
        </section>}
        <section>
          <Form
          name='basic'
          form={form}
          initialValues={{
            coinsToBeAssigned: walletConfig?.coinsToBeAssigned,
            valuePerCoin: walletConfig?.valuePerCoin,
            maxPercentageCanBeSpent: walletConfig?.maxPercentageCanBeSpent || 0
          }}
          onFinish={onFinish}
          autoComplete='off'
          layout='vertical'
          className='modal-form'
        >
          <Form.Item
            label='Per referral points'
            name='coinsToBeAssigned'
            className='c-input'
            type="number"
            rules={[{ required: true, message: 'Please enter referral points' }]}
          >
            <InputNumber placeholder='Per referral points' />
          </Form.Item>
          <Form.Item
            label='Value per coin'
            name='valuePerCoin'
            className='c-input'
            type="number"
            rules={[{ required: true, message: 'Please enter value per coin' }]}
          >
            <InputNumber placeholder='Value per coin' />
          </Form.Item>

          <Form.Item
            label='Maximum Percentage of coins %'
            name='maxPercentageCanBeSpent'
            className='c-input'
            type="number"
            rules={[{ required: true, message: 'Please enter maximum percentage'}]}
          >
            <InputNumber placeholder='Maximum percentage'/>
          </Form.Item>
          <Form.Item className="mb-0">
            <Button className="c-button button-with-loading" type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
        </section>
       
      </section>
    </div>
  );
};

export default Refferal;
