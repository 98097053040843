import Students from "../constants/Students";
import { post } from "../../utils/methods";

export const getAllStudents = (payload, CB) => (dispatch) => {
    dispatch({ type: Students.GET_ALL_STUDENTS_API, loading: true, data: [] });
    post('student/getAll', payload)
        .then(({ data }) => {
            console.log( data?.data?.metaData, ' data?.data?.metaData');
            let students = (data.data && data.data.entries && data.data.entries.length) ? data.data.entries : []
            dispatch({
                type: Students.GET_ALL_STUDENTS_API,
                loading: false,
                data: (data.data && data.data.entries && data.data.entries.length) ? data.data.entries : [],
                metaData: data?.data?.metaData
            });
            CB && CB(students);
        })
        .catch((error) => {
            dispatch({ type: Students.GET_ALL_STUDENTS_API, loading: false, data: [] });
        });
};

export const getStudentSubjects = (payload, CB) => (dispatch) => {
    dispatch({ type: Students.GET_STUDENT_SUBJECTS, loading: true, data: [] });
    // dispatch({type: Students.GET_ALL_STUDENTS_API,})
    post('student/getStudentSubjects', payload)
        .then(({ data }) => {
            console.log("console.log",data)
            let subjects = (data?.data && data?.data?.entries && data?.data?.entries?.length) ? data?.data?.entries : []
            dispatch({
                type: Students.GET_STUDENT_SUBJECTS,
                loading: false,
                data: (data?.data && data?.data?.entries && data?.data?.entries?.length) ? data?.data?.entries : []
            });
            CB && CB(subjects);
        }).catch((error) => {
            dispatch({ type: Students.GET_STUDENT_SUBJECTS, loading: false, data: [] });
        })
}

export const getMyTutors = (payload) => (dispatch) => {
    dispatch({ type: Students.GET_MY_TUTORS_API, loading: true, data: [] });
    post('subscription/getStudentTutors', payload)
        .then(({ data }) => {
            dispatch({
                type: Students.GET_MY_TUTORS_API,
                loading: false,
                data: (data.data && data.data.entries && data.data.entries.length) ? data.data.entries : []
            });
        })
        .catch((error) => {
            dispatch({ type: Students.GET_MY_TUTORS_API, loading: false, data: [] });
        });
};

export const getAllIntorductoryMeetings = (payload, CB) => (dispatch) => {
    dispatch({ type: Students.GET_ALL_INTRODUCTORY_MEETING_API, loading: true, data: [] });
    post('student/getIntroductoryMeetings', payload)
        .then(({ data }) => {
            let meetings = (data.data.entries && data.data.entries.length) ? data.data.entries : [];
            dispatch({
                type: Students.GET_ALL_INTRODUCTORY_MEETING_API,
                loading: false,
                data: (data.data && data.data.entries) ? data.data.entries : [],
                totalHired: data.data && data.data.totalHired ? data.data.totalHired : 0,
                totalRejected: data.data && data.data.totalRejected ? data.data.totalRejected : 0

            });
            CB && CB(meetings)
        })
        .catch((error) => {
            dispatch({ type: Students.GET_ALL_INTRODUCTORY_MEETING_API, loading: false, data: [] });
        });
};