import React, { useState } from "react";
import {
  CModal,
  Loading,
  CImage,
} from "../../uiComponents";
import {
  MappedElement,
  renderUserName,
  renderUserImage,
  dataEmpty,
  renderUserFullName,
  getObjectValues,
  renderParentInfoWithKey,
  formatNumberWithCommas,
} from "../../utils/helper";
import { IoIosArrowRoundBack } from "react-icons/io";

import {
  Button,

} from "antd";
import moment from "moment"; 
export default function MyCODListingsComponent(props) {
    const { data, updateFunction, backFunction, loading } = props;
    const [isOpen, updateIsOpen] = useState(false);
    const [selectedData, updateSelectedData] = useState(null);
  
    const toggleDetailModal = (value, data = null) => {
      updateSelectedData(data);
      updateIsOpen(value);
    };
  
    const renderListItem = (value, i) => {
      return (
        <li key={i} className="list-item mb-1">
          <span>
            <a href={false} onClick={() => toggleDetailModal(true, value)}>
              <CImage
                className="image"
                imageUrl={renderUserImage(
                  getObjectValues(value?.student, "imageUrl")
                )}
                alt=""
              />
            </a>
          </span>
          <div className="flex-1 d-flex align-items-center">
            <div className="text flex-1">{renderUserName(value?.student)}</div>
          </div>
          <div className="text flex-1">
            PKR {formatNumberWithCommas(getObjectValues(value, "amount"))}
          </div>
          <div className="text flex-1">
            {moment(
              getObjectValues(value?.billingAddress, "collectionDate")
            ).format("MM/DD/YYYY")}
          </div>
          <div className="button-groups d-flex flex-row mr-0">
            <Button
              onClick={() =>
                updateFunction({ finance: value?._id, status: "APPROVED" })
              }
              className="c-button"
            >
              Received
            </Button>
            <Button
              onClick={() =>
                updateFunction({ finance: value?._id, status: "REJECTED" })
              }
              className="c-button mr-0"
            >
              Decline
            </Button>
          </div>
        </li>
      );
    };
  
    const renderHeader = () => {
      return (
        <div className="inner-header">
          <span onClick={() => backFunction(false)}>
            {" "}
            <IoIosArrowRoundBack />{" "}
          </span>
          <h4>Pending Cash on Demand</h4>
        </div>
      );
    };
  
    return (
      <section className="finances d-flex flex-column">
        {renderHeader()}
        <div className="c-section mb-5 h-100">
          {loading ? (
            <Loading position="static" className="page-loading mt-5" />
          ) : data && data.length ? (
            <ul className="list mb-5">
              <MappedElement data={data} renderElement={renderListItem} />
            </ul>
          ) : (
            dataEmpty()
          )}
  
          <CModal
            show={isOpen}
            className="right-side"
            onHide={() => toggleDetailModal(false)}
          >
            {selectedData && Object.keys(selectedData).length ? (
              <div className="user-detail-view d-flex flex-column scroll-y">
                <div className="user-image-with-name">
                  <CImage
                    imageUrl={renderUserImage(
                      getObjectValues(selectedData?.student, "imageUrl")
                    )}
                    alt=""
                  />
                  <h4>{renderUserName(selectedData?.student)}</h4>
                </div>
                <ul>
                  <li>
                    <span>Name</span>
                    <span>{renderUserFullName(selectedData?.student)}</span>
                  </li>
                  <li>
                    <span>Email</span>
                    <span>{getObjectValues(selectedData?.student, "email")}</span>
                  </li>
                  <li>
                    <span>Address</span>
                    <span>
                      {getObjectValues(selectedData?.billingAddress, "address")}
                    </span>
                  </li>
                  <li>
                    <span>Amount</span>
                    <span> PKR {getObjectValues(selectedData, "amount")}</span>
                  </li>
                  <li>
                    <span>Father Email</span>
                    <span>
                      {renderParentInfoWithKey(
                        selectedData?.student,
                        "fatherEmail"
                      )}
                    </span>
                  </li>
                </ul>
              </div>
            ) : null}
          </CModal>
        </div>
      </section>
    );
  }