import React, { Fragment, useState, useEffect } from "react";
import {
  CModal,
  CNav,
  Loading,
  CImage,
} from "../../uiComponents";
import {
  MappedElement,
  renderUserName,
  dataEmpty,
  formatNumberWithCommas,
  moneyFormat,
} from "../../utils/helper";
import { CSearchBar } from "../../uiComponents/";
import {
  Button,
} from "antd";
import {
  getAllTeacherFinances,
  sendMoneyTeacher,
  getFinancesForAdmin,

} from "../../store/actions/Finances";
import { useDispatch, useSelector } from "react-redux";
import MyCODListingsComponent from "../myFinances/CashOnDeman";
import { post } from "../../utils/methods";



import { Tabs, Dropdown, Menu } from "antd";




function MyFinancesComponent(props) {

    
    
    const { finances, loading, adminFinances, getAllTeacherFinances } = props;



  const transactionData = (value, valex) => {
    console.log('values :', value)
    if(value.type == 'STUDENT_PAYMENT'){
      return (
        <li className="list-item mb-1" key={valex}>
          <CImage
            className="image"
            imageUrl={
              value?.student?.profile?.imageUrl
            }
            alt=""
          />
          <div className="flex-1 d-flex align-items-center">
            <h6 className="text">{value && `${value?.student?.profile?.firstName} ${value?.student?.profile?.lastName}`}</h6>
            {/* <div className="c-tag rounded"> Disputes</div> */}
          </div>
          <div className="text flex-1">
            {/* {value && pending
              ? `$ ${formatNumberWithCommas(pending)}`
              : "$ 0"} */}
              {value.course.subject.name ? `${value?.course?.subject?.name}` : 'Counseling'}
          </div>

          <div className="text flex-1">
              {value && `$ ${value.netAmount}`}
          </div>

          <div className="text flex-1">
              {value && ` ${value.paidByPoints ? value.paidByPoints + " " + '(' + value?.valuePerCoin + ")" : 0}`}
          </div>

          <div className="text flex-1" style={{ paddingRight: '2%'}}>
              {value && `$ ${value.amount}`}
          </div>

          <div className="text flex-1">
              {value && `${value.status}`}
          </div>
          {/* <div className="button-groups d-flex flex-row">
          <Button
              className="c-button"
            >
             {value.isPaid}
            </Button>
          </div> */}
  
        </li>
      );
    }
  };


  const searchPayToTeacher = (text) => {
    if (text !== "") {
      getAllTeacherFinances({ searchQueryText: text });
    } else {
      getAllTeacherFinances(null);
    }
  };

  return (
    <Fragment>



      <div className="c-section flex-1">
        <div className="d-flex c-section-header">
          <h3 className="page-title m-0 flex-1"> Student Payments </h3>
          <CSearchBar
            isShow={true}
            onTextChange={(e) => searchPayToTeacher(e)}
            isFixed={true}
          />
        </div>
        <div className="d-flex align-items-center mt-3 mb-4">
        </div>
        {loading ? (
          <Loading position="static" className="page-loading" />
        ) : (
          <>
            {finances && finances?.length ? (
              <Fragment>
                <div className="c-section-multiple-titles d-flex">
                  <h6 className="c-section-title flex-07" style={{ textAlign: 'center'}}>Student</h6>
                  <h6 className="c-section-title flex-04" style={{ marginLeft: '3%'}}>Subject</h6>
                  <h6 className="c-section-title flex-06" style={{ textAlign: 'center'}}>Paid By Stripe</h6>
                  <h6 className="c-section-title flex-06" style={{ textAlign: 'center'}}>Paid By Coins (Value Per Coin)</h6>
                  <h6 className="c-section-title flex-06" style={{ textAlign: 'center'}}>Total Amount</h6>
                  <h6 className="c-section-title flex-1"> </h6>
                </div>
                <ul className="list mb-5" style={{overflowY: 'scroll', paddingBottom: '250px'}}>
                  <MappedElement
                    data={finances?.length ? finances : []}
                    renderElement={transactionData}
                  />
                </ul>
              </Fragment>
            ) : (
              dataEmpty()
            )}
          </>
        )}
      </div>
    
    </Fragment>
  );
}
function Transactions() {


// const { TabPane } = Tabs;

    const dispatch = useDispatch()

    const reduxState = useSelector(({ finances }) => {
      return {
        finances: finances?.finances,
        adminFinances: finances?.adminFinances,
        loading: finances?.getFinancesLoading,
        adminFinancesLoading: finances?.getAdminFinancesLoading,
        cashOndemand: finances?.cashOnDemands,
        cashOndemandLoading: finances?.getashOnDemandsLoading,
        updateCashOnDemandLoading: finances?.updateCashOnDemandLoading,
      };
    });
  
    const { finances, adminFinances, cashOndemand, updateCashoOnDemand, loading, adminFinancesLoading, cashOndemandLoading } = reduxState;
  
   

    const [allData, setAllData] = useState([])
  
    const get = () => {
      dispatch(getAllTeacherFinances())
      dispatch(getFinancesForAdmin())
    }
  
    useEffect(() => {
      get();
      post('finance/getAll', {page: 1, limit: 100})
      .then((res)=>{
        console.log('finance response :', res.data)
        setAllData(res.data.data.entries)
      })
    }, []);
  
    return (
      <div className="my-finances w-100 d-flex flex-column">
   

     

      
          <>
              <MyFinancesComponent
                finances={allData}
                adminFinances={adminFinances}
                getAllTeacherFinances={getAllTeacherFinances}
                loading={loading}
                
              />
          </>
  
     

      </div>
    );
  }



export default Transactions