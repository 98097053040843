import React, { Fragment, useEffect, useState } from "react";
import { CNav, CImage } from "../../uiComponents";
import { Tabs } from "antd";
// import { AiOutlineEllipsis } from "react-icons/ai";
import { FiX, FiSearch } from "react-icons/fi";
import Details from "./Details";
import IntroductoryMeeting from "./IntroductoryMeeting";
// import ParentTeacherMeeting from "./ParentTeacherMeeting";
// import Comments from "./Comments";
import { getAllParents } from "../../store/actions/Parents";
import { connect } from "react-redux";
import { Loading } from "../../uiComponents/index";
import { CTags } from "../../uiComponents/index";
import { MappedElement, renderUserName, renderChildren, dataEmpty } from "../../utils/helper";
import Children from "./Children";
import CSearchBar from "../../uiComponents/cSearchBar/CSearchBar";
const { TabPane } = Tabs;

function Parents(props) {

  const { getAllParents, parents, getAllParentsLoading, location, history } = props;
  const [selectedParent, updateSelectedParent] = useState(null);
  const [showSearch, setShowSearch] = useState(null);
  const { state } = location;

  const navigateTo = (pathname, id) => {
    history.push({ pathname, state: { id } })
  };

  const CTypeList = ({ data }) => {
    if (data && data.length) {
      return (
        <ul className="m-0 p-0 c-type-list">
          <MappedElement data={data} renderElement={(obj, index) => {
            return (
              <li key={index}
                  className={`d-flex flex-row c-type-list-item 
                   ${selectedParent && (obj._id === selectedParent._id ? 'active' : '')}
                  ${obj.childDetails && obj.childDetails.length ? "align-items-start" : "align-items-center"}`}
                  onClick={() => updateSelectedParent(obj)}>
                <CImage imageUrl={obj.imageUrl ? obj.imageUrl : ""} alt={obj.name} />
                <div className="flex-1 c-type-list-item-info">
                  <h6 className="m-0"> {renderUserName(obj)} </h6>
                  {renderChildren(obj)}
                  <CTags data={obj.tags} />
                </div>
              </li>
            );
          }}
          />
        </ul>
      );
    }
    return null;
  };

  useEffect(() => {
    getAllParents(null, getAllParentCallBack)
  }, []);

  const getAllParentCallBack = (payload) => {
    if (state && state.id) {
      let findParent = payload.find(obj => obj._id === state.id);
      updateSelectedParent(findParent)
    } if (payload && payload.length) {
      updateSelectedParent(payload[0])
    }
  };
  const searchParent = (text) => {
    if(text !== ''){
      getAllParents({searchQueryText: text},getAllParentCallBack)
    } else {
      getAllParents(null,getAllParentCallBack)
    }
  };

  const toggleSearch = () => setShowSearch(!showSearch);

  return (
    <div className="parents w-100 d-flex flex-column">
      <CNav />
      <div className="two-card-view d-flex">
        <div className="first-card-view">
        <div className="first-card-view-header d-flex">

           <h1 className="flex-1">{showSearch ? '' : 'Parents'}</h1>
                 <button onClick={() => toggleSearch()}>
                   {showSearch ? <FiX /> : <FiSearch />}
                 </button>

                 <CSearchBar resetSearch={showSearch} isShow={showSearch} onTextChange={e => searchParent(e)}  />

           </div>

          <div className="first-card-view-body with-out-filter scroll-y">
            {getAllParentsLoading ? <div className="w-100 h-100">
              <Loading position="static" className="page-loading" />
            </div> : (parents && parents.length) ?   <CTypeList data={parents}
            /> : dataEmpty()}
          </div>
        </div>
        <div className="second-card-view d-flex flex-column">
          {(selectedParent && Object.keys(selectedParent).length) ? <Fragment>
            <div className="second-card-view-header d-flex align-items-center">
              <CImage imageUrl={selectedParent ? selectedParent.imageUrl : null} alt="" />
              <h1 className="flex-1">{renderUserName(selectedParent)}</h1>
            </div>
            <Tabs className="h-100 d-flex flex-column c-tabs" defaultActiveKey="details">
              <TabPane tab="Details" key="details">
                <div className="content-container scroll-y">
                  <Details parent={selectedParent} />
                </div>
              </TabPane>
              <TabPane tab={`Children's (${selectedParent.childDetails ? selectedParent.childDetails.length: 0})`} key="children">
                <div className="content-container scroll-y">
                 {selectedParent.childDetails? <Children onClick={(id)=> navigateTo('/students',id)}
                                                         history={history} child={selectedParent.childDetails} /> : null}
                </div>
              </TabPane>
              {/*<TabPane tab="Comments" key="comments">*/}
              {/*  <div className="content-container scroll-y">*/}
              {/*    <Comments />*/}
              {/*  </div>*/}
              {/*</TabPane>*/}

              <TabPane tab="Introductory Meeting" key="introductory-meeting">
                <div className="content-container scroll-y">
                  <IntroductoryMeeting parentId={selectedParent._id}  />
                </div>
              </TabPane>
              {/*<TabPane tab="Parent Teacher Meeting" key="parent-teacher-meeting">*/}
              {/*  <div className="content-container scroll-y">*/}
              {/*    <ParentTeacherMeeting />*/}
              {/*  </div>*/}
              {/*</TabPane>*/}
            </Tabs>
          </Fragment> : dataEmpty()}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = ({ parents }) => {
  return {
    getAllParentsLoading: parents.getAllParentsLoading,
    parents: parents.parents,
  };
};
const mapDispatchToProps = {
  getAllParents
};
export default connect(mapStateToProps, mapDispatchToProps)(Parents);
