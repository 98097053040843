import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { Button, Form, Input } from "antd";
import * as Rules from "../../utils/rules";
import { login } from "../../store/actions/Users";
import { AuthDesignWrapper } from "../../uiComponents";

const CForm = () => {
  
  const dispatch = useDispatch();
  let loading = useSelector(({ users }) => users.loginLoading);
  
  const onFinish = (values) =>
    dispatch(login({ method: "EMAIL", role: "admin", ...values })
    );

  return (
    <Form
      name="normal_login"
      initialValues={{
        email: "",
        password: "",
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={Rules?.emailRule}
        className="c-input c-input-check"
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={Rules?.passwordRule}
        className="c-password-input"
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          className="c-button c-button-primary button-with-loading"
          loading={loading}
          block
        >
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};

function Login() {
  return (
    <AuthDesignWrapper
      title="Sign in"
      imagePath={require("../../assets/images/welcome-background.jpeg")}
    >
      <CForm />
    </AuthDesignWrapper>
  );
}
export default Login;
