import React, { useState, useEffect ,useRef} from "react";
import { Input } from "antd";
import { FiSearch,  } from "react-icons/fi";
import _ from 'lodash'

const CSearchBar = ({resetSearch=null, onTextChange, placeholder = "Search", isShow, time = 500,isFixed=false }) => {

  const [userQuery, setUserQuery] = useState("");
  const delayedQuery = useRef(_.debounce((userQuery) =>onTextChange(userQuery), time)).current;
  const onChange = e => {
    setUserQuery(e.target.value);
    delayedQuery(e.target.value);
  };
  useEffect(()=>{
    if(resetSearch!==null){
      if(!resetSearch){
        setUserQuery("");
        delayedQuery("")
      }
    }
    
  },[resetSearch,delayedQuery])
  //Added Delaed 
  return (
    <div className={!isFixed? `search-bar flex-1 c-input ${isShow ? 'active' : ''}`:'fixed-search-bar d-flex flex-row'}>
      <Input
        onChange={onChange} 
        value={userQuery} 
        placeholder={placeholder}
        className="w-100"
      />
      {isFixed?<span><FiSearch /></span>:null}
    </div>
  );
};

export default CSearchBar;
