import React, { useEffect } from "react";
import { CNav, Loading } from "../../uiComponents";
import {
  formatNumberWithCommas,
  MappedElement,
  renderNotificationText,
} from "../../utils/helper";
import { Progress } from "antd";
import { getDashboardData } from "../../store/actions/Dashboard";
import { AiOutlineCheckCircle, AiOutlineNotification } from "react-icons/ai";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";


function Dashboard() {

  const dispatch = useDispatch();

  const get = () => {
    dispatch(getDashboardData());
  }

  useEffect(() => {
    get()
  }, []);

  const reduxState = useSelector(({ dashboard }) => {
    return {
      loading: dashboard?.loading,
      announcements: dashboard?.announcements,
      notifications: dashboard?.notifications,
      usersInSystem: dashboard?.usersInSystem,
      finances: dashboard?.finances,
      teacherStatus: dashboard?.teacherStatus,
      classData: dashboard?.classData,
    };
  });

  const { teacherStatus, classData, finances, usersInSystem, notifications, announcements, loading } = reduxState;


  const renderNotificationItem = (obj, index) => {
    return (
      <li key={index} className="list-item notification-list-item">
        <AiOutlineNotification className="icon" />
        <h4 className="text flex-1">{renderNotificationText(obj)}</h4>
        <p className="secondary-text">
          {obj.createdAt ? moment(obj.createdAt).fromNow() : "-"}
        </p>
      </li>
    );
  };
  const renderNotification = (array) => {
    if (array) {
      return (
        <div className="c-section mb-5">
          <ul className="list notification-list">
            <MappedElement
              data={array}
              renderElement={(obj, index) => renderNotificationItem(obj, index)}
            />
          </ul>
        </div>
      );
    }
    return null;
  };

  const renderAnnouncementsItem = (obj, index) => {
    return (
      <li key={index} className="basic-section-list-item">
        <AiOutlineCheckCircle className="basic-section-icon" />
        <h4 className="text title">{obj?.message}</h4>
        <p className="secondary-text date">
          {obj?.createdAt ? moment(obj?.createdAt).fromNow() : "-"}
        </p>
      </li>
    );
  };
  const renderAnnouncements = (array) => {
    if (array) {
      return (
        <div className="c-section mb-5">
          <ul className="basic-section-list">
            <MappedElement
              data={array}
              renderElement={(obj, index) =>
                renderAnnouncementsItem(obj, index)
              }
            />
          </ul>
        </div>
      );
    }
    return null;
  };

  const getSystemProgress = (type) => {
    let total = 0;
    let calculateValue = 0;
    if (usersInSystem) {
      total = usersInSystem.teachers + usersInSystem.students;

      if (type === "teachers") {
        calculateValue = (usersInSystem.teachers / total) * 100;
      }
      if (type === "students") {
        calculateValue = (usersInSystem.students / total) * 100;
      }
    }
    return Math.round(calculateValue);
  };
  const getRevenue = (type) => {
    let total = 0;
    let calculateValue = 0;
    let amount = 0;
    if (finances) {
      let revenue = finances?.totalRevenue ? finances?.totalRevenue : 0;
      let commission = finances?.totalCommission ? finances?.totalCommission : 0;
      total = revenue > commission ? revenue : commission;
      if (type === "revenue") {
        calculateValue = (revenue / total) * 100;
        amount = revenue;
      }
      if (type === "commission") {
        calculateValue = (commission / total) * 100;
        amount = commission;
      }
      if (calculateValue < 1) calculateValue = 1;
    }
    return {
      percent: Math.round(calculateValue),
      amount: formatNumberWithCommas(amount),
    };
  };

  return (
    <div className="dashboard body-container-padding">
      <CNav />
      {loading ? (
        <div className="w-100 h-100">
          <Loading position="static" className="page-loading" />
        </div>
      ) : (
        <div className="flex-container ">
          <div className="flex-left">
            <div className="c-section mb-5">
              <div className="graph-with-value-card">
                <div className="graph">
                  <div className="c-card circle-progress-container">
                    <div className="c-card-title">
                      Users
                    </div>
                    <div className="circle-progress-inner-container">
                      <span className="graph">
                        <Progress
                          type="circle"
                          showInfo={false}
                          strokeLinecap={"square"}
                          strokeColor={"#FFBF00"}
                          trailColor={"#f1f2f5"}
                          strokeWidth={15}
                          width={180}
                          percent={getSystemProgress("students")}
                          gapPosition="left"
                        />
                      </span>
                      <div className="icon-overlay">
                        <div className="shadow-overlay">
                          <img
                            className="shadow-image"
                            src={require("../../assets/svgs/graduation-cap.svg")}
                            alt=""
                            draggable={false}
                          />
                        </div>

                      </div>
                    </div>
                    <div className="circle-progress-values">
                      <div className="circle-progress-value">
                        <span
                          className="dot"
                          style={{ "--color": "#FFBF00" }}
                        />
                        <div className="circle-progress-value-info ">
                          <h6 className="family" >{getSystemProgress("students") && getSystemProgress("students") ? getSystemProgress("students") : 0}%</h6>
                          <p>Students</p>
                        </div>
                      </div>
                      <div className="circle-progress-value">
                        <span
                          className="dot"
                          style={{ "--color": "#f1f2f5" }}
                        />
                        <div className="circle-progress-value-info">
                          <h6 className="family" >{getSystemProgress("teachers") && getSystemProgress("teachers") ? getSystemProgress("teachers") : 0}%</h6>
                          <p>Teachers</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="values-card">
                  <div className="c-card value-card ">
                    <div className="c-card-heading">
                      <h4 className="family">
                        {classData && classData?.mostClassesDoneByTeacher
                          ? classData?.mostClassesDoneByTeacher
                          : 0}
                      </h4>

                    </div>
                    <p>Most classes done by a teacher</p>
                  </div>
                  <div className="c-card value-card ">
                    <div className="c-card-heading">
                      <h4 className="family">
                        {finances && finances?.teacherRevenue
                          ? formatNumberWithCommas(finances?.teacherRevenue)
                          : 0}

                      </h4>
                    </div>

                    <p>Most revenue generated by a teacher</p>
                  </div>
                  <div className="c-card value-card ">
                    <div className="c-card-heading">
                      <h4 className="family">
                        {finances && finances?.studentRevenue
                          ? formatNumberWithCommas(finances?.studentRevenue)
                          : 0}
                      </h4>
                    </div>
                    <p>Most revenue generated by a student</p>
                  </div>
                </div>
              </div>
              <div className="graph">
                <div className="c-card flex-1 square-progress-container">
                  <div className="c-card-title">
                    Revenue
                  </div>
                  <div className="square-progress-inner-container">
                    <div className="square-progress">
                      <Progress
                        strokeLinecap="square"
                        showInfo={false}
                        strokeColor={"#228B22"}
                        trailColor={"transparent"}
                        strokeWidth={35}
                        percent={getRevenue("revenue")?.percent}
                      />
                      <span >{getRevenue("revenue")?.amount ? getRevenue("revenue")?.amount : ""}</span>
                    </div>
                    <div className="square-progress">
                      <Progress
                        strokeLinecap="square"
                        showInfo={false}
                        strokeColor={"#9ACD32"}
                        trailColor={"transparent"}
                        strokeWidth={35}
                        percent={getRevenue("commission")?.percent}
                      />
                      <span>{getRevenue("commission")?.amount ? getRevenue("commission")?.amount : ""}</span>
                    </div>
                  </div>
                  <div className="square-progress-values">
                    <div className="square-progress-value">
                      <span className="dot" style={{ "--color": "#228B22" }} />
                      <p className="dashboard-span">Total revenue generated</p>
                    </div>
                    <div className="square-progress-value">
                      <span className="dot" style={{ "--color": "#9ACD32" }} />
                      <p className="dashboard-span">Total commissions generated</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="graph">
                <div className="c-card dashboard-progress-container">
                  <div className="c-card-title text-center">
                    Number of classes conducted till now
                  </div>
                  <div className="card-value">
                    <span className="family">

                      {classData && classData?.executedLessonCount
                        ? classData?.executedLessonCount
                        : 0}
                    </span>
                  </div>
                </div>
                <div className="c-card dashboard-progress-container">
                  <div className="c-card-title text-center">
                    Total recorded classes available till now
                  </div>
                  <div className="card-value">
                    <span className="family">
                      {classData && classData?.recordedLessonsCount
                        ? classData?.recordedLessonsCount
                        : 0}
                    </span>
                  </div>
                </div>
              </div>
              <div className="graph">
                <div className="c-card teacher-info ">
                  <div className="align">
                    <div className="c-card-title mb-4 normal-text dashboard-span " >
                      Total applied : &nbsp;
                      <span >
                        {teacherStatus && teacherStatus?.totalApplied
                          ? `${teacherStatus?.totalApplied} `
                          : 0}
                      </span>
                    </div>
                  </div>

                  <div className="info-list">
                    <div className="info-list-item">
                      <span className="info-list-item-title">
                        {teacherStatus && teacherStatus?.totalRejected
                          ? teacherStatus?.totalRejected
                          : 0}
                      </span>
                      <span className=" c-card-title info-list-item-status normal-text dashboard-span" >
                        Rejected
                      </span>
                    </div>
                    <div className="info-list-item">
                      <span className="info-list-item-title">
                        {teacherStatus && teacherStatus?.totalApproved
                          ? teacherStatus?.totalApproved
                          : 0}
                      </span>
                      <span className="c-card-title info-list-item-status normal-text dashboard-span" >
                        Approved
                      </span>
                    </div>
                    <div className="info-list-item">
                      <span className="info-list-item-title" >
                        {teacherStatus && teacherStatus?.totalPending
                          ? teacherStatus?.totalPending
                          : 0}
                      </span>
                      <span className="c-card-title info-list-item-status normal-text dashboard-span" >
                        Pending
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
