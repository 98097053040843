import React from 'react'
import { Image } from 'react-bootstrap'
import { renderUserImage } from '../../utils/helper'

function CImage({ imageUrl, placeholderImage = require("../../assets/images/placeholder-user-image.png"), className = '', alt = "" }) {
    return (
        <Image
            src={renderUserImage(imageUrl)}
            onError={image => image.target.src = placeholderImage}
            className={className} draggable={false} alt={alt}
        />
    )
}

export default CImage
