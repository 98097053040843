import React from 'react'

function Recordings({recordingData}) {
    return (
        <div className="details-tab-container">
                <ul className="user-info d-flex flex-column">
    
                  
                    <li style={{ display: 'flex', flexDirection: 'column'}}>
                        <table  style={{border: '1px solid #00000040', width: '100%', marginTop: '10px'}}>
                            <tr style={{border: '1px solid green', width: '40%'}}>
                               
                                {/* <th style={{border: '1px solid #00000040', paddingLeft: '10px'}}>Type</th> */}
                                <th style={{border: '1px solid #00000040', padding: '0 10px'}}>Subject</th>
                               <th style={{border: '1px solid #00000040', width: '35%', paddingLeft: '10px', textAlign: 'center'}}>Recording Urls</th>
                            </tr>
    
                            {
                              recordingData &&
                              recordingData.map((obj)=>{
                              //  let x = obj.type.split('_')
                                return(
                                  <tr height='30px' style={{ width: '20% !important'}}>
                                  {/* <td style={{border: '1px solid #00000040', paddingLeft: '4px', paddingRight: '4px',fontSize: '10px', fontWeight: '700'}}>{
                                  x[0] + " " + x[1] + ' ' + x[2]
                                  }</td> */}
                                  <td style={{border: '1px solid #00000040', paddingLeft: '10px'}}>{obj?.subjectData[0]?.name}</td>

                                  <td style={{ textAlign: 'center', overflowY: 'scroll', width: '25%', height: '10px !important'}}>
    
                                  {
                                    obj.files.length > 0 
                                    ?
                                    obj.files.map((item)=>{
                                      return(
                                        <>
                                          <a href={item.src} target='_blank'>
                                          {item.src}
                                          </a>
                                          <br/>
                                          </>
                                      )
                                    })
                                    :
                                    '--'
                                   
                                  }

                                  </td>
    
    
                              </tr>
                              )
                              })
                            }
    
                            {/* {
                                teacher &&
                                teacher?.offering?.oneToOneSubjects?.map((obj)=>{
    
                                return(
                                    <tr style={{border: '1px solid green', width: '40%'}}>
                                    <td style={{border: '1px solid #00000040', width: '50%', paddingLeft: '10px'}}>{obj?.subject?.name}</td>
                                    <td style={{border: '1px solid #00000040', paddingLeft: '10px'}}>$ {obj?.price}</td>
                                </tr>
                                )
    
                                })
                            } */}
    
                        </table>
                    </li>
    
                </ul>
            </div>
      )
}

export default Recordings