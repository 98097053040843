import Common from '../constants/Common';
const initialState = {
    countriesLoading: false,
    countries: [],
    subjectsLoading: false,
    subjects: [],
    curriculumLoading: false,
    curriculum: [],
    gradesLoading: false,
    grades: []
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Common.GET_ALL_COUNTRIES_API:
            return { ...state, countriesLoading: action.loading, countries: action.countries };
        case Common.GET_ALL_SUBJECT_API:
            return { ...state, countriesLoading: action.loading, subjects: action.subjects };
        case Common.GET_ALL_CURRICULUM_API:
            return { ...state, countriesLoading: action.loading, curriculum: action.curriculum };
        case Common.GET_ALL_GRADES_API:
            return { ...state, countriesLoading: action.loading, grades: action.grades };
        default:
            return state;
    }
};
