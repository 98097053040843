import React from "react";
import { dataEmpty, renderUserFullName, renderUserNickName, renderUserProfession } from "../../utils/helper";

function ParentDetails(props) {
  let father = props && props?.father && props?.father?.parentDetails ? props?.father?.parentDetails : [];
  let objectLength = Object.keys(father).length
  return (
    <div className="details-tab-container">
      <ul className="user-info d-flex flex-column">
        <div className="father">
          <div>Mother</div>
        </div>
        {
         father?.mother  ? <div>

            <li>
              <span>Name</span>
              <span>{renderUserFullName(props?.father?.parentDetails?.mother)}</span>
            </li>
            <li>
              <span>Email</span>
              <span>{props && props?.father?.parentDetails?.mother?.profile?.email ? props?.father?.parentDetails?.mother?.profile?.email : '-'}</span>
            </li>
            <li>
              <span>Phone</span>
              <span>{props && props?.father?.parentDetails?.mother?.profile?.phone ? props?.father?.parentDetails?.mother?.profile?.phone : '-'}</span>
            </li>
          </div> : dataEmpty()
        }
      </ul>

      <ul className="user-info d-flex flex-column">
        <div className="father">
          <div>Father</div>
        </div>

        {
          objectLength != 0 ? <div>

            <li>
              <span>Name</span>
              <span>{renderUserFullName(props?.father?.parentDetails?.father)}</span>
            </li>
            <li>
              <span>Email</span>
              <span>{props && props?.father?.parentDetails?.father?.profile?.email ? props?.father?.parentDetails?.father?.profile?.email : '-'}</span>
            </li>
            <li>
              <span>Phone</span>
              <span>{props && props?.father?.parentDetails?.father?.profile?.phone ? props?.father?.parentDetails?.father?.profile?.phone : '-'}</span>
            </li>
          </div> : dataEmpty()
        }

      </ul>
    </div>
  );
}
export default ParentDetails;
