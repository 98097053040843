import Referral from "../constants/Referral";

const initialState = {
    getWalletDataLoading: false,
    updateWalletLoading: false,
    walletConfig: {}
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Referral.GET_WALLET_INFO:
            return { ...state, getWalletDataLoading: action.loading, walletConfig: action.data };
        case Referral.UPDATE_WALLET_INFO:
            return { ...state, updateWalletLoading: action.loading};
        default:
            return state;
    }
};
