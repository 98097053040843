import React from "react";
import { dataEmpty, MappedElement } from "../../utils/helper";
import moment from 'moment'

function Credentials({ teacher }) {
    let credetials = teacher ? teacher.credentials : [];

    let workExperience = teacher?.credentials?.workExperience ? teacher?.credentials?.workExperience : [];
    let qualification = teacher?.credentials?.qualification ? teacher?.credentials?.qualification : []

    const dynamicView = (type, obj, i) => {
   
      
        switch (type) {
            case 'school':
                return (
                    <ul key={i} className="m-0 p-0 mb-3">
                        <li>
                            <span>Name</span>
                            <span>{obj?.name ? obj?.name : '-'}</span>
                        </li>
                        <li>
                            <span>Grade</span>
                            <span>{obj?.degree ? obj?.degree : '-'}</span>
                        </li>
                        <li>
                            <span>Certificate Type</span>
                            <span>{obj?.degreeType ? obj?.degreeType : '-'}</span>
                        </li>
                    </ul>
                );
            case 'university':
                return (
                    <ul key={i} className="m-0 p-0 mb-3">
                        <li>
                            <span>Name</span>
                            <span>{obj?.name ? obj?.name : "-"}</span>
                        </li>
                        <li>
                            <span>Degree</span>
                            <span>{obj?.major ? obj?.major : "-"}</span>
                        </li>
                        <li>
                            <span>GPA</span>
                            <span>{obj?.majorGpa ? obj?.majorGpa : "-"}</span>
                        </li>
                    </ul>
                );
            case 'qualification':
                return (
                    <ul key={i} className="m-0 p-0 mb-3">
                        <li>
                            <span>Name</span>
                            <span>{obj?.name ? obj?.name : "-"}</span>
                        </li>
                        <li>
                            <span>Title</span>
                            <span>{obj?.title ? obj?.title : "-"}</span>
                        </li>
                        <li>
                            <span>To</span>
                            <span>{obj?.to ? moment(obj?.to && obj?.to).format("DD-MMMM-YYYY") : 'Present'}</span>
                        </li>
                        <li>
                            <span>From</span>
                            <span>{obj?.from ? moment(obj?.from && obj?.from).format("DD-MMMM-YYYY") : '-'}</span>
                        </li>
                    </ul>
                );
            case 'workExperience':
                return (
                    <ul key={i} className="m-0 p-0 mb-3">
                        <li>
                            <span>Institute</span>
                            <span>{obj.name ? obj.name : '-'}</span>
                        </li>
                        <li>
                            <span>From</span>
                            <span>{obj?.from ? moment(obj?.from && obj?.from).format("DD-MMMM-YYYY") : '-'}</span>
                        </li>
                        <li>
                            <span>To</span>
                            <span>{obj?.to ? moment(obj?.to && obj?.to).format("DD-MMMM-YYYY") : 'Present'}</span>
                        </li>
                    </ul>
                );
            default:
                return null
        }
    };

    const renderView = (array, type) => {
        if (array && array.length) {
            return <MappedElement data={array} renderElement={(obj, index) => dynamicView(type, obj, index)} />
        }
        return dataEmpty();
    };

    return (
        <div className="details-tab-container">
            <div className="user-info d-flex flex-column">
                <h5> Work Experience </h5>
                {renderView(workExperience, 'workExperience')}
            </div>
            <div className="user-info d-flex flex-column">
                <h5> Qualification </h5>
                {renderView(qualification, 'qualification')}
            </div>
        </div>
    );
}
export default Credentials;
