import { Empty, message, notification, Select } from "antd";
import audio from "../assets/aud/alert.mp3";
import moment from "moment";
import React from "react";
import _ from "lodash";
import CImage from "../uiComponents/CImage/CImage";

message.config({
  duration: 2,
  maxCount: 1,
});

export const checkTokenInParam = (CB) => {
  let obj = null;
  let search =
    window.location && window.location.search
      ? window.location.search.substring(1)
      : "";
  if (search) {
    obj = JSON.parse(
      '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    );
  }
  window.history.replaceState("", "", window.location.href.split("?")[0]);
  return obj;
};

export const uuid = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return s4() + s4() + "-" + s4();
};

export const toast = (type, text) => {
  if (text) {
    if (type === "success") {
      message.success(text);
    } else if (type === "warning") {
      message.warning(text);
    } else if (type === "info") {
      message.warning(text);
    } else if (type === "error") {
      message.error(text);
    }
  }
};

export const aud = new Audio(audio);

export const openNotification = (title, description, type) => {
  if (type === "info") {
    notification.info({
      message: title,
      description: description,
    });
  } else {
    notification.open({
      message: title,
      description: description,
      onClick: () => {
      },
    });
  }
};

export const renderOptions = (options, title = "title", key = "value") => {
  if (options) {
    return options.map((option, index) => (
      <Select.Option key={index} value={option[key]}>
        {option[title] ? option[title] : "Grades"}
      </Select.Option>
    ));
  }
  return null;
};

export const getWeeksInYear = () => {
  let count = moment().weeksInYear();
  return Array.apply(null, new Array(count)).map((e, i) => ({ _id: i + 1 }));
};

export const getObjectValues = (object, key) => {
  if (object && Object.keys(object).length && object[key]) {
    return object[key];
  } else {
    return "";
  }
};

export const checkValueInObject = (object, key) => {
  return object && object[key] ? object[key] : "";
};

export const MappedElement = ({ data, renderElement, count, role }) => {
  if (data && data.length) {
  console.log('gett--',JSON.stringify(data.length,null,2))
    // const sortedArray = _.orderBy(data,[obj=>new Date((role=='tutor')?obj.profile?.createdAt:obj.profile?.grade?.createdAt)],['desc'])
      return data.map((obj, index, array) => {
      if (count) {
        return index <= count ? renderElement(obj, index, array) : null;
      } else {
        return renderElement(obj, index, array);
      }
    })
  }
  return null;
};

export const classAgainstValue = (value) => {
  if (value) {
    switch (value.toLowerCase()) {
      case "pending":
      case "subject":
        return "";
      case "grade":
        return "";
      case "curriculum":
        return "purple";
      case "not submitted":
        return "";
      case "crossed deadline":
        return "";
      default:
        return "";
    }
  }
  return "";
};

export const groupBy = (array, key) => {
  return _.mapValues(_.groupBy(array, key), (clist) =>
    clist.map((car) => _.omit(car, key))
  );
};

export const dataEmpty = () => {
  return (
    <div className="h-100 flex-1 d-flex justify-content-center align-items-center">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
  );
};

export const renderTotalMeetings = (hired, rejected) => {
  let total = hired + rejected;
  return Number(total);
};

export const getTags = (
  data,
  subjectKey = "",
  gradeKey = "gradeId",
  curriculumKey = ""
) => {
  let values = [];
  if (!curriculumKey && data && data?.name) {
    values.push(data?.name);
  }
  if (
    curriculumKey &&
    data &&
    data[curriculumKey] &&
    data[curriculumKey]?.name
  ) {
    values.push(data[curriculumKey]?.name);
  }
  if (subjectKey && data && data[subjectKey] && data[subjectKey]?.name) {
    values.push(data[subjectKey]?.name);
  }
  if (data && data[gradeKey] && data[gradeKey]?.name) {
    values.push(data[gradeKey]?.name);
  }
  return values;
};

export const renderUserName = (user, id) => {
  // console.log(user?.profile, '=========>>>>>>> user?.profile?.firstName');
  if (user && id && id === user?._id) {
    return "You";
  }
  if (user && user?.profile) {
    return `
      ${user?.profile?.firstName ? user?.profile?.firstName : ""}
      ${user?.profile?.lastName ? user?.profile?.lastName : ""}
    `;
  }
  return "-";
};

export const renderUser = (user, id) => {
  if (user && id && id === user?._id) {
    return "You";
  }
  if (user && user?.profile) {
    return `
      ${user?.profile?.userName ? user?.profile?.userName : ""}
    `;
  }
  return "-";
};


export const renderPhone = (user) => {
  if (user && user?.profile?.phone) {
    return user?.profile?.countryCodeNum + " " +  user?.profile?.phone;
  }
  return "-";
};
export const renderIp = (user) => {
  if (user && user?.profile?.ip) {
    return user?.profile?.ip;
  }
  return "-";
};
export const renderScore = (user) => {
  if (user && user?.profile?.score) {
    return user?.profile?.score;
  }
  return "0";
};
export const renderScoreByAdmin = (user) => {
  if (user && user?.profile?.scoreByAdmin) {
    return user?.profile?.scoreByAdmin;
  }
  return "-";
};
export const renderOneToOnePrice = (user) => {
  console.log('dekhe : ', user);
  if (user && user?.oneToOnePrice) {
    return user?.oneToOnePrice;
  }
  return "-";
};
export const renderCounselingPrice = (user) => {
  if (user && user?.offering?.counselingPrice) {
    return user?.offering?.counselingPrice;
  }
  return "-";
};

export const renderChildren = (obj) => {
  if (obj?.childDetails && obj?.childDetails?.length) {
    return obj?.childDetails.map((child, index) => (
      <div key={index} className="d-flex align-items-center mb-2 mt-3">
        <CImage
          className="child-img"
          imageUrl={child?.imageUrl ? child?.imageUrl : ""}
          alt=""
        />
        <div className="secondary-text">{renderUserName(child)}</div>
      </div>
    ));
  } else {
    return null;
  }
};

export const renderUserFullName = (user) => {
  if (user && user?.profile) {
    return `
      ${user?.profile?.firstName ? user?.profile?.firstName : ""}
      ${user?.profile?.middleName ? `${user?.profile?.middleName}` : ""}
      ${user?.profile?.lastName ? `${user?.profile?.lastName}` : ""}
    `;
  }
  return "-";
};

export const renderUserNickName = (user) => {
  if (user && user?.details) {
    return user?.details?.nickName ? user?.details?.nickName : "-";
  }
  return "-";
};

export const renderUrl = (user) => {
  if (user && user?.details) {
    return user?.details?.nickName ? user?.details?.nickName : "-";
  }
  return "-";
};

export const renderUserGender = (user) => {
  if (user && user?.profile) {
    return user?.profile?.gender ? user?.profile?.gender : "-";
  }
  return "-";
};

export const renderUserProfession = (user) => {
  if (user && user?.details) {
    return user?.details?.profession ? user?.details?.profession : "-";
  }
  return "-";
};

export const renderUserDob = (user) => {
  if (user && user?.profile && user?.profile?.dateOfBirth) {
    return moment(user?.profile?.dateOfBirth).format("DD.MM.YYYY");
  }
  return "-";
};

export const renderEmail = (user) => {
  if (user && user?.profile && user?.profile?.email) {
    return user?.profile?.email;
  }
  return "-";
};

export const renderUserAge = (user) => {
  if (user && user?.details && user?.details?.dateOfBirth) {
    let a = moment(),
      b = moment(user?.details?.dateOfBirth, "YYYY");
    return a.diff(b, "years");
  }
  return "-";
};

export const renderUserImage = (imageUrl) => {
  if (imageUrl) {
    return imageUrl;
  }
  return require("../assets/images/placeholder-user-image.png");
};

export const renderParentInfoWithKey = (user, key) => {
  if (user && user?.parentDetails) {
    return user?.parentDetails[key] ? user?.parentDetails[key] : "-";
  }
  return "-";
};

export const encodeQueryData = (data) => {
  const ret = [];
  for (const d in data) {
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  }
  return ret.join('&');
};

export const renderHours = (obj) => {
  if (obj) {
    return `${obj?.amount / obj?.purchasedRate} classes`;
  }
  return " - ";
};

export const renderTime = (time) => {
  if (time) {
    return moment(time).format("MMMM Do, h:mm a");
  }
  return " - ";
};

export const getMultipleValues = (array, parentKeyName, keyName) => {
  let value = [];
  if (array && array.length) {
    array.map((obj) => {
      if (
        keyName &&
        obj[parentKeyName] &&
        obj[parentKeyName][keyName] &&
        obj[parentKeyName][keyName]?.name
      ) {
        value.push(obj[parentKeyName][keyName]?.name);
      } else if (
        obj[parentKeyName] &&
        obj[parentKeyName] &&
        obj[parentKeyName]?.name
      ) {
        value.push(obj[parentKeyName]?.name);
      }
    });
  }
  return value.length ? value.join(", ") : "-";
};

export const getCredentialsDetail = (credentials, key, innerKey) => {
  if (credentials && credentials[key] && credentials[key][innerKey]) {
    return credentials[key][innerKey];
  }
  return "-";
};

export const renderNotificationText = (obj) => {
  switch (obj?.topic) {
    case "NEW_PROMO_CREATED_APPROVED":
      return `Your Promocode ${obj?.promo ? (obj?.promo?.couponCode ? obj?.promo?.couponCode : "") : ""
        } is approved.`;
    case "NEW_PROMO_CREATED_PENDING":
      return `Your Promocode ${obj?.promo ? (obj?.promo?.couponCode ? obj?.promo?.couponCode : "") : ""
        } is pending.`;
    case "NEW_PROMO_CREATED_REJECTED":
      return `Your Promocode ${obj?.promo ? (obj?.promo?.couponCode ? obj?.promo?.couponCode : "") : ""
        } is rejected.`;
    case "UPDATE_PROMO_CREATED_APPROVED":
      return `Your update request of Promocode ${obj?.promo ? (obj?.promo?.couponCode ? obj?.promo?.couponCode : "") : ""
        } is approved.`;
    case "UPDATE_PROMO_CREATED_REJECTED":
      return `Your update request of Promocode ${obj?.promo ? (obj?.promo?.couponCode ? obj?.promo?.couponCode : "") : ""
        } is rejected.`;
    case "TEACHER_CREDENTIAL_UPDATE_APPROVED":
      return "Your profile details has been approved.";
    case "TEACHER_CREDENTIAL_UPDATE_REJECTED":
      return "Your profile details has been rejected.";
    case "TEACHER_PROFILE_UPDATE_PENDING":
      return `${renderUserName(obj?.teacher)} request for  profile update.`;
    case "TEACHER_NEW_LESSON_SIGNUP":
      return `${obj?.student && obj?.student?.details
        ? `${obj?.student?.details?.firstName} ${obj?.student?.details?.lastName}`
        : "A student"
        } has subcribe your ${obj?.course && obj?.course?.subject && obj?.course?.subject?.name
          ? obj?.course?.subject?.name
          : ""
        } course.`;
    default:
      return "";
  }
};

export const formatNumberWithCommas = (x) => {
  if (x) {
    return x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  return 0;
};

export const renderFullName = (user) => {
  if (user?.profile) {
    return `
    ${user?.profile?.firstName ? user?.profile?.firstName : ""} 
    ${user?.profile?.lastName ? user?.profile?.lastName : ""}
    `;
  }
  return "";
};

export const getCourseName = (data) => {
  let course = data; 
  return course && course && course?.name
    ? {
      text: course?.name,
      backgroundColor: " rgba(253, 167, 64, 0.13)",
      color: "#FF9D28",
    }
    : {
      text: "-",
    };
};

export const courseTypeRename = (value) => {
  if (value) {
    switch (value) {
      case "GROUP_LESSON":
        return {
          text: "Group Lesson",
          backgroundColor: "rgba(16, 199, 227, 0.1)",
          color: "#10C7E3",
        };
      case "ONE_TO_ONE":
        return {
          text: "One To One",
          backgroundColor: "rgba(64, 91, 251, 0.1)",
          color: "#405BFB",
        };
      case "SPECIAL_EVENT":
        return {
          text: "Special Event",
          backgroundColor: "rgba(159, 209, 20, 0.1)",
          color: "#93C30B",
        };
      case "APPROVED":
        return {
          text: "APPROVED",
          backgroundColor: "rgba(159, 209, 20, 0.1)",
          color: "#93C30B",
        };
      default:
        return "";
    }
  }
  return "";
};

export const promoStatus = (value) => {
  if (value) {
    switch (value) {
      case "APPROVED":
        return {
          text: "APPROVED",
          backgroundColor: "rgba(159, 209, 20, 0.1)",
          color: "#93C30B",
        };
      case "REJECTED":
        return {
          text: "REJECTED",
          backgroundColor: "rgba(255, 0, 0, 0.1)",
          color: "#fb0101",
        };
      case "EXPIRED":
        return {
          text: "EXPIRED",
          backgroundColor: "rgba(255, 0, 0, 0.1)",
          color: "#fb0101",
        };
      case "PENDING":
        return {
          text: "WAITING FOR APPROVE",
          backgroundColor: "rgba(253, 167, 64, 0.13)",
          color: "#FF9D28",
        };
      default:
        return "";
    }
  }
  return "";
};

export const getCourse = (data, check = "showSubject") => {
  let values = [];
  let course = data && data?.course ? data?.course : { ...data };
  if (course) {
    if (check === "courseType" && course && course?.type) {
      values.push(courseTypeRename(course.type));
    }
    if (check === "showSubject" && course?.subject && course?.subject?.name) {
      values.push({
        text: course?.subject?.name,
        backgroundColor: " rgba(253, 167, 64, 0.13)",
        color: "#FF9D28",
      });
    }
    if (course?.subject && course?.subject?.grade && course?.subject?.grade?.name) {
      values.push({
        text: course?.subject?.grade?.name,
        backgroundColor: "rgba(159, 209, 20, 0.1)",
        color: "#93C30B",
      });
    }
    if (
      course?.subject &&
      course?.subject?.grade &&
      course?.subject?.grade?.curriculum &&
      course?.subject?.grade?.curriculum?.name
    ) {
      values.push({
        text: course?.subject?.grade?.curriculum?.name,
        backgroundColor: "rgba(166, 129, 227, 0.1)",
        color: "#A681E3",
      });
    }
  }
  return values;
};

export const getCourseStudents = (data) => {
  let course = data && data?.course ? data?.course : { ...data };
  return course && course?.otherStudents && course?.otherStudents?.entries
    ? course?.otherStudents?.entries
    : [];
};

export const moneyFormat = (num, digits) => {
  const serial = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = serial.length - 1; i > 0; i--) {
    if (num >= serial[i].value) break;
  }
  return (
    (num / serial[i].value).toFixed(digits).replace(rx, "$1") + serial[i].symbol
  );
};


// export const getProfilePercentage = (user = {}) => {
//   let { profile = {}, credentials = {}, introductory = {}, address = {}, bankDetails = {}, offering = {} } = user;

//   let singleValue = 100 / 4;

//   let totalValue = 0;

//   let routesEnable = "";

//   let obj = {
//     Profile: Object.keys(profile).length && profile?.imageUrl ? singleValue : 0,
//     Offering: Object.keys(offering).length ? singleValue : 0,
//     Credentials:
//       credentials &&
//       Object.keys(credentials).length &&
//       credentials?.qualification &&
//       credentials?.qualification?.length &&
//       credentials?.workExperience &&
//       credentials?.workExperience?.length
//         ? singleValue
//         : 0,
//     Introduction: Object.keys(introductory).length ? singleValue : 0,
//     // BankDetails: Object.keys(bankDetails).length ? singleValue : 0,
//     // Address: Object.keys(address).length ? singleValue : 0
//   };

//   let keys = Object.keys(obj);
//   // let compValues = Object.values(compeletionObj).reduce((a, b) => a + b);

//   for (let i = 0; i < keys.length; i++) {
//     if (!obj[keys[i]]) {
//       routesEnable = keys[i];
//       break;
//     }
//     if (i === keys.length - 1 && obj[keys[i]]) {
//       routesEnable = "Profile";
//     }
//   }

//   for (let key in obj) totalValue += obj[key];

//   return {
//     total: Math.round(totalValue),
//     pages: obj,
//     routesEnable,
//   };
// };


export const getProfilePercentage = (user = {}) => {
  let { profile = {}, credentials = {}, introductory = {}, address = {}, bankDetails = {}, offering = {} } = user;

  let singleValue = 100 / 6;

  let totalValue = 0;

  let routesEnable = "";

  let obj = {
    Profile: Object.keys(profile).length && profile?.imageUrl ? singleValue : 0,
    Offering: Object.keys(offering).length ? singleValue : 0,
    Credentials:
      credentials &&
      Object.keys(credentials).length &&
      credentials?.qualification &&
      credentials?.qualification?.length &&
      credentials?.workExperience &&
      credentials?.workExperience?.length
        ? singleValue
        : 0,
    Introduction: Object.keys(introductory).length ? singleValue : 0,
    BankDetails: Object.keys(bankDetails).length ? singleValue : 0,
    Address: Object.keys(address).length ? singleValue : 0
  };

  let keys = Object.keys(obj);
  // let compValues = Object.values(compeletionObj).reduce((a, b) => a + b);

  for (let i = 0; i < keys.length; i++) {
    if (!obj[keys[i]]) {
      routesEnable = keys[i];
      break;
    }
    if (i === keys.length - 1 && obj[keys[i]]) {
      routesEnable = "Profile";
    }
  }

  for (let key in obj) totalValue += obj[key];

  // console.log('klkl', Math.round(totalValue))
  return {
    total: Math.round(totalValue),
    pages: obj,
    routesEnable,
  };
};
