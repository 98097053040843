import Referral from '../constants/Referral';
import axios from 'axios';
import { toast } from '../../utils/helper';
import { ACCESS_TOKEN, getValueInToLocalStorage, referralUrl } from '../../utils/intercepter';

export const getWalletConfig = () => async (dispatch) => {
  dispatch({ type: Referral.GET_WALLET_INFO, loading: true });
//   const token = await getValueInToLocalStorage(ACCESS_TOKEN);
//   console.log({ token });
//   const header = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };
//   console.log(header);
  axios
    .get(`${referralUrl}/admin/coins`)
    .then(({ data }) => {
      dispatch({
        type: Referral.GET_WALLET_INFO,
        loading: false,
        data: data?.data,
      });
    })
    .catch((error) => {
      dispatch({ type: Referral.GET_WALLET_INFO, loading: false });
      toast('error', error?.response?.data?.message || error?.data?.message || 'something went wrong');
    });
};

export const updateWalletConfig = (payload) => (dispatch) => {
  dispatch({ type: Referral.UPDATE_WALLET_INFO, loading: true });
  axios
    .patch(
      `${referralUrl}/admin/coins?coinsToBeAssigned=${payload?.coinsToBeAssigned}&valuePerCoin=${payload.valuePerCoin}&maxPercentageCanBeSpent=${payload?.maxPercentageCanBeSpent}`
    )
    .then(({ data }) => {
        console.log(data, 'data?.data');
      toast('success', data?.message);
      dispatch({
        type: Referral.UPDATE_WALLET_INFO,
        loading: false,
        data: data?.data,
      });
    })
    .catch((error) => {
      dispatch({ type: Referral.UPDATE_WALLET_INFO, loading: false });
      toast('error', error?.response?.data?.message || error?.data?.message || 'something went wrong');
    });
};
