import React from "react";
import { CTags, CImage } from "../../uiComponents/index";
import { MappedElement } from "../../utils/helper";

const UserList = ({ data, onClick }) => {
  if (data && data.length) {
    return (
      <ul className="m-0 p-0 c-user-list">
        <MappedElement
          data={data}
          renderElement={(obj, index) => {
            return (
              <li
                key={index}
                className="d-flex flex-row align-items-start c-user-list-item"
                onClick={onClick}
              >
                <CImage imageUrl={obj && obj?.imageUrl ? obj?.imageUrl : null} alt={obj?.name} />
                <div className="flex-1 c-user-list-item-info">
                  <div className="d-flex align-items-center justify-content-between">
                    {obj?.role ? (
                      <div className="d-flex">
                        <MappedElement
                          data={["Daniel D", "Rita R", "Candie C"]}
                          renderElement={(users, index) => {
                            return (
                              <div>
                                <h6>{users},</h6>
                              </div>
                            );
                          }}
                        />
                      </div>
                    ) : (
                        <div className="d-flex align-items-center">
                          <b className="text">{obj?.name}</b>
                          {obj?.role ? (
                            <div>
                              •<span className="secondary-text">{obj?.role}</span>
                            </div>
                          ) : null}
                        </div>
                      )}
                    <div className="secondary-text mb-2">Yesterday</div>
                  </div>
                  <div className="secondary-text ellipsis-text overflow-hidden text-nowrap">
                    {obj?.message}
                  </div>
                  <h6>{obj?.students ? <span> . {obj?.students}</span> : ""}</h6>
                  {obj?.tags ? <CTags data={obj?.tags} /> : null}
                </div>
              </li>
            );
          }}
        />
      </ul>
    );
  }
  return null;
};

export default UserList;
