import TopSubject from '../constants/TopSubject';

const initialState={
    addSubject: false,
    getAllSubjectsLoading: false,
    subjects: [],
}

export default (state = initialState, action = {}) => {
    switch (action.type) {
      case TopSubject.ADD_SUBJECT_API:
        return {
          ...state,
          addSubject: action.loading,
        };
      case TopSubject.GET_ALL_SUBJECTS_API:
        return {
          ...state,
          getAllSubjectsLoading: action.loading,
          subjects: action.subjects,
        };
      default:
        return state;
    }
  };