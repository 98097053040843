import Students from "../constants/Students";

const initialState = {
    getAllStudentsLoading: true,
    students: [],
    metaData:[], 
    getStudentTutorsLoading: true,
    studentTutors: [],
    getAllMeetingLoading: true,
    meetings: [],
    totalHired: 0,
    totalRejected: 0,
    getAllStudentSubjectsLoading:true,
    getStudentSubjects: []

};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Students.GET_ALL_STUDENTS_API:
            return { ...state, getAllStudentsLoading: action.loading, students: action.data, metaData:action?.metaData };
        case Students.GET_MY_TUTORS_API:
            return { ...state, getStudentTutorsLoading: action.loading, studentTutors: action.data };
        case Students.GET_ALL_INTRODUCTORY_MEETING_API:
            return { ...state, getAllMeetingLoading: action.loading, meetings: action.data, totalHired: action.totalHired, totalRejected: action.totalRejected };
        case Students.GET_STUDENT_SUBJECTS:
            return {...state, getAllStudentSubjectsLoading: action.loading, getStudentSubjects: action.data}
        default:
            return state;
    }
};
