import Categories from '../constants/Categories';

const initialState = {
    createCategoryLoading: false,
    curriculumLoading: false,
    updateLoading: false,
    deleteLoading: false,
    curriculum: [],
    
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Categories.CREATE_CATEGORY_API:
            return { ...state, createCategoryLoading: action.loading };
        case Categories.GET_ALL_CURRICULUM_API:
            return { ...state, curriculumLoading: action.loading, curriculum: action.curriculum };
        case Categories.DELETE_CURRICULUM:
            return {...state, deleteLoading: action.loading};
        case Categories.DELETE_GRADE:
            return {...state, deleteLoading: action.loading };
        case Categories.DELETE_SUBJECT:
            return {...state, deleteLoading: action.loading };
        case Categories.UPDATE_CURRICULUM:
            return {...state, updateLoading:action.loading };
        default:
            return state;
    }
};
