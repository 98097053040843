import React, { useState } from "react";
import { CModal, CImage } from "../../uiComponents";
import { renderUserFullName, renderUserNickName } from "../../utils/helper";

function Details(data) {
  const [detailModalIsOpen, setDetailModal] = useState(false);
  const parent = data.parent ? data.parent : null
  const toggleDetailModal = (isOpen, data = {}) => {
    setDetailModal(isOpen);
  };
  return (
    <div className="details-tab-container">
      <ul className="user-info d-flex flex-column">
        <li>
          <span>Name</span>
          <span>{renderUserFullName(parent)}</span>
        </li>
        <li>
          <span>Nickname</span>
          <span>{renderUserNickName(parent)}</span>
        </li>

        <li>
          <span>Email</span>
          <span>{parent.email ? parent.email : '-'}</span>
        </li>
        {/* <li>
          <span>Gender</span>
          <span>Male</span>
        </li> */}
        <li>
          <span>Phone</span>
          <span>{parent.phone ? parent.phone : "-"}</span>
        </li>
        <li>
          <span>Profession</span>
          <span>{parent.details.profession ? parent.details.profession : "-"}</span>
        </li>
{/* 
        <li>
          <span>Child Information</span>
          <span>
            <a href={false} onClick={() => toggleDetailModal(true)}>
              <CImage
                alt=""
                className="image-rounded"
                imageUrl={require("../../assets/images/user-2.jpg")}
              />
            </a>
          </span>
        </li> */}
        {/* <li>
          <span>Paid</span>
          <span>35$</span>
        </li> */}
        {/* <li>
          <span>Commission</span>
          <span>134$</span>
        </li> */}
      </ul>
      <CModal
        show={detailModalIsOpen}
        className="right-side"
        onHide={() => toggleDetailModal(false)}
      >
        <div className="user-detail-view d-flex flex-column scroll-y">
          <div className="user-image-with-name">
            <CImage imageUrl={require("../../assets/images/user-3.jpg")} alt="" />
            <h4>Dianne R.</h4>
          </div>
          <ul>
            <li>
              <span>Name</span> <span>Dianne Rid</span>
            </li>
            <li>
              <span>Nickname</span> <span>Dianne</span>
            </li>
            <li>
              <span>Email</span> <span>dianne.rid@yahoo.com</span>
            </li>
            <li>
              <span>Gender</span> <span>Female</span>
            </li>
            <li>
              <span>Phone</span> <span>(631) 681-3147</span>
            </li>
            <li>
              <span>Curriculum</span> <span>Lorem ipsum</span>
            </li>
            <li>
              <span>Grade</span> <span>9</span>
            </li>
            <li>
              <span>Subjects</span> <span>Spanish, French</span>
            </li>
          </ul>
        </div>
      </CModal>
    </div>
  );
}
export default Details;
