import Users from "../constants/Users";
import { post, get } from "../../utils/methods";
import {
  getTokenAndSetIntoHeaders,
  removeTokenInToLocalStorageAndDeleteAuthorization,
  setTokenInToLocalStorage,
  ACCESS_TOKEN,
  authBaseUrl,
  removeTokenFromLocalStorage,
} from "../../utils/intercepter";
import { toast } from "../../utils/helper";
import { getAll } from "./Common";
import { connectionSocket } from "../../utils/socket";

export const checkTokenIsValid = (data) => (dispatch) => {
  let currentTime = new Date().getTime() + data.serverTime;
  if (currentTime > data.tokenExpiry) {
    dispatch(logout(null, "success", "Session expired"));
  }
};

export const checkAuth = (token) => async (dispatch) => {
  if (token) setTokenInToLocalStorage(token);
  let accessToken = await localStorage.getItem(ACCESS_TOKEN);
  if (accessToken) {
    dispatch({
      type: Users.CHECK_AUTH_API,
      loading: true,
      userLoggedIn: false,
    });
    getTokenAndSetIntoHeaders(accessToken);
    get("admin/getProfile")
      .then(({ data }) => {
        dispatch(
          loggedInUserUpdate(
            data.data,
            data.additionalData ? data.additionalData : {}
          )
        );
        dispatch(getAll());
        connectionSocket(accessToken);
        toast("success", "Request Successfull");
        dispatch({
          type: Users.CHECK_AUTH_API,
          loading: false,
          userLoggedIn: true,
        });
      })
      .catch((error) => {
        dispatch({
          type: Users.CHECK_AUTH_API,
          loading: false,
          userLoggedIn: false,
        });
        toast("error", "Wrong Credentials");
      });
  }
};

export const login = (payload, CB) => (dispatch) => {
  dispatch({ type: Users.LOGIN_USER_API, loginLoading: true });
  post(`${authBaseUrl}/auth/login`, payload)
    .then(({ data }) => {
      dispatch({ type: Users.LOGIN_USER_API, loginLoading: false });
      dispatch(checkAuth(data.data.token));
      CB && CB();
    })
    .catch((error) => {
      dispatch({ type: Users.LOGIN_USER_API, loginLoading: false });
      toast("error", "Wrong Credentials")
    });
};

export const signUp = (payload, CB) => (dispatch) => {
  dispatch({ type: Users.SIGN_UP_USER_API, loginLoading: true });
  post("admin/createAccount", payload)
    .then(({ data }) => {
      dispatch({ type: Users.SIGN_UP_USER_API, loginLoading: false });
      dispatch(checkAuth(data.data.token));
      CB && CB();
    })
    .catch((error) => {
      dispatch({ type: Users.SIGN_UP_USER_API, loginLoading: false });
    });
};

export const loggedInUserUpdate = (user, additionalData) => (dispatch) => {
  let updateAdditionalData = {};
  if (additionalData) {
    updateAdditionalData = additionalData;
    updateAdditionalData.serverTime =
      updateAdditionalData.serverTime - new Date().getTime();
    updateAdditionalData.tokenExpiry = `${updateAdditionalData.tokenExpiry}000`;
  }
  dispatch({
    type: Users.SET_LOGGED_IN_USER,
    user: { ...user, additionalData: updateAdditionalData },
  });
};

export const logout =
  (history, status = "success", message = "Successfully logout") =>
  (dispatch) => {
    history && history.replace('/login')
    removeTokenInToLocalStorageAndDeleteAuthorization();
    dispatch({ type: Users.LOGOUT_USER_API });
    removeTokenFromLocalStorage("PREV_ROUTE")
   
  };
