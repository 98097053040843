import React, { Fragment, useState, useEffect } from "react";
import {
  CNav,
} from "../../uiComponents";


import { Tabs } from "antd";
import Transactions from "./Transactions";
import TeacherTransactions from "./TeacherTransactions";




function TransactionTabs() {


const { TabPane } = Tabs;

   
   
  
    return (
      <div className="my-finances w-100 d-flex flex-column" >
        <CNav notificationVisible={false}>
         
        </CNav>

        {/* <Tabs
                className="h-100 d-flex flex-column c-tabs"
              >
                <TabPane tab="Transactions" key="transactions">
                  <div className="content-container scroll-y">
                    <Transactions />
                  </div>
                </TabPane>
                </Tabs> */}




                <Tabs
                className="h-100 d-flex flex-column c-tabs"
                defaultActiveKey="transactions"
                // onTabClick={() => setPlaying(false)}
              >
                <TabPane tab="Student Payments" key="transactions">
                  <div className="content-container scroll-Y" style={{ overflowY: 'scroll', height:"800px"}}>
                    <Transactions/>
                  </div>
                </TabPane>
                <TabPane tab="Teacher Payments" key="teacherTransactions">
                  <div className="content-container scroll-Y" style={{ overflowY: 'scroll', height:"800px"}}>
                    <TeacherTransactions/>
                  </div>
                </TabPane>
                </Tabs>

      
  
     

      </div>
    );
  }



export default TransactionTabs