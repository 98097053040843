const Common = {
  GET_ALL_COUNTRIES_API: "GET_ALL_COUNTRIES_API",
  GET_ALL_SUBJECT_API: "GET_ALL_SUBJECT_API",
  GET_ALL_CURRICULUM_API: "GET_ALL_CURRICULUM_API",
  GET_ALL_GRADES_API: "GET_ALL_GRADES_API",
};
export default Common;

export const PageNav = [
   {
    stack: 'Homestack',
    page: 'homeMain',
    label: 'Home'
  },
];

