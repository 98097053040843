import React from "react";
import {Statistic} from "antd";
const { Countdown } = Statistic;

function CCountdown({deadline, className, title}) {
    return (
        <div className={`c-countdown ${className ? className : ""}`}>
            {title ? <p className="c-countdown-title">{title}</p> : null}
            <div className="c-countdown-container">
                <Countdown value={deadline} format="D : H : m" />
            </div>
        </div>
    );
}
export default CCountdown;
