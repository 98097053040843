import React from 'react';
import { IoIosArrowRoundBack, IoMdAdd } from 'react-icons/io';
import {CCardWrapper, CNav} from "../../uiComponents";
import {Button, Checkbox, Form, Select} from "antd";
import {MappedElement, renderOptions} from "../../utils/helper";
import {selectOptions} from "../data";


const CForm = () => {

    const onFinish = values => {
        console.log('values', values)
    };

    return (
        <Form name="create-quiz"
              className="create-quiz-form-container h-100 d-flex flex-column"
              initialValues={{}}
              onFinish={onFinish}>
            <div className="create-quiz-container h-100 d-flex flex-row">
                <div className="quiz-detail-container">
                    <h6>Quizzes Details</h6>
                    <Form.Item name="subject" className="mb-3">
                        <Select defaultValue="" className="c-select">
                            <Select.Option value="">Subject</Select.Option>
                            {renderOptions(selectOptions)}
                        </Select>
                    </Form.Item>
                    <Form.Item name="curriculum" className="mb-3">
                        <Select defaultValue="" className="c-select">
                            <Select.Option value="">Curriculum</Select.Option>
                            {renderOptions(selectOptions)}
                        </Select>
                    </Form.Item>
                    <Form.Item name="grade" className="mb-3">
                        <Select defaultValue="" className="c-select">
                            <Select.Option value="">Grade</Select.Option>
                            {renderOptions(selectOptions)}
                        </Select>
                    </Form.Item>
                </div>
                <div className="quiz-questions-container d-flex flex-column">
                    <h4>How to write an essay?</h4>
                    <div className="questions">
                        <MappedElement data={['', '', '', '']} renderElement={(value, index) => {
                            return(
                                <Form.Item name="subject" className="question mb-4" key={index}>
                                    <span className="question-text">
                                        {index+1}.	Which two statements together prove that Tim has a red car?
                                    </span>
                                    <Checkbox.Group className="question-answers">
                                        <Checkbox value="Gil likes Tim's car colour">
                                            Gil likes Tim's car colour
                                        </Checkbox>
                                        <Checkbox value="Tim likes fast cars">
                                            Tim likes fast cars
                                        </Checkbox>
                                        <Checkbox value="Gil has a fast car">
                                            Gil has a fast car
                                        </Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            )
                        }}/>
                    </div>
                    <div className="add-question">
                        <IoMdAdd/>
                        <span>Add...</span>
                    </div>
                    <Form.Item className="mt-4 mb-0">
                        <Button htmlType="submit" className="c-button c-button-primary button-with-loading" block>
                            Submit
                        </Button>
                    </Form.Item>
                </div>
            </div>
        </Form>
    );
};


function CreateQuiz(props) {
    const { history } = props;
    return(
        <div className="quizzes d-flex flex-column w-100">
            <CNav title="Create Quizzes"/>

            <h3 className="page-title"> Quizzes </h3>

            <div className="inner-header">
                <span onClick={() => history.goBack()}>
                    <IoIosArrowRoundBack />
                </span>
                <h4>Back</h4>
            </div>
            <CCardWrapper className="c-card-wrapper w-100 p-0 flex-1">
                <CForm />
            </CCardWrapper>
        </div>
    )
}

export default CreateQuiz;
