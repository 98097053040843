import React, { Fragment, useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import {
  CNav,
  CModal,
  Loading,
  UserListItem,
  UserList,
} from "../../uiComponents";
import { get, post } from "../../utils/methods";
import { baseUrl } from "../../utils/intercepter";
import { MappedElement } from "../../utils/helper";
import "./PromotionCard.css";
import { PageNav } from "../../store/constants/Common";
import Axios from "axios";
import { message } from "antd";

function PromotionCard() {
  const [hit, setHit] = useState(0);

  const [isLoading, setIsLoading] = useState(false)

  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [promotionData, setPromotionData] = useState([]);

  //   Params

  const [selectedColor, setSelectedColor] = useState("#d65757");
  const [selectedImage, setSelectedImage] = useState({});
  const [selectedText, setSelectedText] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedLink, setSelectedLink] = useState( {
    stack: 'Homestack',
    page: 'homeMain',
    label: 'Home'
  });

  const [selectedId, setselectedId] = useState("");

  useEffect(() => {
    setIsLoading(true)
    get(`${baseUrl}/landingPage/get-promotion-card`)
      .then((res) => {
        console.log("promotioncard data :", res.data);
        setPromotionData(res.data);
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true)
    get(`${baseUrl}/landingPage/get-promotion-card`)
      .then((res) => {
        console.log("promotioncard data :", res.data);
        setPromotionData(res.data);
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err);
      });
  }, [hit]);

  const addPromotionCard = async () => {
    setIsLoading(true)
    console.log("selected : ", selectedImage);
    // return;
    console.log('form wala link', JSON.stringify(selectedLink))
    let payload = new FormData();
    payload.append("color", selectedColor);
    payload.append("title", selectedText);
    payload.append("link", JSON.stringify(selectedLink));
    payload.append("image", selectedImage);
    console.log(payload);
    post(`${baseUrl}/landingPage/add-promotion-card`, payload)
      .then((res) => {
        setHit(hit + 1);
        setModalOpen(false);
        setIsLoading(false)
        console.log("promotioncard data :", res.data);
        // setPromotionData(res.data)
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false)
      });
  };


  const editPromotionData = () => {
    setIsLoading(true)
    console.log("selected : ", selectedImage);
    // return;
    console.log('form wala link', JSON.stringify(selectedLink))
    let payload = new FormData();
    payload.append("color", selectedColor);
    payload.append("_id", selectedId);
    payload.append("title", selectedText);
    payload.append("link", JSON.stringify(selectedLink));
    payload.append("image", selectedImage);
    console.log(payload);
    post(`${baseUrl}/landingPage/update-promotion-card`, payload)
      .then((res) => {
        setHit(hit + 1);
        setEditModalOpen(false);
        console.log("promotioncard updated data :", res.data);
        setIsLoading(false)
        // setPromotionData(res.data)
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false)
      });
  }

  const deletePromotionData = () => {
    setIsLoading(true)
    post(`${baseUrl}/landingPage/delete-promotion-card`, { _id: selectedId })
      .then((res) => {
        setHit(hit + 1);
        setDeleteModalOpen(false);
        setIsLoading(false)
        // console.log('promotioncard data :', res.data)
        // setPromotionData(res.data)
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false)
      });
  };

  return (
    <>
      <div className="topMentor w-100 d-flex  flex-column">
        
        <CNav />

        <button className="c-fab-button" onClick={() => setModalOpen(true)}>
          <AiOutlinePlus />
        </button>
        {modalOpen && (
          <CModal
            show={modalOpen}
            backdrop="static"
            keyboard={false}
            className="center small"
            onHide={() => setModalOpen(false)}
          >
            <h3 style={{ textAlign: "center" }}>Add Card</h3>
            <input
              type="text"
              placeholder="Title"
              className="promotion-color-title"
              maxlength="40"
              onChange={(e) => {
                setSelectedText(e.target.value);
              }}
            />
            {/* <p>Please enter title</p> */}

            <input
              type="file"
              className="promotion-file-input"
              onChange={(e) => {
                const file = e.target.files[0]
                // console.log("input file :", e.target.files);
                const isJpgOrPng = file.type === 'image/png';
    if (!isJpgOrPng) {
      alert('You can only upload PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      alert('Image must smaller than 2MB!');
    }
    if(isJpgOrPng && isLt2M) {
      console.log(file);
      setSelectedImage(file);
    }
    return false;
              }}
            />
            {/* <p>Please choose image</p> */}

            <div className="promotion-color-div">
              <span style={{ width: "85%" }}>{selectedColor}</span>

              <input
                type="color"
                id="favcolor"
                name="favcolor"
                value={selectedColor}
                style={{ width: "15%" }}
                onChange={(e) => {
                  console.log("check color :", e.target.value);
                  setSelectedColor(e.target.value);
                }}
              />
            </div>

            <select
              className="promotion-color-select"
              onChange={(e) => {
                setSelectedType(e.target.value);
              }}
            >
              {/* <option>Select Type</option> */}
              <option value="explore">Explore</option>
            </select>

            <select
              className="promotion-color-select"
              onChange={(e) => {
                console.log(e.target.value, PageNav[e.target.value]);
                setSelectedLink(PageNav[e.target.value]);
              }}
            >
              <option>Select Link</option>
              <option value={0}>Home</option>
            </select>
            {/* <p>Please Select Link</p> */}
            {/* <AddSubject onHide={() => setModalOpen(false)} data={data} /> */}

            <button disabled={isLoading ? true : false} className="promotion-submit" onClick={addPromotionCard}>
              Submit
            </button>
          </CModal>
        )}

        {deleteModalOpen && (
          <CModal
            show={deleteModalOpen}
            backdrop="static"
            keyboard={false}
            className="center small"
            onHide={() => setModalOpen(false)}
          >
            <h3 style={{ textAlign: "center", color: "#111c55" }}>
              Delete Banner
            </h3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "60px",
              }}
            >
              <button
                className="promotion-delete-submit"
                onClick={() => {
                  setDeleteModalOpen(false);
                }}
              >
                No
              </button>

              <button
              disabled={isLoading ? true : false} 
                className="promotion-delete-submit"
                onClick={deletePromotionData}
              >
                Yes
              </button>
            </div>
          </CModal>
        )}

        {editModalOpen && (
          <CModal
          show={editModalOpen}
          backdrop="static"
          keyboard={false}
          className="center small"
          onHide={() => setEditModalOpen(false)}
        >
          <h3 style={{ textAlign: "center" }}>Update Banner</h3>
          <input
            type="text"
            placeholder="Title"
            className="promotion-color-title"
            maxlength="40"
            onChange={(e) => {
              setSelectedText(e.target.value);
            }}
            value={selectedText}
          />
          {/* <p>Please enter title</p> */}

          <input
            type="file"
            className="promotion-file-input"
            onChange={(e) => {
              const file = e.target.files[0]
              // console.log("input file :", e.target.files);
              const isJpgOrPng = file.type === 'image/png';
  if (!isJpgOrPng) {
    alert('You can only upload PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    alert('Image must smaller than 2MB!');
  }
  if(isJpgOrPng && isLt2M) {
    console.log(file);
    setSelectedImage(file);
  }
  return false;
            }}
          />
          {/* <p>Please choose image</p> */}

          <div className="promotion-color-div">
            <span style={{ width: "85%" }}>{selectedColor}</span>

            <input
              type="color"
              id="favcolor"
              name="favcolor"
              value={selectedColor}
              style={{ width: "15%" }}
              onChange={(e) => {
                console.log("check color :", e.target.value);
                setSelectedColor(e.target.value);
              }}
            />
          </div>

          <select
            className="promotion-color-select"
            onChange={(e) => {
              setSelectedType(e.target.value);
            }}
          >
            {/* <option>Select Type</option> */}
            <option value="explore">Explore</option>
          </select>

          <select
            className="promotion-color-select"
            onChange={(e) => {
              console.log(e.target.value, PageNav[e.target.value]);
              setSelectedLink(PageNav[e.target.value]);
            }}
          >
            <option>Select Link</option>
            <option value={0}>Home</option>
          </select>
          {/* <p>Please Select Link</p> */}
          {/* <AddSubject onHide={() => setModalOpen(false)} data={data} /> */}

          <button disabled={isLoading ? true : false}  className="promotion-submit" onClick={editPromotionData}>
            Update
          </button>
        </CModal>
        )}

        <Fragment>
          <table>
            <tr>
              <th>Title</th>
              <th>Image URL</th>
              <th>Color</th>
              <th>Link</th>
              <th>Edit / Delete</th>
            </tr>

            {promotionData &&
              promotionData.map((obj) => {
                return (
                  <tr>
                    <td>{obj.title}</td>
                    <td style={{height: '50px'}}>
                        <img src={obj?.image} style={{height: '100%', width: '50px', backgroundSize: 'cover'}}/>
                        </td>
                    <td style={{ background: obj.color }}>{obj.color}</td>
                    <td>{obj?.link.label}</td>
                    <td style={{ width: "10vw" }}>
                      <i
                        className="fa-solid fa-pen"
                        style={{ marginLeft: "1vw" }}
                        onClick={() => {
                            setEditModalOpen(true);
                            setselectedId(obj._id);
                            setSelectedColor(obj.color)
                            setSelectedLink(obj.link)
                            setSelectedText(obj.title)
                          }}
                      ></i>
                      <i
                        className="fa-regular fa-trash-can"
                        style={{ marginLeft: "3vw" }}
                        onClick={() => {
                          setDeleteModalOpen(true);
                          setselectedId(obj._id);
                        }}
                      ></i>
                    </td>
                  </tr>
                );
              })}
          </table>
        </Fragment>
      </div>
    </>
  );
}

export default PromotionCard;
