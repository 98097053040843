import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/Users";
import CImage from "../CImage/CImage";
import { useHistory } from "react-router-dom";
import { PREV_ROUTE, setValueInToLocalStorage } from "../../utils/intercepter";

const routes = [
  {
    title: "Dashboard",
    // icon: 'icon-dashboard'
    icon: require("../../assets/svgs/Shape.svg"),
    path: "/dashboard",
  },
  // {
  //   title: "Announcements",
  //   icon: require("../../assets/svgs/promo.svg"),
  //   path: "/announcements",
  // },
  {
    title: "Categories",
    icon: require("../../assets/svgs/categories.svg"),
    path: "/categories",
  },
  {
    title: "Tutors",
    icon: require("../../assets/svgs/Shape-1.svg"),
    path: "/tutors",
  },
  {
    title: "Students",
    icon: require("../../assets/svgs/graduation-cap.svg"),
    path: "/students",
  },
  // {
  //   title: "Parents",
  //   icon: require("../../assets/svgs/parents.svg"),
  //   path: "/parents",
  // },
  {
    title: "Referral",
    icon: require("../../assets/svgs/money.svg"),
    path: "/referral",
  },
  {
    title: "My Finances",
    icon: require("../../assets/svgs/money.svg"),
    path: "/my-finances",
  },
  {
    title: "Payments",
    icon: require("../../assets/svgs/money.svg"),
    path: "/transactions",
  },
  // {
  //   title: "Questions",
  //   icon: "icon-questions",
  //   path: "/questions",
  // },
  // {
  //   title: "Quizzes",
  //   icon: "icon-quizzes",
  //   path: "/quizzes",
  // },
  // {
  //   title: "Homeworks",
  //   icon: "icon-homeworks",
  //   path: "/homeworks",
  // },
  // {
  //   title: "Messages",
  //   icon: "icon-document",
  //   path: "/messages",
  // },
  // {
  //   title: "My Messages",
  //   icon: "icon-document",
  //   path: "/my-messages",
  // },
  // {
  //   title: "Disputes",
  //   icon: "icon-calendar",
  //   path: "/disputes",
  // },
  {
    title: "Admin Level Us.",
    icon: require("../../assets/svgs/adminLevelUs.svg"),
    path: "/admin-level-us",
  },
  {
    title: "Approvals",
    icon: require("../../assets/svgs/approvals.svg"),
    path: "/approvals",
  },
  // {
  //   title: "Top Mentors(Admin)",
  //   icon: require("../../assets/svgs/approvals.svg"),
  //   path: "/top-mentors",
  // },
  {
    title: "Top Mentors(Score)",
    icon: require("../../assets/svgs/approvals.svg"),
    path: "/top-mentors-score",
  },
  // {
  //   title: "Top Subjects",
  //   icon: require("../../assets/svgs/categories.svg"),
  //   path: "/top-subjects",
  // },
  {
    title: "Promotion Card",
    icon: require("../../assets/svgs/approvals.svg"),
    path: "/promotion-card",
  },
  {
    title: "Requested Teachers",
    icon: require("../../assets/svgs/approvals.svg"),
    path: "/requested-teachers",
  },
  {
    title: "Events",
    icon: require("../../assets/svgs/approvals.svg"),
    path: "#",
    subType: [
      {
        name: 'Teacher',
        path:'/Teacher'
      },
      {
        name: 'Courses',
        path:'/courses'
      },
      {
        name: 'Buttons',
        path:'/Buttons'
      },
      {
        name: 'Pages',
        path:'/Page'
      }
    ]
  
  }
];

function SideMenu({ currentPathName }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const checkActiveClass = (first, second) => {
    if (first.replace("/", "") === second.replace("/", "")) {
      return "active";
    } else {
      return "";
    }
  };
  const onselect = async (r) => {
    await setValueInToLocalStorage(PREV_ROUTE, r.path);
  };

  const [forSubMenu, setForSubMenu] = useState('right')
  const [subMenuHeight, setSubMenuHeight] = useState('0px')
  const [subMenuBottom, setSubMenuBottom] = useState('-10px')
  const [forSubMenuDisplay, setForSubMenuDisplay] = useState('none')

  const renderMenu = (menus) => {
    if (menus) {
      return (
        <ul>
          {menus.map((menu, index) => {
            return (
              <li key={index}>
                
                <Link
                  to={menu.path}
                  className={`${checkActiveClass(currentPathName, menu.path)}`}
                  onClick={() =>{
                    if(menu.subType){
                      if(forSubMenu === 'right'){
                        setForSubMenu('down')
                        setSubMenuHeight('max-content')
                        setSubMenuBottom('-270px')

                        setForSubMenuDisplay('block')
                      }
                      else{
                        setForSubMenu('right')
                        setSubMenuHeight('0px')
                        setSubMenuBottom('-10px')
                        setForSubMenuDisplay('none')
                      }
                    }
                    else{
                      onselect(menu)
                    }
                  }
                    }
                  
                >
                  <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center', position: 'relative'}}>
                  {menu.icon ? <img src={menu.icon} alt="" /> : null}
                  {/* <span>{menu.title}</span> */}
                  <span>{menu.title}</span>
                  {
                    menu?.subType?.length > 0 &&
                    <>
                    <i class={`fa-solid fa-chevron-${forSubMenu}` }
                    onClick={()=>{
                      if(forSubMenu === 'right'){
                        setForSubMenu('down')
                        setSubMenuHeight('max-content')
                        setSubMenuBottom('-270px')

                        setForSubMenuDisplay('block')
                      }
                      else{
                        setForSubMenu('right')
                        setSubMenuHeight('0px')
                        setSubMenuBottom('-10px')
                        setForSubMenuDisplay('none')
                      }
                    }}
                    />

                    <div style={{border: '1px solid #dddddd50', position: 'absolute', height: subMenuHeight, width: '90%', bottom: subMenuBottom, paddingLeft: '10px'}}>

                        {
                          menu?.subType?.map((obj)=>{
                            return(
                              <Link to={obj.path} style={{display: forSubMenuDisplay, marginTop: '10px', marginBottom: '10px', color: '#ffffff80'}}>
                                  {obj.name}
                              </Link>
                            )
                          })
                        }

                    </div>

                    </>
                  } 
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      );
    }
    return null;
  };
  const user = useSelector(({ users }) => users.user);
  const renderUserName = () => {
    if (user.profile) {
      return `${user?.profile?.firstName ? user?.profile?.firstName : ""} ${
        user?.profile?.lastName ? user?.profile?.lastName : ""
      }`;
    }
    return "";
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => dispatch(logout(history))} key="1">
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="side-menu h-100 w-100">
      <div className="logo">
        <img
          src={require("../../assets/images/hikma-logo-white.png")}
          alt="Himka"
          draggable="false"
        />
      </div>
      <div className="menu scroll-y">{renderMenu(routes)}</div>
      {Object.keys(user).length ? (
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          overlayClassName="c-dropdown"
        >
          <div className="user d-flex flex-row">
            <CImage
              alt=""
              imageUrl={
                user && user.profile.imageUrl
                  ? user.profile.imageUrl
                  : require("../../assets/svgs/hikma-logo-white.svg")
              }
              draggable="false"
            />
            <div className="user-info d-flex flex-column">
              <span>ADMIN</span>
              <span>{renderUserName()}</span>
            </div>
          </div>
        </Dropdown>
      ) : null}
    </div>
  );
}
export default SideMenu;
