const usersList = [
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Audrey W.",
    tags: ["Curriculum", "Grade", "Subject"],
    message: "Risus sed etiam tincidunt eu this is it font yes it is it...",
    role: "Admin",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Arlene F.",
    tags: ["Curriculum", "Grade", "Subject"],
    message: "Risus sed etiam tincidunt eu this is it font yes it is it...",
    role: "Super Admin",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "William D.",
    tags: ["Curriculum", "Grade", "Subject"],
    message: "Risus sed etiam tincidunt eu this is it font yes it is it...",
    role: "Admin",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Claire M.",
    tags: ["Curriculum", "Grade", "Subject"],
    message: "Risus sed etiam tincidunt eu this is it font yes it is it...",
    role: "Super Admin",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Darrell Mc.",
    tags: ["Curriculum", "Grade", "Subject"],
    message: "Risus sed etiam tincidunt eu this is it font yes it is it...",
    role: "Admin",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Gregory B.",
    tags: ["Curriculum", "Grade", "Subject"],
    message: "Risus sed etiam tincidunt eu this is it font yes it is it...",
    role: "Admin",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Nathan F.",
    tags: ["Curriculum", "Grade", "Subject"],
    message: "Risus sed etiam tincidunt eu this is it font yes it is it...",
    role: "Super Admin",
  },
];

const roleList = [
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Students",
    tags: ["Curriculum", "Grade", "Subject"],
    message: "Risus sed etiam tincidunt eu this is it font yes it is it...",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Parents",
    tags: ["Curriculum", "Grade", "Subject"],
    message: "Risus sed etiam tincidunt eu this is it font yes it is it...",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Teachers",
    tags: ["Curriculum", "Grade", "Subject"],
    message: "Risus sed etiam tincidunt eu this is it font yes it is it...",
  },
];

const messageList = [
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Audrey W.",
    message: "Risus sed etiam tincidunt eu this is it font yes it is it...",
    role: "Admin",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Arlene F.",
    message: "Risus sed etiam tincidunt eu this is it font yes it is it...",
    role: "Super Admin",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "William D.",
    message: "Risus sed etiam tincidunt eu this is it font yes it is it...",
    role: "Admin",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Claire M.",
    message: "Risus sed etiam tincidunt eu this is it font yes it is it...",
    role: "Super Admin",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Darrell Mc.",
    message: "Risus sed etiam tincidunt eu this is it font yes it is it...",
    role: "Admin",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Gregory B.",
    message: "Risus sed etiam tincidunt eu this is it font yes it is it...",
    role: "Admin",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Nathan F.",
    message: "Risus sed etiam tincidunt eu this is it font yes it is it...",
    role: "Super Admin",
  },
];

const parentList = [
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Audrey W.",
    tags: ["Curriculum", "Grade", "Subject"],
    child: "John Bage",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Arlene F.",
    tags: ["Curriculum", "Grade", "Subject"],
    child: "John Bage",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "William D.",
    tags: ["Curriculum", "Grade", "Subject"],
    child: "John Bage",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Claire M.",
    tags: ["Curriculum", "Grade", "Subject"],
    child: "John Bage",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Darrell Mc.",
    tags: ["Curriculum", "Grade", "Subject"],
    child: "John Bage",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Gregory B.",
    tags: ["Curriculum", "Grade", "Subject"],
    child: "John Bage",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Nathan F.",
    tags: ["Curriculum", "Grade", "Subject"],
    child: "John Bage",
  },
];

const invoices = [
  {
    title: "Invoice #121321",
    status: "Sent",
    lessons: "2 Lessons",
    rate: "$50",
    date: "Today 12:40",
  },
  {
    title: "Invoice #121321",
    status: "Approved",
    lessons: "2 Lessons",
    rate: "$50",
    date: "Today 12:40",
  },
  {
    title: "Invoice #121321",
    status: "Approved",
    lessons: "2 Lessons",
    rate: "$50",
    date: "Today 12:40",
  },
];

const messages = [
  {
    name: "Theresa D.",
    userImage: require("../assets/images/user-3.jpg"),
    message: "Hello. I would like to know how my son had passed the last test?",
    date: new Date(),
    reciever: "Ember D",
    recieve: "Yesterday",
    role: "Teacher",
  },
  {
    name: "You",
    userImage: require("../assets/images/user-2.jpg"),
    message: "Hello, Mrs. Danson. He had prepared pretty well for the test. ",
    date: new Date(),
    reciever: "Bernar M",
    recieve: "Today",
    role: "Student",
  },
  {
    name: "Theresa D.",
    userImage: require("../assets/images/user-3.jpg"),
    message: "Nice to hear. Thank you. When would he have an exam?",
    date: new Date(),
    reciever: "Clair K",
    recieve: "Today",
    role: "Student",
  },
  {
    name: "You",
    userImage: require("../assets/images/user-2.jpg"),
    message:
      "The exam will be next monday. Hope, William will prepare very good 😊",
    date: new Date(),
    reciever: "Mary D",
    recieve: "Mar 19",
    role: "Teacher",
  },

  {
    name: "Theresa D.",
    userImage: require("../assets/images/user-3.jpg"),
    message: "Hello. I would like to know how my son had passed the last test?",
    date: new Date(),
    reciever: "Tom P",
    recieve: "Yesterday",
    role: "Teacher",
  },
  {
    name: "You",
    userImage: require("../assets/images/user-2.jpg"),
    message: "Hello, Mrs. Danson. He had prepared pretty well for the test. ",
    date: new Date(),
    reciever: "Ember D",
    recieve: "Yesterday",
    role: "Teacher",
  },
  {
    name: "Theresa D.",
    userImage: require("../assets/images/user-3.jpg"),
    message: "Nice to hear. Thank you. When would he have an exam?",
    date: new Date(),
    reciever: "Ember D",
    recieve: "Yesterday",
    role: "Teacher",
  },
  {
    name: "You",
    userImage: require("../assets/images/user-2.jpg"),
    message:
      "The exam will be next monday. Hope, William will prepare very good 😊",
    date: new Date(),
    reciever: "Ember D",
    recieve: "Yesterday",
    role: "Teacher",
  },

  {
    name: "Theresa D.",
    userImage: require("../assets/images/user-3.jpg"),
    message: "Hello. I would like to know how my son had passed the last test?",
    date: new Date(),
    reciever: "Ember D",
    recieve: "Yesterday",
    role: "Teacher",
  },
  {
    name: "You",
    userImage: require("../assets/images/user-2.jpg"),
    message: "Hello, Mrs. Danson. He had prepared pretty well for the test. ",
    date: new Date(),
    reciever: "Ember D",
    recieve: "Yesterday",
    role: "Teacher",
  },
  {
    name: "Theresa D.",
    userImage: require("../assets/images/user-3.jpg"),
    message: "Nice to hear. Thank you. When would he have an exam?",
    date: new Date(),
    reciever: "Ember D",
    recieve: "Yesterday",
    role: "Teacher",
  },
  {
    name: "You",
    userImage: require("../assets/images/user-2.jpg"),
    message:
      "The exam will be next monday. Hope, William will prepare very good 😊",
    date: new Date(),
    reciever: "Harold K",
    recieve: "Yesterday",
    role: "Student",
  },
];

const categories = [
  {
    title: "Grade",
    children: [
      {
        title: "Grade 1",
      },
      {
        title: "Grade 2",
      },
      {
        title: "Grade 3",
      },
      {
        title: "Grade 4",
      },
    ],
  },
  {
    title: "Curriculum",
    children: [
      {
        title: "Curriculum 1",
      },
      {
        title: "Curriculum 2",
      },
      {
        title: "Curriculum 3",
      },
      {
        title: "Curriculum 4",
      },
    ],
  },
  {
    title: "Subject",
    children: [
      {
        title: "Subject 1",
      },
      {
        title: "Subject 2",
      },
      {
        title: "Subject 3",
      },
      {
        title: "Subject 4",
      },
    ],
  },
];

const selectOptions = [
  {
    value: "Option 1",
    title: "Option 1",
  },
  {
    value: "Option 2",
    title: "Option 2",
  },
  {
    value: "Option 3",
    title: "Option 3",
  },
  {
    value: "Option 4",
    title: "Option 4",
  },
];

const teachersList = [
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Audrey W.",
    accured: "$657",
    paid: "$100",
    length: "15mint",
    date: "Mar 30 at 2:15PM",
    status: "Parent",
    hired: "Yes",
    role: "superadmin",
    button: "make admin",
  },
  {
    imageUrl: require("../assets/images/user-3.jpg"),
    name: "Arlene F.",
    accured: "$657",
    paid: "$100",
    length: "15mint",
    date: "Mar 30 at 2:15PM",
    status: "Parent",
    hired: "No",
    role: "superadmin",
    button: "make admin",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "William D.",
    accured: "$657",
    paid: "$100",
    length: "15mint",
    date: "Mar 30 at 2:15PM",
    status: "Parent",
    hired: "Yes",
    role: "superadmin",
    button: "make admin",
  },
  {
    imageUrl: require("../assets/images/user-4.jpg"),
    name: "Claire M.",
    accured: "$657",
    paid: "$100",
    length: "15mint",
    date: "Mar 30 at 2:15PM",
    status: "Parent",
    hired: "Yes",
    role: "superadmin",
    button: "make admin",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Darrell Mc.",
    accured: "$657",
    paid: "$100",
    length: "15mint",
    date: "Mar 30 at 2:15PM",
    status: "Parent",
    hired: "Yes",
    role: "superadmin",
    button: "make admin",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Gregory B.",
    accured: "$657",
    paid: "$100",
    length: "15mint",
    date: "Mar 30 at 2:15PM",
    status: "Parent",
    hired: "Yes",
    role: "superadmin",
    button: "make admin",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    name: "Nathan F.",
    accured: "$657",
    paid: "$100",
    length: "15mint",
    date: "Mar 30 at 2:15PM",
    status: "Parent",
    hired: "Yes",
    role: "superadmin",
    button: "make admin",
  },
];
const feedback = [
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    role: "student",
    name: "Mary D.",
    date: new Date(),
    tags: ["Curriculum", "Grade", "Subject"],
    knowledge: 5,
    onlineDelivery: 5,
    overallAttitude: 4,
    presentation: 3,
    punctuality: 4,
    comment:
      "Risus sed etiam tincidunt eu id amet vesti bulum semper. Et viverra arcu, sit id interdum nunc. Eget fermentum purus gravida egestas.",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    role: "student",
    name: "Mary D.",
    date: new Date(),
    tags: ["Curriculum", "Grade", "Subject"],
    knowledge: 5,
    onlineDelivery: 5,
    overallAttitude: 4,
    presentation: 3,
    punctuality: 4,
    comment:
      "Risus sed etiam tincidunt eu id amet vesti bulum semper. Et viverra arcu, sit id interdum nunc. Eget fermentum purus gravida egestas.",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    role: "student",
    name: "Mary D.",
    date: new Date(),
    tags: ["Curriculum", "Grade", "Subject"],
    knowledge: 5,
    onlineDelivery: 5,
    overallAttitude: 4,
    presentation: 3,
    punctuality: 4,
    comment:
      "Risus sed etiam tincidunt eu id amet vesti bulum semper. Et viverra arcu, sit id interdum nunc. Eget fermentum purus gravida egestas.",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    role: "student",
    name: "Mary D.",
    date: new Date(),
    tags: ["Curriculum", "Grade", "Subject"],
    knowledge: 5,
    onlineDelivery: 5,
    overallAttitude: 4,
    presentation: 3,
    punctuality: 4,
    comment:
      "Risus sed etiam tincidunt eu id amet vesti bulum semper. Et viverra arcu, sit id interdum nunc. Eget fermentum purus gravida egestas.",
  },
  {
    imageUrl: require("../assets/images/user-2.jpg"),
    role: "student",
    name: "Mary D.",
    date: new Date(),
    tags: ["Curriculum", "Grade", "Subject"],
    knowledge: 5,
    onlineDelivery: 5,
    overallAttitude: 4,
    presentation: 3,
    punctuality: 4,
    comment:
      "Risus sed etiam tincidunt eu id amet vesti bulum semper. Et viverra arcu, sit id interdum nunc. Eget fermentum purus gravida egestas.",
  },
];

const pastStudentsExperience = [
  {
    name: "William D.",
    imageUrl: require("../assets/images/user-2.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject", "Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
  {
    name: "William D.",
    imageUrl: require("../assets/images/user.jpg"),
    grade: "8/10",
    tags: ["Curriculum", "Grade", "Subject"],
  },
];

const notifications = [
  {
    name: "Savannah M.",
    message: "sent a message",
    role: "Samata L.",
    time: "Today 12:40",
  },
  {
    name: "Savannah M.",
    message: "sent a announcement",
    role: "Samata L.",
    time: "Today 12:40",
  },
  {
    name: "Savannah M.",
    message: "sent a invoice",
    role: "Samata L.",
    time: "Today 12:40",
  },
  {
    name: "Savannah M.",
    message: "logged in",
    time: "Today 12:40",
  },
];

export {
  usersList,
  invoices,
  messages,
  categories,
  selectOptions,
  teachersList,
  feedback,
  pastStudentsExperience,
  notifications,
  parentList,
  messageList,
  roleList,
};
