// Get all Curriculum api
import Approvals from "../constants/Approvals";
import { post, get } from "../../utils/methods";
import { encodeQueryData, toast } from "../../utils/helper";

export const getAllApprovals = (payload) => (dispatch) => {
    dispatch({ type: Approvals.GET_ALL_APPROVALS_API, loading: true, approvals: [] });
    post('approval/getAll', payload)
        .then(({ data }) => {
            console.log("data approval", data)
            dispatch({
                type: Approvals.GET_ALL_APPROVALS_API,
                loading: false,
                approvals: (data.data && data.data.entries) ? data.data.entries : [],
                metaData: data?.data?.metaData
            });
        })
        .catch((error) => {
            dispatch({ type: Approvals.GET_ALL_APPROVALS_API, loading: false, approvals: [] });
        });
};

export const getAllTeachers = (payload, CB) => (dispatch) => {
    dispatch({ type: Approvals.GET_TEACHERS_APPROVAL, loading: true });
    let queryParams = encodeQueryData(payload) ? `?${encodeQueryData(payload)}` : '';
    get(`teacher/getProfile${queryParams}`)
        .then(({ data }) => {
            let teacher = data?.data && data?.data ? data?.data : []
            dispatch({
                type: Approvals.GET_TEACHERS_APPROVAL, loading: false
            });
            CB && CB(teacher);
        })
        .catch((error) => {
            console.log(error)
        });
}

export const approvalsTakeAction = (payload, CB) => (dispatch) => {
    dispatch({ type: Approvals.APPROVAL_TAKE_ACTION_API, loading: true });
    post('approval/takeAction', payload)
        .then(({ data }) => {
            toast('success', "Success")
            dispatch({ type: Approvals.APPROVAL_TAKE_ACTION_API, loading: false });
            CB && CB();
            dispatch(getAllApprovals({ topics: ['TEACHER_SIGNUP', 'TEACHER_PROFILE_UPDATE', 'TEACHER_RATE_CHANGE', "ON_BOARD_REQUEST",] }))
        })
        .catch((error) => {
            dispatch({ type: Approvals.APPROVAL_TAKE_ACTION_API, loading: false });
        });
};
