import MyAccount from "../constants/MyAccount";

const initialState={
    activateAccountLoading: false,
}

export default (state= initialState, action={})=> {
    switch(action.type){
        case MyAccount.ACTIVATE_ACCOUNT: 
            return {...state, activateAccountLoading: action.loading}

        default: 
            return state;
    }
}