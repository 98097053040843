import React from "react";
import { CTags } from "../index";
import { MappedElement, renderUserName, renderChildren } from "../../utils/helper";
import CImage from "../CImage/CImage";

const CTypeList = ({ data, onClick }) => {
  if (data && data.length) {
    return (
      <ul className="m-0 p-0 c-type-list">
        <MappedElement data={data} renderElement={(obj, index) => {
          return (
            <li key={index} className={`d-flex flex-row c-type-list-item ${obj.childDetails ? "align-items-start" : "align-items-center"}`}
              onClick={onClick}>
              <CImage imageUrl={obj.imageUrl ? obj.imageUrl : ""} alt={obj.name} />
              <div className="flex-1 c-type-list-item-info">
                <h6>
                  {renderUserName(obj)}
                </h6>
                {renderChildren(obj)}
                <CTags data={obj.tags} />
              </div>
            </li>
          );
        }}
        />
      </ul>
    );
  }
  return null;
};

export default CTypeList;
