import React, { useEffect, useState } from "react";
import { CNav } from "../../uiComponents";
import "./requestedTeachers.css";
import { get } from "../../utils/methods";

function RequestedTeachers() {
  const [requestData, setRequestData] = useState([]);

  const getAllTeacherRequests = async () => {
    const allRequests = await get("subject/getRequestedTeachers");
    console.log(allRequests?.data, "all req");
    setRequestData(allRequests?.data);
  };

  useEffect(() => {
    getAllTeacherRequests();
  }, []);

  return (
    <div className="topMentor w-100 d-flex  flex-column">
      <CNav />

      <h1>Requested Teachers</h1>

      <div className="requested-teacher-body-container">
        {requestData &&
          requestData.map((obj, index) => {
            return (
              <div className="requested-teacher-content-box" key={index}>
                <h4>
                  Subject: <span>{obj?.subject}</span>
                </h4>
                <h4>
                  Grade: <span>{obj?.grade}</span>
                </h4>
                <h4>
                  Count: <span>{obj?.count}</span>
                </h4>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default RequestedTeachers;
