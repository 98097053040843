import AdminLevelUsers from '../constants/AdminLevelUsers';
import Users from "../constants/Users";

const initialState = {
    getAllAdminLevelUsersLoading: true,
    adminLevelUsers: [],
    createAdminLoading: false,
    switchPrivilegeLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case AdminLevelUsers.GET_ALL_ADMIN_LEVEL_USERS_API:
            return { ...state, getAllAdminLevelUsersLoading: action.loading, adminLevelUsers: action.data };
        case AdminLevelUsers.CREATE_ADMIN_API:
            return { ...state, createAdminLoading: action.loading };
        case Users.SWITCH_PRIVILEGE:
            return { ...state, switchPrivilegeLoading: action.loading };
        default:
            return state;
    }
};
