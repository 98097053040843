import Tutors from "../constants/Tutors";

const initialState = {
    getAllTutorsLoading: true,
    tutors: [],
    metaData:[], 
    getMyStudentsLoading: true,
    myStudents: [],
    getTutorPromosLoading: true,
    tutorPromos: [],
    actionLoading: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Tutors.GET_ALL_TUTORS_API:
            return { ...state, getAllTutorsLoading: action.loading, tutors: action.data, metaData:action?.metaData };
        case Tutors.GET_MY_STUDENTS_API:
            return { ...state, getMyStudentsLoading: action.loading, myStudents: action.data };
        case Tutors.GET_TUTOR_PROMOS_API:
            return { ...state, getTutorPromosLoading: action.loading, tutorPromos: action.data };
        case Tutors.PROMO_TAKE_ACTION_API:
            return { ...state, actionLoading: action.loading };
        default:
            return state;
    }
};
