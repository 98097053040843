import Finances from "../constants/Finances";
import { post } from "../../utils/methods";
import { toast } from "../../utils/helper";

export const getAllTeacherFinances = (payload) => (dispatch) => {
  dispatch({
    type: Finances.GET_ALL_TEACHER_FINANCES,
    loading: true,
    finances: [],
  });
  post("finance/getTeacherFinancesForAdmin", payload)
    .then(({ data }) => {
      dispatch({
        type: Finances.GET_ALL_TEACHER_FINANCES,
        loading: false,
        finances:
          data?.data?.entries && data?.data?.entries?.length
            ? data?.data?.entries
            : [],
      });
    })
    .catch((error) => {
      dispatch({
        type: Finances.GET_ALL_TEACHER_FINANCES,
        loading: false,
        finances: [],
      });
    });
};

export const sendMoneyTeacher =
  (payload = {}, upcomingPayload, CB) =>
  (dispatch) => {
    dispatch({ type: Finances.SEND_MONEY_API, loading: true });
    post("finance/payTeacher", payload)
      .then(({ data }) => {
        toast("success", `Successfully send money`);
        dispatch({ type: Finances.SEND_MONEY_API, loading: false });
        if (upcomingPayload === "teacherList") {
          dispatch(getAllTeacherFinances());
        } else if (upcomingPayload === "teacher") {
          dispatch(getAllTeacherFinances({ teacher: payload.teacher }));
        }
        CB && CB();
      })
      .catch((error) => {
        dispatch({ type: Finances.SEND_MONEY_API, loading: false });
      });
  };

export const getFinancesForAdmin = () => (dispatch) => {
  dispatch({
    type: Finances.GET_FINANCES_FOR_ADMIN,
    loading: true,
    adminFinances: [],
  });
  post("finance/getFinancesForAdmin")
    .then(({ data }) => {
      dispatch({
        type: Finances.GET_FINANCES_FOR_ADMIN,
        loading: false,
        adminFinances: data.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Finances.GET_FINANCES_FOR_ADMIN,
        loading: false,
        adminFinances: [],
      });
    });
};

export const getFinancesByPagination = (payload, CB) => (dispatch) => {
  dispatch({
    type: Finances.GET_FINANCES_BY_PAGINATION_API,
    loading: true,
    data: [],
  });
  post("finance/getFinancesByPagination", payload)
    .then(({ data }) => {
      dispatch({
        type: Finances.GET_FINANCES_BY_PAGINATION_API,
        loading: false,
        data: data.data && data.data.entries ? data.data.entries : [],
      });
    })
    .catch((error) => {
      dispatch({
        type: Finances.GET_FINANCES_BY_PAGINATION_API,
        loading: false,
        data: [],
      });
    });
};

export const getCashonDemand = () => (dispatch) => {
  dispatch({
    type: Finances.GET_CASH_ON_DEMAND,
    loading: true,
    cashOnDemandRequests: [],
  });
  post("finance/getCashOnDemand")
    .then(({ data }) => {
      dispatch({
        type: Finances.GET_CASH_ON_DEMAND,
        loading: false,
        data: data.data && data.data.entries,
      });
    })
    .catch((error) => {
      dispatch({
        type: Finances.GET_CASH_ON_DEMAND,
        loading: false,
        cashOnDemandRequests: [],
      });
    });
};

export const updateCashoOnDemand =
  (payload = {}, CB) =>
  (dispatch) => {
    dispatch({ type: Finances.UPDATE_CASH_ON_DEMAND, loading: true });
    post("finance/updateCashOnDemand", payload)
      .then(({ data }) => {
        toast("success", `Amount despatch successfully `);
        dispatch({ type: Finances.UPDATE_CASH_ON_DEMAND, loading: false });
        dispatch(getCashonDemand());
      })
      .catch((error) => {
        dispatch({ type: Finances.UPDATE_CASH_ON_DEMAND, loading: false });
      });
  };
