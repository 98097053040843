import React from "react";
import { BellOutlined } from '@ant-design/icons';
function CNav(props) {

    const { title = 'Admin Dashboard', notificationVisible = false, children } = props;

    return (
        <div className="page-nav d-flex flex-row">
            <div className="pageheading"><h1>{title}</h1></div>
            {notificationVisible ? <BellOutlined /> : null}
       
        </div>
    );
}
export default CNav;
