import Finances from '../constants/Finances';

const initialState = {
    getFinancesLoading: false,
    sendMoneyLoading: false,
    getAdminFinancesLoading: false,
    adminFinances: [],
    finances: [],
    getFinancesByPaginationLoading: false,
    studentFinances: [],
    cashOnDemands: [],
    getashOnDemandsLoading: false,
    updateCashOnDemandLoading: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Finances.GET_ALL_TEACHER_FINANCES:
            return { ...state, getFinancesLoading: action.loading, finances: action.finances };
        case Finances.GET_FINANCES_FOR_ADMIN:
            return { ...state, getAdminFinancesLoading: action.loading, adminFinances: action.adminFinances };
        case Finances.SEND_MONEY_API:
            return { ...state, sendMoneyLoading: action.loading };
        case Finances.GET_FINANCES_BY_PAGINATION_API:
            return { ...state, getFinancesByPaginationLoading: action.loading, studentFinances: action.data };
        case Finances.GET_CASH_ON_DEMAND:
            return { ...state, getashOnDemandsLoading: action.loading, cashOnDemands: action.data };
        case Finances.UPDATE_CASH_ON_DEMAND:
            return { ...state, updateCashOnDemandLoading: action.loading };
        default:
            return state;
    }
};
