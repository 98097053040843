import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popconfirm } from "antd";
import { CNav, CModal, Loading } from "../../uiComponents";
import { dataEmpty, MappedElement, renderOptions } from "../../utils/helper";
import { Form, Select, message } from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { GiCancel } from "react-icons/gi";
import { getAllCurriculum } from "../../store/actions/Categories";
import { withRouter } from "react-router";
import CreateCategory from "./CreateCategory";
import { deleteSubject } from "../../store/actions/Categories";
import { deleteCirriculum } from "../../store/actions/Categories";
import { deleteGrade } from "../../store/actions/Categories";

function Categories() {

  const [addCurriculumModal, setAddCurriculum] = useState(false);
  const [curriculumModal, setCurriculumModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [type, setType] = useState("curriculum");
  const [selectedId, setSelectedId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updatecurriculum, setUpdateCurriculum] = useState(null);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const reduxState = useSelector(({ categories }) => {
    let curriculum = categories.curriculum;
    return {
      curriculum: curriculum,
      curriculumCount: curriculum && curriculum?.length ? curriculum?.length : 0,
      curriculumLoading: categories?.curriculumLoading,
    };
  });

  const { curriculumLoading, curriculum, curriculumCount } = reduxState;

  const get = () => {
    getCurriculum({ isFillSubjectDetails: true });
  };

  useEffect(() => {
    get();
  }, []);

  function cancel(e) {
    message.error('Cancelled');
  }

  const toggleModal = (value, data = null, isUpdate = false) => {
    setAddCurriculum(value);
    setSelectedCategory(data);
  };


  const toggleCuriculum = (val = false, obj = {}, isUpdate = false, type = "curriculum") => {
    setCurriculumModal(val);
    setSelected(obj);
    setIsUpdate(isUpdate);
    setType(type);
  };

  function confirm(obj, get, type) {
    dispatch(deleteCirriculum({ curriculum: obj?._id }, get))

  }

  function confirm_one(selectedId, get) {
    dispatch(deleteGrade({ grade: selectedId }, get));
  }

  function confirm_two(subject, get) {
    dispatch(deleteSubject({ subject: subject }, get))

  }

  const getCurriculum = () => {
    dispatch(getAllCurriculum({ isFillSubjectDetails: true, showAllCurriculum: true }))
  };

  const getValue = (index, id) => {
    let v = form.getFieldValue(`id-${index}`);
    return v ? v : id;
  };

  const getSubjects = (data, id) => {
    if (data && data.length && id) {
      let subjects = data.find((obj) => obj?._id === id);
      if (subjects && Object.keys(subjects)?.length) {
        return subjects?.subjects;
      } else {
        return [];
      }
    } else return [];
  };




  const renderCurriculum = (curriculum) => {
    if (curriculum && curriculum?.length) {
      return (
        <MappedElement
          data={curriculum}
          renderElement={(obj, index) => {

            let selectedGrade =
              obj?.grades && obj?.grades.length ? obj?.grades[0]?._id : "";
            return (
              <div className='c-card curriculum-card ' key={index}>
                <div className='curriculum-card-header'>
                  <div className='curriculum-card-header-down'>
                    {obj && obj?.curriculumIcon && (
                      <div className="curriculum-icon">
                        <img src={obj?.curriculumIcon} alt={obj?.name} />
                      </div>
                    )}
                    <h1>{obj && obj?.name ? obj?.name : ''}</h1>

                    <h1 className='curriculum-card-header-down-heading'>
                      <Popconfirm
                        placement='topLeft'
                        className=''
                        title={'Are you sure to delete this?'}
                        onConfirm={() => confirm(obj, get, type)}
                        onCancel={cancel}
                        okText={'Yes'}
                        cancelText={'No'}
                      >
                        <AiOutlineDelete color='#21047C' cursor={'pointer'} fontSize={30} />
                      </Popconfirm>
                      <AiOutlineEdit
                        cursor={'pointer'}
                        color='#21047C'
                        onClick={() => toggleCuriculum(true, obj, true, 'curriculum')}
                        fontSize={30}
                      />
                    </h1>
                  </div>
                  <div>
                  <p style={{marginTop:'10px'}}>{obj && obj?.isVocational ? 'Non Curriculum' : 'Curriculum'}</p>
                  </div>
                  {obj?.grades && obj?.grades?.length ? (
                    <div className='curriculum-card-header-select'>
                      <Form.Item name={`id-${index}`} className='m-0'>
                        <Select
                          onChange={(v) => {
                            form.resetFields();
                            setSelectedId(v);
                          }}
                          className='c-select'
                          loading={false}
                          placeholder='Grades'
                        >
                          <Select.Option value=''>Select</Select.Option>
                          {renderOptions(obj?.grades && obj?.grades ? obj?.grades : 'Grades', 'name', '_id')}
                        </Select>
                      </Form.Item>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className='curriculum-card-body'>
                  <div className='curriculum-list'>
                    <div className='categories-grades-model'>
                      {obj &&
                      obj?.grades &&
                      obj?.grades?.length &&
                      obj?.grades.find((item) => item?._id === selectedId)?.name ? (
                        <div className='conditional-category'>
                          {obj &&
                          obj?.grades &&
                          obj?.grades?.length &&
                          obj?.grades.find((item) => item?._id === selectedId)?.name
                            ? obj &&
                              obj?.grades &&
                              obj?.grades?.length &&
                              obj?.grades.find((item) => item?._id === selectedId)?.name
                            : null}

                          {obj &&
                          obj?.grades &&
                          obj?.grades?.length &&
                          obj?.grades.find((item) => item?._id === selectedId)?.name ? (
                            <Popconfirm
                              placement='topLeft'
                              className=''
                              title={'Are you sure to delete this?'}
                              onConfirm={() => confirm_one(selectedId, get)}
                              onCancel={cancel}
                              okText={'Yes'}
                              cancelText={'No'}
                            >
                              <AiOutlineDelete className='delete' />
                            </Popconfirm>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='styling'>
                    {/* {(obj?.grades?.length && !obj?.subjects?.length)|| (!obj?.grades?.length && !obj?.subjects?.length) ? getSubjects(obj?.grades, getValue(index, selectedId)).length ? ( */}
                    { getSubjects(obj?.grades, getValue(index, selectedId)).length ? (<MappedElement
                        data={getSubjects(obj?.grades, selectedId, getValue(index, selectedId))}
                        renderElement={(subject, index) => (
                          <div className='curriculum-list subjects' key={index}>
                            <div className='categories-subject-model'>
                              <div className='curriculum-list-down'>
                                <div>{subject?.name ? subject?.name : ''}</div>

                                <div>
                                  <Popconfirm
                                    placement='topLeft'
                                    className=''
                                    title={'Are you sure to delete this?'}
                                    onConfirm={() => confirm_two(subject?._id, get)}
                                    onCancel={cancel}
                                    okText={'Yes'}
                                    cancelText={'No'}
                                  >
                                    <GiCancel className='cancel' />
                                  </Popconfirm>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    ) : (
                       obj?.grades?.length ? dataEmpty() : null
                    )}
               
                  </div>
                  {/* // vocational subject  */}
                  <div className='styling'>
                    {obj?.isVocational && !obj?.grades?.length &&
                      (obj.subjects?.length ? (
                        <MappedElement
                          data={obj?.subjects}
                          renderElement={(subject, index) => (
                            <div className='curriculum-list subjects' key={index}>
                              <div className='categories-subject-model'>
                                <div className='curriculum-list-down'>
                                  <div>{subject?.name ? subject?.name : ''}</div>

                                  <div>
                                    <Popconfirm
                                      placement='topLeft'
                                      className=''
                                      title={'Are you sure to delete this?'}
                                      onConfirm={() => confirm_two(subject?._id, get)}
                                      onCancel={cancel}
                                      okText={'Yes'}
                                      cancelText={'No'}
                                    >
                                      <GiCancel className='cancel' />
                                    </Popconfirm>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        />
                      ) : (
                        dataEmpty()
                      ))}
                  </div>
                </div>
                <div className='curriculum-card-footer'>
                  {(!obj?.grades?.length && obj?.subjects?.length) || (!obj?.grades?.length) && (obj?.subjects?.length && obj?.grades?.length) ? ''  : <div
                    className='curriculum-card-footer-down link'
                    onClick={() => toggleCuriculum(true, obj, false, 'grade')}
                  >
                    Add Grade
                  </div>}
                  <div
                    className='curriculum-card-footer-down link'
                    onClick={() => toggleCuriculum(true, obj, false, 'subject')}
                  >
                    Add Subject
                  </div>
                </div>
              </div>
            );
          }}
        />
      );
    }
    return null;
  };

  return (
    <div className="categories w-100 d-flex  flex-column">
      <CNav />
      <h3 className="page-title"> Categories </h3>
      <div className="d-flex align-items-center ">
      </div>
      <div className="c-section d-flex flex-column  flex-1 ">
        <h6 className="c-section-title">
        </h6>
        <Form form={form} name="filter" className="flex-1">
          <div className="categories-container  h-100">
            {curriculumLoading ? (
              <div className="w-100 h-100">
                <Loading position="static" className="page-loading" />
              </div>
            ) : curriculum && curriculum?.length ? (
              renderCurriculum(curriculum)
            ) : (
              dataEmpty()
            )}
          </div>
        </Form>
      </div>
      <button className="c-fab-button" onClick={() => setCurriculumModal(true)}>
        <AiOutlinePlus />
      </button>
      <CModal
        show={curriculumModal}
        backdrop="static"
        keyboard={false}
        className="center small"
        onHide={() => toggleCuriculum()}
      >
        <CreateCategory
          onHide={() => toggleCuriculum()}
          data={selected}
          isUpdate={isUpdate}
          typeData={type}
          id={selectedId}
        />
      </CModal>
    </div>
  );
}

export default withRouter(Categories)
