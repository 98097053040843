import Parents from "../constants/Parents";

const initialState = {
    getAllParentsLoading: true,
    parents: [],
    getAllMeetingLoading: true,
    meetings: [],
    totalHired: 0,
    totalRejected: 0

};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Parents.GET_ALL_PARENTS_API:
            return { ...state, getAllParentsLoading: action.loading, parents: action.data };
        case Parents.GET_ALL_INTRODUCTORY_MEETING_API:
            return { ...state, getAllMeetingLoading: action.loading, meetings: action.data, totalHired: action.totalHired, totalRejected: action.totalRejected };
        default:
            return state;
    }
};
