import React, { useEffect, useState, Fragment } from "react";
import { CNav, CModal, Loading, CImage } from "../../uiComponents";
import { MappedElement, dataEmpty, renderUserName } from "../../utils/helper";
import moment from "moment";
import { RiEdit2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { getAnnouncements } from "../../store/actions/Announcements";
import CreateAnnouncement from "./CreateAnnouncement";

function Announcements() {
  const [createAnnouncement, setCreateAnnouncement] = useState(false);
  const [selectedAnnouncement, updateSelectedAnnouncement] = useState(null);
  const dispatch = useDispatch();
  const reduxState = useSelector(({ announcements, users }) => {
    return {
      loading: announcements.getAnnouncementsLoading,
      students: announcements.students,
      studentsMeta: {},
      teachers: announcements.teachers,
      teachersMeta: {},
      parents: announcements.parents,
      parentsMeta: {},
      all: announcements.all,
      allMeta: {},
      user: users.user
    }
  });

  const getAllAnnouncementsCallBack = (payload) => {
    if (payload && payload.length) {
      selectAnnouncement({ title: "All" });
    }
  };

  const { students, teachers, parents, all, user, loading } = reduxState;

  useEffect(() => {
    dispatch(getAnnouncements(null, getAllAnnouncementsCallBack))
  }, []);

  const selectAnnouncement = (data = null) => {
    updateSelectedAnnouncement(data);
  };

  const renderAnnouncementListItem = (data, key) => {
    if (data && data?.length) {
      return (
        <li className={`d-flex c-type-list-item pt-3 pb-3
              ${selectedAnnouncement && (key === selectedAnnouncement?.title ? 'active' : '')}`}
          onClick={() => selectAnnouncement({ title: key })}>
          <div className="c-user-list-item-info w-100">
            <div className="d-flex align-items-center justify-content-between">
              <b className="text flex-1">{key}</b>
              <span className="secondary-text mr-0 ml-2">
                {data[(data.length - 1)]?.createdAt ? moment(data[(data.length - 1)]?.createdAt).fromNow() : '-'}
              </span>
            </div>
            <div className="secondary-text ellipsis-text overflow-hidden text-nowrap">
              {data[(data.length - 1)]?.message ? data[(data.length - 1)]?.message : ''}
            </div>
          </div>
        </li>
      );
    }
    return null
  };

  const renderElement = (message, index) => {
    return (
      <li className="chat-content" key={index}>
        <CImage imageUrl={message?.admin && message?.admin?.imageUrl ? message?.admin?.imageUrl : ''} alt={''} />
        <div className="flex-1">
          <span>
            {renderUserName(message?.admin, user ? user?._id : '')}, {moment(message.createdAt).format("LT")}
          </span>
          <span className="m-0"> <b> {message?.title ? message?.title : '-'} </b> </span>
          <p> {message?.message ? message?.message : '-'} </p>
        </div>
      </li>
    );
  };

  const getUpdatedData = (type) => {
    switch (type) {
      case 'All':
        return all;
      case 'Teachers':
        return teachers;
      case 'Parents':
        return parents;
      case 'Students':
        return students;
      default:
        return [];
    }
  }

  return (
    <div className="announcements w-100 d-flex flex-column ">
      <CNav />
      <div className="two-card-view d-flex">
        <div className="first-card-view">
          <div className="first-card-view-header d-flex">
            <h1 className="flex-1">Announcements</h1>
            <button className="c-button-icon ml-2" onClick={() => setCreateAnnouncement(true)}>
              <RiEdit2Line />
            </button>
          </div>
          <div className="first-card-view-body with-out-filter scroll-y">
            {loading ? (
              <div className="w-100 h-100">
                <Loading position="static" className="page-loading" />
              </div>
            ) : (students?.length || teachers?.length || parents?.length || all?.length) ? (
              <ul className="m-0 p-0 c-type-list">
                {renderAnnouncementListItem(all, 'All')}
                {renderAnnouncementListItem(teachers, 'Teachers')}
                {renderAnnouncementListItem(parents, 'Parents')}
                {renderAnnouncementListItem(students, 'Students')}
              </ul>
            ) : dataEmpty()}
          </div>
        </div>
        <div className="second-card-view d-flex flex-column">
          {(selectedAnnouncement && Object.keys(selectedAnnouncement).length) ? <Fragment>
            <div className="second-card-view-header without-tabs d-flex align-items-center">
              <div className="d-flex flex-column flex-1">
                <div className="d-flex align-items-center">
                  <b className="text">{selectedAnnouncement.title}</b>
                </div>
                <div className="secondary-text">{getUpdatedData(selectedAnnouncement?.title).length} announcement's</div>
              </div>
            </div>
            <div className="chat-tab-container">
              <div className="chat-container scroll-y">
                <ul className="chat">
                  <MappedElement data={getUpdatedData(selectedAnnouncement?.title)}
                    renderElement={renderElement} />
                </ul>
              </div>
            </div>
          </Fragment> : dataEmpty()}
        </div>
      </div>
      <CModal show={createAnnouncement} className="center small" onHide={() => setCreateAnnouncement(false)}>
        <CreateAnnouncement onHide={() => setCreateAnnouncement(false)} />
      </CModal>
    </div>
  );
}
export default Announcements;
