import React, { Fragment, useState } from "react";
import {
  CNav,
  SelectCategoriesFilter,
  CMultipleImages,
  CCardWrapper,
} from "../../uiComponents";
import { Dropdown, Menu } from "antd";
import { categories } from "../data";
import { MappedElement } from "../../utils/helper";
import { FiX } from "react-icons/fi";
import { AiOutlineEllipsis } from "react-icons/ai";
import { IoIosArrowRoundBack } from "react-icons/io";
import { BsCloudDownload } from "react-icons/bs";

function HomeWorkComponent(props) {
  const menu = () => (
    <Menu className="categories-dropdown">
      <Menu.Item key="0"> Share with all students </Menu.Item>
      <Menu.Item key="1"> Share with whole class </Menu.Item>
      <Menu.Item key="2"> Share with... </Menu.Item>
    </Menu>
  );

  const renderHomeWorkItem = (value, index) => {
    return (
      <li
        className="list-item"
        key={index}
        onClick={() => props.goToDetail(true)}
      >
        <img
          className="icon"
          src={require("../../assets/svgs/homework.svg")}
          draggable="false"
          alt=""
        />
        <div className="text flex-1">
          {" "}
          <b> Spanish </b> - (Lesson 3){" "}
        </div>
        <div className="single-user text flex-1">
          <img src={require("../../assets/images/user-3.jpg")} alt="" />
          <b>Spanish</b>
        </div>
        <div className="secondary-text text flex-1">
          <CMultipleImages
            data={[
              require("../../assets/images/user.jpg"),
              require("../../assets/images/user.jpg"),
              require("../../assets/images/user.jpg"),
            ]}
            renderCount={1}
          />
        </div>
        <div className="secondary-text flex-1">Deadline: Mar 30</div>
        <Dropdown
          overlay={menu()}
          trigger={["click"]}
          overlayClassName="c-dropdown"
        >
          <a
            href={false}
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            <AiOutlineEllipsis />
          </a>
        </Dropdown>
      </li>
    );
  };

  return (
    <Fragment>
      <div className="d-flex align-items-center mb-3">
        <div className="d-flex flex-1">
          <div className="c-tag rounded mb-0">
      
            Curriculum{" "}
            <button>
         
              <FiX />{" "}
            </button>{" "}
          </div>
          <div className="c-tag rounded mb-0">
    
            Subject
            <button>
          
              <FiX />
            </button>
          </div>
          <div className="c-tag rounded mb-0">
 
            Grade
            <button>

              <FiX />
            </button>
          </div>
          <div className="c-tag rounded mb-0">
       
            Student
            <button>
         
              <FiX />
            </button>
          </div>
          <div className="c-tag rounded mb-0">
     
            Tutor
            <button>
        
              <FiX />
            </button>
          </div>
          <div className="clear-all-button">
    
            <span>Clear All</span>
          </div>
        </div>
        <SelectCategoriesFilter categories={categories} />
      </div>

      <div className="c-section mt-3 mb-5">
        <div className="c-section-multiple-titles d-flex mb-2">
          <span className="c-section-title flex-05">Subject</span>
          <span className="c-section-title flex-04">Teacher</span>
          <span className="c-section-title flex-09">Student</span>
        </div>

        <ul className="list">
          <MappedElement data={["", ""]} renderElement={renderHomeWorkItem} />
        </ul>
      </div>
    </Fragment>
  );
}
function HomeWorkDetailComponent(props) {
  return (
    <Fragment>
      <div className="inner-header">
        <span onClick={() => props?.back(false)}>
          <IoIosArrowRoundBack />
        </span>
        <h4>Lesson</h4>
      </div>
      <CCardWrapper className="c-card-wrapper w-100 p-0 flex-1">
        <div className="c-inner-container h-100 d-flex flex-row">
          <div className="c-inner-container-left">
            <h6 className="m-0">Homework Details</h6>
            <div className="user-detail-view p-0">
              <ul>
                <li>
                  <span>Curriculum</span> <span>Lorem ipsum</span>
                </li>
                <li>
                  <span>Grade</span> <span>9</span>
                </li>
                <li>
                  <span>Subject</span> <span>Spanish</span>
                </li>
                <li>
                  <span>Lesson</span> <span>4</span>
                </li>
                <li>
                  <span>Deadline</span> <span>Mar 26 12:00 PM</span>
                </li>
                <li>
                  <span>Teacher</span>
                  <span className="text-with-image">
                    <img src={require("../../assets/images/user.jpg")} alt="" />
                    Jon Wick
                  </span>
                </li>
                <li>
                  <span>Students</span>
                  <span>
                    <CMultipleImages
                      data={[
                        require("../../assets/images/user.jpg"),
                        require("../../assets/images/user.jpg"),
                        require("../../assets/images/user.jpg"),
                      ]}
                      renderCount={1}
                    />
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="c-inner-container-right">
            <h4>How to write an essay?</h4>
            <div className="files">
              <div className="file-list">
                <MappedElement
                  data={["", ""]}
                  renderElement={(value, index) => {
                    return (
                      <div className="file-list-item w-100" key={index}>
                        <img
                          className="icon"
                          src={require("../../assets/svgs/pdf.svg")}
                          alt=""
                        />
                        <div className="file-list-item-content">
                          <h2 className="heading">Homework 1</h2>
                          <span className="text">1.2MB</span>
                        </div>
                        <div className="file-list-item-actions">
                          <span>
                            <BsCloudDownload />
                          </span>
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </CCardWrapper>
    </Fragment>
  );
}

function Homeworks() {
  const [innerPageIsShow, setInnerPageIsShow] = useState(false);

  const showDetailPage = (value, object) => {
    setInnerPageIsShow(value);
  };

  return (
    <div className="homeworks d-flex flex-column w-100">
      <CNav />

      <h3 className="page-title"> Homeworks </h3>

      {innerPageIsShow ? (
        <HomeWorkDetailComponent back={showDetailPage} />
      ) : (
        <HomeWorkComponent goToDetail={showDetailPage} />
      )}
    </div>
  );
}
export default Homeworks;
