import {socket} from "./socket";
import {aud, openNotification} from "./helper";

export const announcementsSocket = (socketType) => {
    if(socket) {
        socket.on(`${socketType},announcement`, (data) => {
            if(data){
                aud.play();
                let title = (data && data?.title) ? data?.title : '-';
                let message = (data && data?.message) ? data?.message : '-';
                openNotification(title, message);
            }
        });
    }
};
