import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { interceptor } from "./utils/intercepter";
import { Provider } from "react-redux";
import "antd/dist/antd.css";
import "./assets/icon/icons.css";
import "./assets/scss/index.scss";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";
import { connectionSocket } from "./utils/socket";
import { BrowserRouter } from "react-router-dom";

// Axios interceptor for intercepting Requests and Response
interceptor();


//First Function To Render as The Application Started
function Root() {
  useEffect(() => {

    //Establishing the Socket Connection as the Application started
    connectionSocket();

  }, []);

  return (

    //Binding Data of Store to Application
    <Provider store={store}>
     {/* Binding Routing To The Application  */}
      <BrowserRouter>
      {/* First Component To Mount */}
        <App />
      </BrowserRouter>
    </Provider>

  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.unregister();
