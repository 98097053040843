import React, { useEffect } from "react";
import ReactPlayer from "react-player";
import { dataEmpty } from "../../utils/helper";
function IntroductoryVideo({ data, onRender, playing }) {
  useEffect(() => {

    onRender();
  }, [onRender,playing]);
  return (
    <div className="d-flex flex-column h-100">
      <h5 className="personal-statement-heading">
        Personal Statement:
      </h5>
      <p className="personal-statement-tag"> {data && data?.introductory && data?.introductory?.personalStatement}</p>
      <hr className="hr-style"></hr>

      
      {playing ? (
        <>
     
          {data && data?.introductory?.personalVideoUrl ? (
            <ReactPlayer
              className="video-player"
              controls={true}
              url={data?.introductory?.personalVideoUrl}
            />
          ) : (
            dataEmpty()
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default IntroductoryVideo;
